.file-inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #FFFFFF 0%, #F4F7FF 100%);
    border: 2px dashed rgba(131, 131, 131, 0.2);
    border-radius: 5px;
    min-height: 50px;
}
 .file-inputs {
    position: relative;
    width: 100%;
}
 .file-inputs input {
    position: relative;
    text-align: right;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    padding: 0px;
    border: 0px;
}
.file-inputs button {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 4px;
    padding: 0px;
    border: none;
    outline: none;
    transition: background-color 0.4s;
    /* box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5); */
}
.file-inputs button i {
    width: 1.5em;
    height: 1.5em;
    padding: 0.4em;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.8em;
    font-size: 0.8em;
}
.main {
    font-weight: bold;
    font-size: 16px;
}
.info {
    font-size: 0.8rem;
}
.file-card{
    width: 100%;
    display: flex;
}
.file_background_design{
    background: white;
    border-radius: 4px;
    /* border: 1px solid #ced7e0; */
    box-shadow: 0px 0px 3px 0px #00000040;
}
.file_background_design_after{
    background: white;
    padding: 0px 8px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}
.file_type_uploaded{
    height: 20px;
    width: 20px;
    object-fit: contain;
}
.file_background_design_item{
    height: 34px;
    display: flex;
    justify-content: center;
}
.delete_container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.delete_icon{
    display: flex;
    justify-content: flex-end;
}
.file_item{
    margin: 4px !important;
    height: 44px;
    padding: 3px;
}
.file_item_after_uploaded{
    margin-top: 4px !important;
    /* height: 24px; */
    /* padding: 3px; */
}
.file_name{
    font-size: 15px;
    display: flex;
    align-items: end;
    padding: 3px;
}
.delete_onclick{
    cursor: pointer;
}
.file_size{
    font-size: 13px;
    color: #A6A6A6;
}
.file-inputs-avatar{
    justify-content: center;
    align-items: center;
    min-height: 37px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 22%) 0px 0px 2px 0px !important;
    border: none !important;
    border-radius: 6px;
    height: 35px;
    position: relative;
    font-weight: 500;
}
.file-inputs-avatar input {
    position: relative;
    text-align: right;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    padding: 0px;
    border: 0px;
}
.file-inputs-avatar button {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    padding: 0px;
    border: none;
    outline: none;
    transition: background-color 0.4s;
    /* box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5); */
}
.file-inputs-avatar button i {
    width: 1.5em;
    height: 1.5em;
    padding: 0.4em;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.8em;
    font-weight: 500;
}