@import "../../../utils/colorCode.scss";

.draggableIconStyle {
    color: #A5A5A5;
    margin-right: 9px;
}

.fieldItemStyle {
    font-size: 15px;
    color: $black;
}

.dragDropContainer {
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    // padding: 15px;
    // min-height: 150px !important;
    // height: auto !important;
    // max-height: 150px !important;
    // overflow: auto !important;

}

.draggableListContainer {
    min-height: 150px !important;
    max-height: 150px !important;
    overflow-y: auto !important;
    margin: 0 auto !important;
}

.draggableListContainerBox {
    div:first-child {
        width: 100%
    }
}

// .asdf {
//     div:first-child {
//         width: 100%
//     }
// }

.draggableListContainer::-webkit-scrollbar {
    width: 5px;
    height: 2px;
}

.draggableListContainer::-webkit-scrollbar-track {
    max-height: 50%;
    height: 50%;
    left: 5px
}

.draggableListContainer::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 5px;
}

.fieldListTextStyle {
    font-size: 15px;
    font-weight: 600;
    color: #1F384C;
    margin-left: 6px !important;
}

.AddFieldTextStyle {
    font-size: 15px;
    font-weight: 400;
    color: $grey;
    margin: 0px 15px 0px 10px
}

.upperBlock {
    margin-bottom: 15px !important;
    overflow: auto !important;
    position: sticky !important;
    top: 10px !important;
    background-color: #FFF !important;
    margin: 10px auto 20px !important;
    z-index: 10 !important;
}

.fieldTextfieldstyle :global(.MuiOutlinedInput-notchedOutline) {
    border-width: 0px !important;
}

.fieldTextfieldstyle :global(.MuiInputBase-input) {
    padding: 0px !important;
}

.deleteIconStyle {
    margin-right: 15px !important;
    cursor: pointer;
}

.templatePromp {
    min-height: 120px !important;
    max-height: 120px !important;
    overflow: auto !important;
    border-radius: 5px !important;
    padding: 2px 5px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
}

.templatePromp ::before {
    white-space: break-spaces;
    ;
}