.uploadFileInput {
    /* box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.10);*/
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05); 
    background: #fff;
    border-radius: 3px;
    /* border: unset !important; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 63px;
}
.chatbotFormField{
    /* box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25); */
    /* box-shadow: 0px 0px 2px 0px #00000040; */
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.15); 
}
.uploadFileInput {
    position: relative;
    width: 100%;
}
 .uploadFileInput input {
    position: relative;
    text-align: right;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    padding: 0px;
    border: 0px;
}
.uploadFileInput button {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 4px;
    padding: 0px;
    border: none;
    outline: none;
    /* box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5); */
}
.colorpickerTestDemo{
    /* box-shadow: 0px 0px 2px 0px #00000040; */
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
    border: 1px solid rgba(0, 0, 0, 0.06); 
}
.colorpickerTestDemo .MuiTextField-root {
    height: 100%;
}
.colorpickerTestDemo .MuiInputBase-root{
    height: 100%;
}
.colorpickerTestDemo button{
    margin:13px 10px 13px 15px !important
}
.colorpickerTestDemo .ColorPicker-MuiInputBase-input{
    padding: 15px 0px 15px 10px;
}
.colorpickerTestDemo .ColorPicker-MuiInput-underline:before{
    border:none;
}

.colorpickerTestDemo .ColorPicker-MuiInput-underline:hover:not(.Mui-disabled):before{
    border:none;
}

.colorpickerTestDemo .ColorPicker-MuiInput-underline:after{
    border:none;
}
/* chatbot start */
.colorPicker {
    
}
.fileUploadedGrid {
    padding: 10px !important;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05);
    /* box-shadow: 0px 0px 2px 0px #00000040 !important; */
    border-radius: 3px;


}
/* chatbot end */