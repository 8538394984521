.sidebarcomponentGridContainer{
    max-width: 360px !important; 
    box-shadow: 0 3px 5px 0 #ADADAD !important;
    flex-wrap: nowrap !important; 
    padding: 30px !important; 
    gap: 80px !important; 
    max-height: calc(100vh - 64px) !important; 
    overflow-y: scroll !important; 
    overflow-x: hidden !important; 
    min-height: 100% !important;
} 