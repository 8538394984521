@import "../../../utils/colorCode.scss";

.stepperDescriptionStyle {
    font-size: 13px !important;
    color: $grey !important;
    margin-bottom: 30px;
    max-width: 247px !important;
    padding-top: 5px !important;
}

.stepperLabelStyle {
    color: #4C4A4D !important;
    font-size: 15px !important;
}

.stepLabelStyle {
    display: flex;
    align-items: start !important;
    padding: 0 !important;
}

.stepperStyle .MuiStepLabel-vertical {
    padding: 0 !important;
}

.stepperTitleGrid {
    font-size: 20px;
    font-weight: 600;
    color: $primary;
    margin-bottom: 30px !important;
    text-align: start;
}