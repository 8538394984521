// .cmpDoumentBtn {
//     margin: 0px 15px !important;
// }

.cmpDoumenCardHeadItem {
    margin: 0px 0px 30px !important;
    padding: 20px 0px !important;
    border-bottom: 1px solid #B3B3B3 !important;
}



.cmpDocCardNameItem {
    min-width: 240px !important;
    max-width: 240px !important;
    min-height: auto !important;
    height: auto !important;
    overflow: auto !important;
    max-height: 200px !important;
    border-radius: 10px !important;
    background: #FFF !important;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08) !important;
    padding: 20px !important;
    margin: 0px 18px 36px 18px !important;
    // margin: 0px 35px 25px 0px !important;

}

.cmpDocCardIconItem {
    margin-bottom: 15px !important;
}

.cmpDocCardNameItem {
    position: relative;
}

.cmpCardCheckbox {
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    padding: 8px !important;
}



.cmpSelectedCard {
    border: 1px solid #6A097D !important;
}

.cmpNameBackbtn {
    color: black !important;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.cmpDocumentsBtn {
    background-color: #6A097D !important;
    color: white !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
}


.cmpDocumentsDisabledBtn {
    background-color: #6A097D !important;
    opacity: 0.5 !important;
    color: white !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
}

.cmpDocCardMainContainer {
    justify-content: start;
}


@media screen and (min-width : 320px) and (max-width: 599px) {
    .cmpDocCardNameItem {
        min-width: 300px !important;
        max-width: 300px !important;
        min-height: auto !important;

        margin: 2px 0px 36px 5px !important;
        // margin: 0px 35px 25px 0px !important;

    }

    .cmpDocumentsDisabledBtn {
        position: absolute !important;
        bottom: 0 !important;
        left: 15px !important;
        width: 60% !important;
        margin-bottom: 15px !important;
    }

    .cmpDocumentsBtn {
        position: absolute !important;
        bottom: 0 !important;
        left: 15px !important;
        width: 60% !important;
        margin-bottom: 15px !important;
    }


    .cmpDocCardMainContainer {
        height: 70vh;
        overflow: auto;
        justify-content: start;
    }

    .cmpDocCardNameMainItem {
        margin-bottom: 70px !important;
    }

    .cmpDoumenCardHeadItem {
        margin: 0px 0px 5px !important;
        padding: 10px 0px 20px !important;
        border-bottom: 1px solid #B3B3B3 !important;
    }


    .cmpDoumentBtn {
        position: absolute !important;
        bottom: 6px !important;
        left: 20px !important;
    }

    @media screen and (min-width : 0px) and (max-width: 375px) {
        .cmpDocCardNameItem {
            min-width: 250px !important;
            max-width: 250px !important;
            min-height: auto !important;
            margin: 0px 0px 36px 0px !important;
            // margin: 0px 35px 25px 0px !important;

        }

        .cmpDocCardMainContainer {
            justify-content: center;
        }
    }

}