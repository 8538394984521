.pcLabelItems {
    margin-bottom: 15px !important;
}

.personaCardMainItem {

    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    width: 325px;
    min-width: 325;
    max-width: 325px;
    height: 210px;
    max-height: 210px;
    margin: 0px 30px 30px 0px !important;
    cursor: pointer;
    position: relative !important;
}

.personaDefaultStyle {
    border: 2px solid transparent;
    background-image: linear-gradient(white, white),
        linear-gradient(to right, #ac19c9, #0A6ADD);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.personaCardMainItem:hover {
    box-shadow: 0px 0px 8px 0px #6A097DB2 !important;
}

.pcIndividualProfileItem {
    border-radius: 10px 10px 0px 0px;
    background: #FDF8FF;
    width: 100%;
    text-align: end;
    padding: 20px 20px;
}

.pcBrandProfileItem {
    border-radius: 10px 10px 0px 0px;
    background-color: #F1F7FD !important;
    width: 100%;
    text-align: end;
    padding: 20px 20px;
}

.pcUserTagNameText {
    color: #6A097D !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: normal !important;
    display: flex !important;
    align-items: center !important;
    justify-content: end !important;
}

.pcProfileItem {
    padding: 0px 20px 20px !important;
    margin-top: -45px !important;
    max-width: 320px !important;
}


.pcUserNameLabelItem {
    margin: 5px 0px 0px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.pcUserNameLabel {
    color: #000 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.pcUserPostLabelItem {
    margin: 0px 0px 12px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.pcUserPostLabel {
    color: #000 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.pcChipsBtn {
    text-transform: capitalize !important;
    border-radius: 5px !important;
    background: #FFF !important;
    border: 1px solid #6A097D !important;
    color: #575757 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}

.pcChipsItem {
    margin: 8px 0px !important;
}

.pcChipsContainer {
    gap: 5px;
}

.personaAvtarProfileImage {
    object-fit: cover !important;
    width: 70px !important;
    height: 70px !important;
    border: 4px solid #fff !important;
    border-radius: 40px !important;

}

.personaIndividualTypeLabel {
    color: #6A097D;
}

.personaBrandTypeLabel {
    color: #0A6ADD !important;
}

.personaMainContainer {
    padding: 27px 40px;
}


.personaTypeItem {
    position: absolute;
    right: 20px;
    top: 20px;
}

.personaPremiumTypeItem {
    position: absolute;
    right: 50px;
    top: 20px;
}

.noDataFoundItem {
    height: calc(100vh - 250px) !important;
    display: flex;
    align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 1200px) {
    .pcUserNameLabelItem {
        margin: 5px 0px 0px !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 260px !important;
    }

}





@media screen and (min-width: 900px) and (max-width: 1030px) {
    .personaCardMainItem {
        width: 300px;
        min-width: 300px;
        max-width: 300px;
        height: 210px;
        max-height: 210px;
        margin: 0px 15px 30px 0px !important;
        cursor: pointer;
    }

    @media screen and (min-width: 900px) and (max-width: 950px) {
        .personaMainContainer {
            padding: 27px 15px;
        }
    }
}

@media screen and (min-width: 600px) and (max-width: 899px) {
    .personaSearchMainItem {
        margin: 0px 0px 20px !important;
    }
    .personaCardMainItem {
        min-width: 300px;
        max-width: 300px;
        height: 210px;
        max-height: 210px;
        margin: 0px 15px 30px 0px !important;
        cursor: pointer;
    }
}

@media screen and (min-width: 320px) and (max-width: 599px) {

    .personaCardMainItem {
        min-width: 300px;
        max-width: 300px;
        height: 210px;
        max-height: 210px;
        margin: 0px 15px 30px 0px !important;
        cursor: pointer;
    }

    .personaMainContainer {
        padding: 27px 15px;
    }

    @media screen and (min-width: 320px) and (max-width: 371px) {

        .personaSearchMainItem {
            margin: 0px 0px 20px !important;
        }
    }
}