.errorPageGridContainer{
    background-color: white !important;
    height: 100vh !important; 
    display: flex !important; 
    align-items: center !important;  
    justify-content: center !important;
    padding: 15px !important;
    min-height: 15vh !important;
    overflow-y: scroll !important;
    object-fit: contain !important;
}

.errorPageGridBox{    
    max-height: 90vh !important; 
    background-color: rgba(255,255,255,1) !important; 
    border-radius: 15px !important; 
    display: flex !important; 
    flex-direction: column !important; 
    align-items: center !important; 
    justify-content: center !important;  
    padding: 25px !important;
}

.errorImage{
    height: 400px !important;
    width: 400px !important;
    margin-bottom: 5px !important;
}

.somethingWentText{
    font-size: 30px !important;
    font-weight: 500 !important; 
    color: #6A097D !important;
    text-align: center !important;
    margin-bottom: 20px !important;
    box-sizing: border-box !important;
}

.errorText{
    font-size: 16px !important;
    font-weight: 500 !important; 
    color: black !important; 
    text-align: center !important; 
    // padding: 0px 200px !important;
    padding: 5px !important;
    box-sizing: border-box !important;

}

.primaryButtonStyle {
    background: #6A097D !important;
    color: #FFF !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 8px 20px !important;
    border-radius: 5px !important;
    text-transform: capitalize !important;
    margin-block: 35px 65px !important;
}


@media screen and (min-width: 320px) and (max-width: 599px) {
    .errorPageGridBox{
        padding: 15px !important;
    }
    .errorImage{
        height: 300px !important;
        width: 300px !important;
    }
    .somethingWentText{
        font-size: 24px !important;
    }
    .errorText{
        font-size: 15px !important;
        padding: 10px !important;
    }
}
@media screen and (min-width: 600px) and (max-width: 899px) {
    .errorPageGridBox{
        padding: 20px !important;
    }
    .errorImage{
        height: 350px !important;
        width: 350px !important;
    }
    .somethingWentText{
        font-size: 26px !important;
    }
    .errorText{
        font-size: 15px !important;
        padding: 10px !important;
    }
}
@media screen and (min-width: 900px) and (max-width: 1199px) {
    .errorPageGridBox{
        padding: 20px !important;
    }
    .errorImage{
        height: 400px !important;
        width: 400px !important;
    }
    .somethingWentText{
        font-size: 27px !important;
    }
    .errorText{
        font-size: 15px !important;
        padding: 5px !important;
    }
}