@import "../../../utils/colorCode.scss";

.header {
    background-color: unset !important;
    // box-shadow: none !important;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25) !important;
}

.header :global(.MuiToolbar-root) {
    justify-content: space-between;
}

.documentTitleStyle {
    border-left: 1px solid #BDBDBD;
    font-weight: 600 !important;
    font-size: 15px !important;
    color: $black;
    margin: 0 0px 0 20px !important;
    padding-left: 20px
}

.docNameTextfieldStyle {
    min-width: 100% !important;
    border-left: 1px solid #BDBDBD;
    font-weight: 600 !important;
    font-size: 15px !important;
    color: $black;
    margin: 0 0px 0 20px !important;
    // min-width: 700px;
    // padding-left: 20px
}

.docNameTextfieldStyle :global(.MuiTextField-root) {
    width: 100%;
}

.docNameTextfieldStyle :global(.MuiInputBase-root) {
    font-weight: 600 !important;
    font-size: 15px !important;
    width: 100%;
}

.docNameTextfieldStyle :global(.MuiInputBase-input) {
    text-align: start;
    padding-left: 15px;
    width: 100%
}

.docNameTextfieldStyle :global(.MuiInputBase-root::after) {
    border-bottom: 0px !important;
}

.docNameTextfieldStyle :global(.MuiInputBase-root::before) {
    border-bottom: 0px !important;
}

.creditWrapper {
    font-weight: 600;
    margin-right: 15px !important;
    display: flex;
    align-items: center;
    color: $black;
}

@media screen and (max-width: 599px) {
    .header {
        background: $sideNavigationPanel !important;
        box-shadow: unset !important;
    }

    .writewizLogo {
        display: none
    }

    .headerButtonWrapper {
        display: none;
    }

    .creditWrapper {
        display: none;
    }

    .headerToolBar {
        padding: 0px !important;
    }

    .docNameTextfieldStyle {
        border-left: 0px;
    }

    .docNameTextfieldStyle {
        margin: 0px !important;
    }

    .docNameTextfieldStyle :global(.MuiInputBase-input) {
        padding: 0 !important;
    }
}

.menuItemList :global(.MuiListItemText-primary){
    font-size: 14px !important;
    font-weight: 600 !important;
}
.menuItemList{
    width: 100% !important; 
    max-width: 275px !important; 
    // background-color: 'background.paper' !important; 
    padding: 0px !important;
}

.subListMenuItem{
    padding-inline: 32px !important;
}

.menuItemList :global(.MuiListItemIcon-root){
    min-width: 40px !important;
}

.exploreMenuButton{
    border-radius: 50% !important;
    display: flex !important; 
    align-items: center !important; 
    text-transform: capitalize !important;  
    color: black !important; 
    padding: 3px 3px !important; 
    text-align: start !important; 
    min-height: 27px !important; 
    min-width: 27px !important;
    height: 27px !important;
    cursor: pointer !important;
}

.exploreMenuButtonOpen{
    border-radius: 50% !important;
    display: flex !important; 
    align-items: center !important; 
    text-transform: capitalize !important;  
    color: black !important; 
    padding: 0px 3px !important; 
    text-align: start !important; 
    min-height: 27px !important; 
    min-width: 27px !important;
    height: 27px !important;
    background-color: rgba(0, 0, 0, 0.15) !important;
    color: rgba($color: #000000, $alpha: 0.6) !important;
    cursor: pointer !important;
}

.exploreMenuButton:hover{
    background-color: rgba(0, 0, 0, 0.15) !important;
}

.exploreMenuButtonWidth{
    min-width: 210px !important;
}

.creditWrapper :global(.MuiPaper-elevation){
    min-width: 210px !important;
}

.listItemButtonComponent{
    font-size: 13px !important; 
    font-weight: 500 !important; 
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    border-top: 1px solid #E9E9E9 !important; 
    border-bottom: 1px solid #E9E9E9 !important;
}

.emailListComponent{
    word-wrap: break-word !important;
}

.menuItemList :global(.MuiListItemText-primary){
    max-width: 275px !important;
    word-wrap: break-word !important;
}

.headerButtonWrapperSM{
    display: flex !important;
}

.exploreMenuGrid{
    display: flex !important;
    align-items: center !important;
    margin-inline: 16px !important;
}

.moreVerticalIcon{
    display: flex !important;
    align-items: end !important;
}

.moreVerticalIconMenu{
    display: flex !important; 
    padding-block: 0px !important;
}

.expandMoreGridMobile{
    display: flex !important; 
    align-items: center !important; 
}

@media screen and (max-width:650px) {
    .headerButtonWrapperSM {
        display: none !important
    }

    .headerButtonWrapperXS {
        display: flex !important;
    }
}

.headerButtonWrapperXS {
    display: none;
}

.documentNameAreaContainer {
    border-left: 1px solid #BDBDBD !important;
    min-width: 100% !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    color: $black;
    margin: 0 0px 0 15px !important;
}

.documentNameAreaContainer :global(.MuiTextarea-root) {
    border: unset !important;
    background-color: $white;
}

@media screen and (min-width:1450px) {
    .documentNameAreaContainer {
        min-width: 1000px !important;
    }
}

@media screen and (min-width:1000px) and (max-width: 1450px) {
    .documentNameAreaContainer {
        min-width: 700px !important;
    }
}

@media screen and (min-width:1000px) and (max-width: 1450px) {
    .documentNameAreaContainer {
        min-width: 550px !important;
    }
}

@media screen and (max-width: 599px) {
    .documentNameAreaContainer {
        min-width: 100% !important;
        border-left: unset !important;
        min-width: 100% !important;
        font-weight: 600 !important;
        font-size: 15px !important;
        color: $black;
        margin: unset !important;
    }

    .documentNameAreaContainer :global(.MuiTextarea-root) {
        border: unset !important;
        background-color: #FBF4FF !important;
        border-left: unset !important;
    }

    .headerWrapper {
        width: 100%
    }
}

@media screen and (min-width:599px) and (max-width:780px) {
    .documentNameAreaContainer {
        min-width: unset !important;
    }
}