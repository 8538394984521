@import "../../utils/colorCode.scss";

.primaryFontSize {
    font-size: 15px !important;
}

.tertiaryFontSize {
    font-size: 13px !important;
}

.primaryFontWeight {
    font-weight: 600 !important;
}

.secondaryFontWeight {
    font-weight: 500 !important;
}

.primaryColor {
    color: $primary !important;
}

.clickable {
    cursor: pointer;
}

.fullwidth {
    width: 100% !important;
}

.displayFlex {
    display: flex;
    align-items: center;
}

.centerAlignment {
    align-items: center;
    justify-content: center;
}

.globalTextfieldStyle {
    width: 100%;
    display: flex;
    justify-content: space-around;
    background: #fff;

    fieldset {
        border-width: 0px !important;
        border-color: unset !important;
    }
}

.globalTextfieldStyle :global(.MuiFormHelperText-root) {
    margin-left: 0px !important;
    font-size: 13px;
}

.globalTextfieldStyle :global(.MuiInputBase-input) {
    padding: 14px !important;
    // padding-right: 0px !important;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 3px;

}

.globalTextStyleBlack {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: $black !important;
}

.globalTextStyleGrey {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: $grey;
    text-align: center;
}

.required {
    color: $required !important;
    padding-left: 3px;
}

.gridItemStyle {
    width: 100%;
    display: flex !important;
    align-content: center !important;
}



.htmlGridContainer {
    padding: 50px !important;
    flex-wrap: nowrap !important;
    height: 100vh !important;
}

.htmlHeading {
    font-size: 20px !important;
    font-weight: 600 !important;
}

.htmlGridItemStyles {
    height: 100% !important;
    width: 100% !important;
}

.fullHeight {
    height: 100%;
}

.dividerStyle {
    border-color: #6A6A6A !important;
}

.formWrapper {
    max-height: 730px;
    overflow-y: auto;
    overflow-x: hidden !important;
    // height: 590px
}

.formWrapperSingleTemplate {
    padding: 10px 20px 20px;
    overflow-y: scroll;
    height: 100%;
}

.authLabel {
    // position: absolute;
    // top: 19%;
    font-size: 26px;
    font-weight: 600;
    color: $primary;
    // margin-bottom: 20px !important;
    margin-bottom: 35px !important;
    text-align: center;
}

.authSublabelText {
    font-size: 18px;
    font-weight: 600;
    color: $black;
    // margin-bottom: 40px !important;
    // margin-bottom: 35px !important;
    margin-bottom: 20px !important;
    text-align: center;
}

.authSublabelProductTextStyle {
    font-size: 19px;
    font-weight: 600;
    color: $primary;
}

.resendButtonStyle {
    color: #6A097D;
    margin-left: 5px;
}

.dividerStyle {
    font-size: 12px;
    font-weight: 600;
    color: $lightGrey;
    margin-top: 9px !important;
    padding-left: 20px !important;
    border-style: unset;
}

.continueWithGoogleButton {
    border-radius: 3px !important;
    border: 1.5px solid #CDCDCD !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    height: 52px !important;
    min-height: 52px;
    max-height: 52px;
    width: 385px !important;
    text-transform: unset !important;
    color: $black !important;
    margin: 0px 0px 10px !important;
    justify-content: center !important;
    padding: 0 !important;
    height: 56px !important;
}


.authPromptTextStyleLogin {
    font-size: 15px;
    font-weight: 600;
    color: $black;
    margin-top: 8px !important;
    // text-align: center;
    text-align: start;
}

.authPromptTextStyleRegistration {
    font-size: 15px;
    font-weight: 600;
    color: $black;
    // text-align: center;
    text-align: start;
}

.authPromptSubTextStyle {
    font-size: 15px;
    font-weight: 600;
    color: $primary
}

.inputLabel {
    font-weight: 600 !important;
    color: $black !important;
    font-size: 15px !important;
    margin-bottom: 8px;
}

.dashboardOutletWrapper {
    width: 100% !important;
    flex-wrap: nowrap;
    // padding: 40px;
}

.dashboardChildWrapper {
    padding: 27px 40px;
    // height: 100% !important;
    flex-wrap: nowrap !important;
}

.dashboardChildTitleTextStyle {
    font-size: 18px !important;
    font-weight: 600;
    color: $black;
    width: 100%;
    margin-bottom: 27px !important;
}

// persona style

.updatePersonaFullContainer {
    box-shadow: 0px 0px 3px 0px #00000040;
    padding: 24px;
    border-radius: 10px;
}

@media screen and (max-width:599px) {
    .updatePersonaFullContainer {
        padding: 15px !important;
        flex-wrap: nowrap !important;
    }

    .updatePersonaFullContainerForm {
        flex-wrap: nowrap;
        display: flex;
        height: calc(100vh - 157px);
    }
}

.dashboardChildTitleTextStylePersona {
    font-size: 18px !important;
    font-weight: 600;
    color: $black;
    width: 100%;
    margin-bottom: 8px !important;
}

.globalSearchBar {
    box-shadow: 0px 0px 2px 0px #5F6368 !important;
    // width: 512px !important;
    height: 33px !important;
}

.globalSearchBarHeight {
    box-shadow: 0px 0px 2px 0px #5F6368 !important;
    // width: 512px !important;
    height: auto !important;
}

.searchBar {
    max-width: 262px;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px $grey;
}

.searchBar :global(.MuiInputBase-root) {
    max-width: 262px;
    width: 100%;
    border-radius: 5px;
    border: unset;
}

.searchBar :global(.MuiInputBase-input) {
    max-width: 262px;
    width: 100%;
    border-radius: 5px;
    border: unset;
    padding: 5px 10px !important;

    & placeholder {
        font-size: 10px;
    }
}

.searchBar :global(.MuiOutlinedInput-notchedOutline) {
    border-width: 0px !important;
}

.dashboardChildSectionTextStyle {
    font-size: 15px;
    font-weight: 600;
    color: #1F384C;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

.containerFlexWrap {
    flex-wrap: nowrap !important;

}

.containerflexWrapStyle {
    flex-wrap: nowrap !important;
    align-items: center;
}

// .dashboardNavigationToggleButtonStyle {
//     border-radius: 25% !important;
//     background: #6A097D !important;
//     position: fixed !important;
//     bottom: 5% !important;
//     left: 8% !important;
//     color: $white !important;
// }

.dashboardNavigationToggleButtonStyle {
    position: absolute !important;
    padding: 13px 8px 8px !important;
    // padding: 16px 8px !important;
    // z-index: 1101;
    // position: sticky !important;
    // top: 0 !important;
}


@media screen and (min-width: 320px) and (max-width : 600px) {
    .headerMenuIcon {
        padding: 16px 8px !important;
        z-index: 1101;
        position: sticky !important;
        top: 0 !important;
    }

}


// Global Table


// .tableContainer {
//     border: 1px solid #E9E9E9;
//     border-radius: 5px 5px 0px 0px;
//     overflow: auto;
// }

.WritewizGlobaltable {
    border: 1px solid #E9E9E9 !important;
    border-radius: 5px 5px 0px 0px !important;
}

.headerRow th {
    background-color: #FBF4FF;
    font-size: 15px;
    font-weight: 500;
    white-space: pre;
    color: #000000;
}

.tableRowStyle {
    color: #373737;
    display: flex;
    align-items: center;
}

.additionalRowClass {
    white-space: pre;
}

.bodyRow:hover {
    background: #f8f8f8;
}

.selectedBodyRow {
    background: #F7E9FF;
}

.bodyRow td {
    font-size: 15px;
    font-weight: 400;
    padding: 10px 16px 10px 16px !important;
    cursor: pointer;
}

.tableCategoryImage {
    height: 18px;
    width: 18px;
}

//Chip
.globalTileStyle {
    max-width: 220px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 54px;
    box-shadow: 0px 0px 4px 0px #D9D9D9;
    border-radius: 5px;
    // margin-right: 10px !important;
}

.globalTileStyle:hover {
    max-width: 220px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 54px;
    box-shadow: 0px 2px 4px 0px #00000040;
    border-radius: 5px;
    // margin-right: 10px !important;
}

.featureIconStyle {
    margin: 15px !important
}

.headText {
    font-size: 15px;
    font-weight: 600;
    color: $black;
}

.descriptionText {
    font-size: 12px;
    font-weight: 400;
    color: #7A7A7A;
}

.featuredTilesWrapper {
    margin-bottom: 25px !important;
    margin-top: 25px !important;
}

//Mobile Footer

.mobileFooterWrapper {
    display: none;
}

.mobileFooterSelect {
    min-width: 160px;
    max-width: 290px;
    box-shadow: 0px 0px 2px 0px #00000040;
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    // font-size: 1rem;
    cursor: pointer;
}

.mobileFooterSelect div {
    padding: 6px 14px !important;
}


.ppPlanSlider :global(.MuiSlider-markLabel) {
    font-size: 12px;
    color: #7A7A7A;
}

.ppPlanSlider :global(.MuiSlider-mark) {
    display: none;
}

.ppPlanSlider :global(.MuiSlider-thumb) {
    border-radius: 3px;
    width: 4px;
    height: 15px;
    color: $primary;
}

.ppPlanSlider :global(.MuiSlider-rail) {
    background: #E1E1E1;
    height: 5px;
}

.globalCardBulletListText {
    padding-bottom: 5px;
}

.globalCardBox {
    box-shadow: 0px 0px 2px 0px $primary;
    border-radius: 10px;
    padding: 20px 15px;
    font-size: 15px;
}

.globalCardBoxDescription {
    overflow: auto;
}

// .globalCardBoxDescription::-webkit-scrollbar {
//     width: 0.2em;
//     height: 2px
// }

// .globalCardBoxDescription::-webkit-scrollbar-track {
//     max-height: 50%;
//     height: 50%;
// }

// .globalCardBoxDescription::-webkit-scrollbar-thumb {

//     background-color: #D9D9D9;
//     outline: 1px solid #D9D9D9;
//     border-radius: 5px;
//     opacity: 0.50 !important;
// }

.globalCardHeading {
    color: $primary;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 500;
    // margin: 10px 0px !important;
    margin: 0px 0px 10px 0px !important
}

.globalCardBullet {
    color: $black;
    font-family: Open Sans;
    font-size: 15px;
    padding: 0px 0px 0px 20px;
}

// Global table css ---
.iconCell {
    width: 60%;
}

.iconCellLastModifield {
    width: 20%;
}

.documentClickCell {
    cursor: pointer;
}

.rowTextfield :global(.MuiInputBase-input) {
    padding: 4.5px 14px !important;
}

.rowTextfieldTable :global(.MuiInputBase-input) {
    padding: 4.5px 14px !important;
}

.rowTextfieldTable :global(.MuiOutlinedInput-notchedOutline) {
    border: none !important;
}

.globalMenuStyle :global(.MuiPaper-root) {
    filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.1)) !important;
    box-shadow: none !important;
}

.menuItemStyle {
    min-height: auto !important;
    color: $black !important;
    margin: 2px 10px !important;
    border-radius: 7px !important;
    display: flex !important;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: start !important;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: unset !important;
    outline: 0;
    border: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    box-sizing: border-box !important;
    white-space: nowrap !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.menuItemStyle :global(.MuiListItemIcon-root) {
    color: $primary;
}

.menuItemStyle:hover {
    background: #EFD7FE !important;
    color: $primary;

}

//   Home page css
.homeBoxDesign {
    width: 100%;
    height: 160px;
    top: 84px;
    left: 280px;
    border-radius: 10px;
    border: 1px;
    border: 1px solid $primary;
    box-shadow: 0px 0px 2px 0px $primary;
    margin-bottom: 20px !important;
    display: flex;
    flex-wrap: nowrap;
}

.newDocumentPopup :global(.MuiPaper-elevation) {
    max-width: 100% !important;
    width: 100%;
    height: 100%;
    margin: 30px 100px !important;
}

.newDocumentPopup :global(.MuiPaper-root)::-webkit-scrollbar {
    width: 4px;
}

.newDocumentPopup :global(.MuiPaper-root)::-webkit-scrollbar-track {
    background: #f3f5f9;
}

.newDocumentPopup :global(.MuiPaper-root)::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 20px;
}

.dashboardChildSectionTextStylePrimary {
    font-size: 15px;
    font-weight: 600;
    color: $primary;
    cursor: pointer;
    margin-bottom: 10px !important;
    // margin-bottom: 20px !important;
}

// Delete popup css
.deleteConfirmPopupStyle {
    height: 45px;
    // width: 45px;
    background: #CF2C2C33;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 19px !important;
    padding: 10px !important;
}

.deleteConfirmImage {
    // height: 22px;
    // width: 22px;
    width: 25px;
    height: auto;
}

.deleteConfirmPopupTextStyle {
    font-size: 16px;
    font-weight: 600;
    color: #cf2c2c;
    margin: 0px 0px 2px !important;
}

.deleteConfirmPopupSubTextStyle {
    font-size: 14px;
    font-weight: 500;
}

.deleteConfirmHeaderPopup {
    padding: 28px 28px 45px 28px !important;
}

.deleteConfirmButtonsPopup {
    background: #FBF4FFCC;
    padding: 15px 20px;
}



// delete new styles
.deleteConfirmationLogoItem {
    // height: 45px;
    width: 45px;
    max-width: 45px;
    background: #CF2C2C33;
    border-radius: 50%;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: 19px !important;
}


.globalDeleteLogoHeadContainer {
    display: flex;
    gap: 15px;
    align-items: start;
}



.cmpGlobalMobileAddIcon {
    background-color: white !important;
    color: #6A097D !important;
    border-radius: 5px !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
    padding: 10px !important;
}

.globalHeadText {
    color: #1F384C !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.globalDecsText {
    color: #7A7A7A !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}


@media screen and (max-width:550px) {
    .continueWithGoogleButton {
        width: 100% !important;
    }
}

@media screen and (min-width:0px) and (max-width:900px) {
    // .mobileFooterWrapper {
    //     display: block;
    // }

    .globalSearchBarHeight {
        box-shadow: 0px 0px 2px 0px #5F6368 !important;
        // width: 512px !important;
        height: 30px !important;
    }
}

@media screen and (min-width:0px) and (max-width:860px) {
    .mobileFooterWrapper {
        display: block;
    }
}

@media screen and (min-width:320px) and (max-width:600px) {
    .dashboardChildWrapper {
        padding: 25px 25px;
    }
}

::-webkit-scrollbar {
    width: 0.2em;
    height: 2px
}

::-webkit-scrollbar-track {
    max-height: 50%;
    height: 50%;
}

::-webkit-scrollbar-thumb {
    background-color: $primary;
    outline: 1px solid slategrey;
    border-radius: 5px;
    opacity: 0.50 !important;
}

// Global styles for responsiveness

.dashboardMaxWidthStyles {
    // max-width: 1800px !important;
    width: 100% !important;
    margin: 0 auto !important;
}

.generateTitleButtonWrapper {
    border-top: 0.5px solid #E7E7E7;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0px;

}

.generateTitleButtonWrapperBlogGeneration {
    margin-top: 20px !important;
}

.generateTitleButtonWrapperBlogGeneration2 {
    margin-top: 8px !important;
}


.openArrowStyle {
    width: 30px;
    position: fixed;
    // transform: translateX(80px) translateY(30px);
    transform: translateX(56px) translateY(30px);
    // right: -13px;
    height: auto;
    cursor: pointer;
    z-index: 2;
    top: 0;
    // top: 2%
}

.closeArrowStyle {
    width: 30px;
    // height: auto;
    height: -13px;
    transform: rotate(180deg);
    position: fixed;
    transform: translateX(217px) translateY(30px) rotate(180deg);
    // right: 94.6%;
    // right: -13px;
    cursor: pointer;
    z-index: 2;
    // top: 2%;
    top: 0;
}


.tooltipCard {
    padding: 3px 6px;
    box-shadow: 0px 0px 1.5px 0px #6A097D;
    border-radius: 5px;
    font-weight: 500;
    font-size: 10px;
    color: #575757;
    margin: 0px 8px 3px 0px !important;
}

.tooltipCardContainer {
    height: 24px;
    overflow: hidden;
    padding: 2px;
}

.tooltipFirstRow {
    flex-wrap: nowrap !important;
    width: 189px !important;
}

.tooltipCardItem {
    margin-top: 6px !important;
}

// .backDropStyle {
//     background-color: transparent !important;
// }

.backDropStyle div {
    color: #6A097D !important;
}

.removeGlobalCss {
    display: block !important;
    padding: 6px 10px !important;
}

.featuredTempMainContainer {
    gap: 10px;
    align-items: center;
}

.featureAllTempLabelContainer {
    gap: 10px;
}

// --------- global Heading css ----------


.globalHeadMainItemRightContainer {
    justify-content: end;
}

.globalHeadLeftSubItem {
    margin: 0px 8px !important;
    // max-width: 286px !important;
}

.globalHeadRightSubItem {
    margin: 0px 8px !important;
    // max-width: 251px !important;
}

.globalSearchbarSubItem {
    width: 286px;
}

.globalNewAddBtnItem {
    width: 268px;
}

.addDocumentBtn {
    border: none !important;
    border-radius: 5px !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
    align-items: center !important;
    text-transform: capitalize !important;
    color: #000000 !important;
    padding: 8px 10px !important;
}

.globalRedirecetionBtn {
    border: none !important;
    align-items: center !important;
    text-transform: capitalize !important;
    color: #1F384C !important;
    padding: 0px !important;
    text-decoration: underline !important;
    font-weight: bold !important;
}

.globalPopOverTooltip {
    word-wrap: normal !important;
}


// Settings -------- 

.settingsPageMainItem {
    padding: 30px 40px !important;
}

@media screen and (max-width : 425px) and (min-Width : 320px) {
    .settingsPageMainItem {
        padding: 30px 20px !important;
    }


}

// Settings -------- 

@media screen and (min-width: 1536px) {

    .globalHeadLeftSubItem {
        margin: 0px 8px !important;
        max-width: 286px !important;
    }

    .globalHeadRightSubItem {
        margin: 0px 8px !important;
        max-width: 286px !important;
    }

}


@media screen and (min-width: 1440px) and (max-width : 1535px) {


    .globalHeadLeftSubItem {
        margin: 0px 8px !important;
        max-width: 260px !important;
    }

    .globalHeadRightSubItem {
        margin: 0px 8px !important;
        max-width: 260px !important;
    }

}

@media screen and (min-width: 900px) and (max-width : 1199px) {

    .globalHeadMainItemRightContainer {
        justify-content: start;
        align-items: center;
        margin-bottom: 20px;
    }

    .globalHeadLeftSubItem {
        margin: 0px 8px 0px 0px !important;
        max-width: 260px !important;
    }

    .globalHeadRightSubItem {
        margin: 0px 8px !important;
        max-width: 260px !important;
    }

}

@media screen and (min-width: 599px) and (max-width : 899px) {

    .globalHeadMainItemRightContainer {
        justify-content: start;
        align-items: center;
    }

    // .globalHeadRightSubItem {
    //     margin: 0px !important;
    // }

    .globalHeadRightSubItem {
        margin: 0px 5px !important;
        max-width: 50px !important;
    }

    .globalHeadLeftSubItem {
        margin: 0px 5px 0px 0px !important;
        max-width: 280px !important;
    }

    @media screen and (min-width: 599px) and (max-width : 668px) {

        .globalHeadLeftSubItem {
            margin: 0px 5px 0px 0px !important;
            max-width: 200px !important;
        }

        .globalHeadRightSubItem {
            margin: 0px 5px !important;
            max-width: 50px !important;
        }
    }
}

@media screen and (min-width: 320px) and (max-width : 599px) {

    .globalHeadMainItemRightContainer {
        justify-content: start;
        align-items: center;
    }

    .globalHeadRightSubItem {
        margin: 0px 5px !important;
    }

    .globalHeadLeftSubItem {
        margin: 0px 5px 0px 0px !important;
        max-width: 250px !important;
    }

    @media screen and (min-width: 320px) and (max-width : 373px) {
        .globalHeadLeftSubItem {
            max-width: 200px !important;
        }
    }
}


// --------- global Heading css ----------


@media screen and (max-width:599px) and (min-width:0px) {
    .closeArrowStyle {
        display: none !important;
    }

    .openArrowStyle {
        display: none !important;
    }
}


@media screen and (min-width: 1535px) {

    // Global table css ---
    .iconCell {
        width: 50%;
    }

    .iconCellLastModifield {
        width: 20%;
    }
}

@media screen and (min-width: 1440px) and (max-width : 1536px) {
    .iconCell {
        width: 45% !important;
    }

    .iconCellLastModifield {
        width: 20% !important;
    }
}

@media screen and (min-width: 1200px) and (max-width : 1440px) {
    .iconCell {
        width: 44% !important;
    }

    .iconCellLastModifield {
        width: 20% !important;
    }
}

@media screen and (min-width: 900px) and (max-width : 1200px) {
    .iconCell {
        width: 70% !important;
    }

    .iconCellLastModifield {
        width: 26% !important;
    }
}

@media screen and (min-width: 600px) and (max-width : 900px) {
    .iconCellLastModifield {
        width: 40%;
    }

}

@media screen and (min-width: 320px) and (max-width : 599px) {
    .featuredTempMainContainer {
        gap: 10px;
    }

}

.blogGenerationFormStyles {
    max-height: 700px;
    overflow-y: scroll;
}

.autocompleteParaphrasing {
    width: 100%;
    box-shadow: rgb(0 0 0 / 60%) 0px 0px 2px 0px;
    border: none !important;
    background-color: white !important;
    outline: none !important;
    font-family: inherit !important;
    width: 100% !important;
    border-color: #cccccc !important;
    font-weight: 500;
    letter-spacing: 0.13px !important;
    border-radius: 4px;
    min-height: 40px !important;
    padding: 0px !important;

}

.autocompleteParaphrasing :global(.MuiChip-deleteIcon) {
    color: #010101 !important;
    font-size: 15px !important;

}

.autocompleteParaphrasing :global(.MuiFormControl-root) {
    background: #ffffff;
    border: none !important;
    border-radius: 4px;
    min-height: 40px
}

.autocompleteParaphrasing :global(.MuiAutocomplete-tag) {
    margin: 6px !important;

}

.autocompleteParaphrasing :global(.MuiAutocomplete-inputRoot) {
    padding: 0px !important;
}

.autocompleteParaphrasing input {
    border: none !important;
    color: #000000DE !important;

}

.autocompleteParaphrasing :global(.MuiOutlinedInput-notchedOutline) {
    border: none !important;
}

.autocompleteParaphrasing :global(.MuiInputBase-input) {
    /* height: 100% !important; */
    padding: 0px 10px !important;
    font-family: inherit !important;
    font-weight: 400;
    font-size: 14px !important;
    min-height: 40px !important;
}

.autocompleteParaphrasing :global(.MuiOutlinedInput-root) {
    min-height: 34px !important;
    /* background: #F3F5F9; */
    background: white;
    /* font-size: 14px !important; */
}

.autocompleteParaphrasing :global(.MuiChip-root) {
    border-radius: 6px !important;
    background-color: white !important;
    border: 1px solid #E1CEE5 !important;
    font-size: 14px !important;
    font-weight: 400;
}

.autocompleteParaphrasing .MuiListSubheader-sticky {
    padding-left: 12px !important;
}

.disabledClass {
    opacity: 0.5;
    cursor: wait;
}

.selectComponent fieldset {
    border-color: unset !important;
    border-width: 0px !important;
}

.selectComponent :global(.MuiSelect-select) {
    padding-left: 0px !important;
}

// GLOBAL TABLE
.subheaderGlobalStyle {
    font-size: 13px;
    font-weight: 600;
    color: #565656;
}

// GLOBAL TABLE

.checkboxStyle {
    padding: 0px !important;

    [type='checkbox']:checked,
    [type='checkbox']:not(:checked) {
        left: 0 !important;
    }
}