@import "../../../utils/colorCode.scss";

.creditUsedContainer {
    border-radius: 10px;
    box-shadow: 0px 0px 2px 0px #6A097D;
    padding: 15px !important;
    margin: 0 15px !important;
}

.creditUsedContainerMobile {
    // padding: 16px 10px !important;
    padding: 10px 6px !important;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 0px #6A097D;
    margin: 0 15px !important;

}

.creditUsedStatusMobile {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.creditUsedStatusMobileContainer {
    margin: 6px 0px 15px !important;
}

.CreditUsedRail {
    background-color: #DBDBDB !important;
    border-radius: 5px !important;
}

.CreditUsedRail :global(.MuiLinearProgress-bar) {
    background-color: $primary !important;
    border-radius: 5px !important;
}

.creditUsedStatus {
    font-size: 15px;
    font-weight: 500;
    color: $black;
}

.creditUsedRailWrapper {
    margin: 7px 0 0px !important;
}

.creditUsedTextStyle {
    font-size: 15px;
    font-weight: 400;
    color: $black;
    margin-left: 5px !important;
}

.firstBlockWrapper {
    margin-bottom: 13px !important;
}