$primary : #6A097D;
$secondary : #F7E9FF;
$successGreen : #5dc729;
$white: #fff;
$grey: #7A7A7A;
// $black: #000;
$black: #4C4A4D;
$required: #C60000;
$grey: #7A7A7A;
$lightGrey: #B1B1B1;
$sideNavigationPanel: #FBF4FF;
$lightPurple: #FDFBFD;
$colorGuideInclusion: #4FD61F;
$colorGuideSynonym: #FFA500;
$colorGuideGrammar: #D11B1B;
$textColor: #1F384C;
$whatsAppColorTheme: #4AC958;