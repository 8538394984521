@import "../../../utils/colorCode.scss";

.rightBlockWrapper {
    width: 50%;
}

.dialogStyle {
    overflow: hidden;
    padding: 40px !important;
}

.initialDialogStyle {
    overflow: hidden;
    padding: 40px !important;
}

.rightBlock {
    background: linear-gradient(to right, #6A097D 0%, #6A097D 100%),
        linear-gradient(to right, #853495 0%, #853495 100%),
        linear-gradient(to right, #C887D5 0%, #C887D5 100%);
    height: 100vh
}

.leftBlockWrapper {
    width: 50% !important;
    padding: 0 50px;
    display: flex;
    justify-content: center;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

}


.leftBlock {
    // height: 100vh;
    // max-width: 323px;
    // max-width: 350px;
    max-width: 385px;
    flex-direction: row !important;
    padding: 20px 0px;
    // flex-wrap: nowrap !important;
}

// .italicText {
//     color: $white;
//     font-size: 32px;
//     font-weight: 600;
//     font-style: italic;
//     text-align: center;
//     max-width: 350px !important;

// }
.italicText {
    color: $white;
    font-size: 25px;
    max-width: 360px !important;
    font-weight: 600;
    font-style: italic;
    text-align: center;
}

.successSubAuthText {
    font-size: 15px;
    color: $black;
    font-weight: 600;
    text-align: center;
}

.successAuthText {
    font-size: 25px;
    color: $primary;
    font-weight: 600;
}

.successAuthNameText {
    font-size: 25px;
    color: $black;
    font-weight: 600;
}

.successAuthTextWrapper {
    margin: 30px 0 10px;
}

.verifyText {
    font-size: 26px;
    font-weight: 600;
    color: $primary;
    text-align: center;
    margin-bottom: 20px !important;
}

.verifySubText {
    font-size: 15px;
    font-weight: 600;
    color: $black;
    text-align: center;
    margin-bottom: 40px !important;
}

.writewizLogoWrapper {
    display: none;
}

.logoStyle {
    width: 200px;
    margin: 30px 0px 20px;
    height: auto;
}

.authLabelRegistration {
    font-size: 26px;
    font-weight: 600;
    color: $primary;
    // margin-bottom: 25px !important;
    margin-bottom: 4px !important;
    text-align: center;
}

@media screen and (max-width:1000px) {
    .leftBlock {
        padding-top: 0px !important;
    }

    .authLabelRegistration {
        display: none;
    }

    .rightBlockWrapper {
        display: none !important;
    }

    .leftBlockWrapper {
        width: 100% !important;
    }

    .writewizLogoWrapper {
        display: block;
    }
}

@media screen and (max-width:400px) {
    .leftBlockWrapper {
        width: 100% !important;
        padding: 0 20px !important
    }
}

@media screen and (max-width: 550px) {
    .leftBlockWrapper {
        padding: 0 30px !important;
    }
}

.termsLineItem {
    text-align: left;
    font-size: 12px !important;
    margin-left: 3px !important;
    margin-bottom: 10px !important;
}

.termsFont,
.privacyFont {
    color: $primary !important;
    font-weight: 600 !important;
    cursor: pointer;
    // text-transform: capitalize !important;
    // font-size: 12px !important;
    // padding: 5px !important;
}

.termsFontPopup {
    color: $primary !important;
    font-weight: 600 !important;
    cursor: pointer;
    text-transform: capitalize !important;
    font-size: 12px !important;
    padding: 0px !important;
    min-width: unset !important;
    padding-left: 3px !important;
}

.privacyFontPopup {
    color: $primary !important;
    font-weight: 600 !important;
    cursor: pointer;
    text-transform: capitalize !important;
    font-size: 12px !important;
    padding: 5px !important;
}

.recaptchaBlock {
    margin: 30px 0px 40px !important;
    transform: scale(1.3);
    // width: 100%;
}

.recaptchaBlockPopup {
    // margin: 30px 0px 40px !important;
    margin-top: 20px !important;
    transform: scale(1.357);
}

@media (max-width: 550px) {
    .recaptchaBlock {
        transform: scale(1.15);
    }

    .recaptchaBlockPopup {
        transform: scale(1.15);

    }
}

@media (max-width: 500px) {
    .recaptchaBlock {
        transform: scale(1.07);
        transform-origin: left;
    }

    .recaptchaBlockPopup {
        transform: scale(1.07);
        transform-origin: left;
    }
}

@media (max-width: 450px) {
    .recaptchaBlock {
        margin: 20px 0px 30px !important;
        transform: scale(0.9);
        transform-origin: left;
    }

    .recaptchaBlockPopup {
        // margin: 20px 0px 30px !important;
        // transform: scale(0.9);
        transform: translate(6px, 10px) !important;
        transform-origin: left;
    }

    .dialogStyle {
        padding: 40px 0px !important
    }

    .initialDialogStyle {
        padding: 40px 20px !important
    }

    .backBtnWrapper {
        padding-left: 15px;
    }
}

@media (max-width: 400px) {
    .recaptchaBlock {
        transform: scale(0.89);
        transform-origin: left;
        padding-left: 0px !important;
        margin: 10px 0px 20px !important;
    }

    .recaptchaBlockPopup {
        transform: scale(0.89);
        transform-origin: left;
        padding-left: 0px !important;
        // margin: 10px 0px 20px !important;
    }
}

.recaptchaBlockPopup:hover {
    background-color: transparent !important;
}

.recaptchaBlock:hover {
    background-color: transparent !important;
}

.mainRecaptchaBlock {
    display: flex;
    justify-content: center;
}

.continueWithButton {
    // display: flex !important;
    // justify-content: center !important;
    border: 1.125pt solid #CDCDCD !important;
    border-radius: 10px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 52px !important;
    min-height: 52px !important;
    max-height: 52px !important;
    width: 100% !important;
    text-transform: unset !important;
    margin: 0px 0px 10px !important;
    justify-content: center !important;
    color: black !important;
    padding: 0 !important;
    height: 56px !important;
}

.continueWithButton img {
    padding-left: 15px;
    flex: 0 0 auto;
}

.wzLogo {
    width: 180px;
}

@media (max-width: 550px) {
    .wzLogo {
        width: 150px;
    }
}

@media (max-width: 450px) {
    .loginUsingEmailContainer {
        width: 100% !important;
    }

    .emailInputContainer {
        width: 100%;
    }

    .verificationBlockWrapper {
        padding: 5px !important;
    }
}

.contBtnWrapper {
    margin-top: 30px !important;
}

.mainBlockStyle {
    display: flex;
    align-self: start;
    width: 100%;
}

.verificationBlockWrapper {
    width: 100%;
    margin: auto;
    overflow: hidden;
    padding: 0 30px
}