@import "../../../utils/colorCode.scss";

.updatePersonaButton {
    display: flex;
}

.personaName {
    font-size: 17px;
    font-weight: 600;
    height: fit-content;
}

.personaNameInMenu {
    color: $primary !important;
    font-size: 12px !important;
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    border-top: 1px solid #E9E9E9 !important;
    border-bottom: 1px solid #E9E9E9 !important;
}

.personaOccupation {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-left: 9px;
    border-left: 1px solid #C8C8C8;
    padding-right: 30px;
}

.personaOccupationButton {
    display: flex !important;
    align-items: center !important;
    text-transform: capitalize !important;
    color: black !important;
    padding: 0px 3px !important;
    text-align: start;
    min-width: 27px !important;
}

.personaNameButton {
    display: flex !important;
    align-items: center !important;
    text-transform: capitalize !important;
    color: $black !important;

    padding: 0px !important;
    margin-left: 0px !important;
    text-align: start;
}

.greyBackground {
    height: auto;
    max-width: 300px;
    border-radius: 50%;
    background-color: #FBF4FF;
    margin: 0px 0px 15px 0px !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}

.personaAvatarBackground {
    height: 300px !important;
    width: 300px !important;
    border-radius: 10px;
    object-fit: cover !important;
    border-radius: 50%;
    margin: 0px 0px 15px 0px !important;
    border: 1px solid #e9bdf2;
}

.personadetailsGrid{
    max-height: calc(100vh - 140px) !important; 
    overflow-y: scroll !important; 
    box-shadow: 0px 0px 2px rgba(0,0,0,0.25) !important; 
    border-radius: 10px !important;
}
.personadetailsGrid {
    &::-webkit-scrollbar-thumb {
        background-color: #D9D9D9 !important;
        outline: unset !important;
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        width: 5px !important;
    }
}

.personaAvatarStyle {
    width: 30px !important;
    height: 30px !important;
}

.personaAvatarItem {
    padding: 8px 0px 35px 0px;
    text-align: center;
}

.personaDefaultText {
    font-size: 14px;
    font-weight: 500;
    color: $primary;
}

.personaDefaultStyle {
    margin-right: 5px !important;
}

.fullItem {
    box-shadow: 0px 0px 2px 0px $primary;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
}

.fullItemBottom {
    box-shadow: 0px 0px 2px 0px $primary;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    margin-top: 15px !important;
}

.fullItemDetails {
    margin: 0px 0px 15px 0px !important;
    width: 100%;
}

.fullItemDetailsList {
    box-shadow: 0px 0px 2px 0px $primary;
    border-radius: 10px;
    padding: 7px 15px;
    color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chipsStyle {
    font-size: 12px;
    box-shadow: 0px 0px 1.5px 0px #898989;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9.5px 8px;
    border-radius: 4px;
    margin: 0px 9px 9px 0px !important;
}

.chipsStylePersona {
    font-size: 12px;
    box-shadow: 0px 0px 1.5px 0px #898989;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9.5px 8px;
    border-radius: 4px;
    margin: 0px 9px 15px 0px !important;
}

.chipsStylePersonaHighlight {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10.5px 19px;
    border-radius: 4px;
    margin: 0px 9px 15px 0px !important;
    color: $primary;
    border: 1px solid #6A097D;
    font-weight: 500;
}


.userData {
    padding: 5px 0px;
    height: 31px;
    font-size: 15px;
    font-weight: 400;
}

.userDataAnswer {
    padding: 5px 0px;
    font-size: 15px;
    font-weight: 500;
}

.fullItemDetailsListAnswer {
    color: #292929;
}

.fullWidth {
    width: 100%;
}

.fullDetailsContainer {
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
}

.personaBackgroundText {
    color: $primary;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 500;
    margin: 10px 0px !important;
}

.personaBackgroundDetailsText {
    color: $black;
    font-family: Open Sans;
    font-size: 15px;
    padding: 15px 0px;
}

.heightFitStyle {
    height: fit-content;
}

.goalsList {
    margin-bottom: 10px;
}

.personaBackgroundDetailsTextBullet {
    color: $black;
    font-family: Open Sans;
    font-size: 15px;
    padding: 15px 0px 15px 20px;
}

.personaBackgroundDetailsTextBulletBottom {
    color: $black;
    font-family: Open Sans;
    font-size: 15px;
    padding: 1px 0px 0px 20px;
}

.personaBackgroundContainer {
    padding: 15px;
    flex-direction: column !important;
}

.individualPersonaButton {
    background: #FBF4FF !important;
    color: $primary !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    padding: 6px 10px !important;
    border-radius: 5px !important;
    display: flex;
    justify-content: center;
    height: 32px;
    width: 115px;
}

.updatePersonaButtonPhone {
    display: none;
}

.updatePersonaMenuPhone {
    display: none;
}


.viewPersonaFullContainer {
    box-shadow: 0px 0px 3px 0px #00000040;
    padding: 24px;
    border-radius: 10px;
}

.personaViewChildWrapper {
    padding: 27px 40px;
    flex-wrap: nowrap !important;
}

.personaViewChildTitleTextStyle {
    font-size: 18px !important;
    font-weight: 600;
    color: $black;
    // width: 100%;
    // margin-bottom: 27px !important;
    display: flex;
    align-items: center;
}


.newPersonaTileStyle {
    padding: 15px !important;
    box-shadow: 0px 0px 4px 0px #D9D9D9;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    margin-left: 20px !important;
}

.newPersonaTileStyle:hover {
    box-shadow: 0px 2px 4px 0px #00000040;
}

.personaViewheaderContainer {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 17px !important;
}

.personaNameMobile {
    display: none;
}

.personaOccupationMobile {
    display: none;
}


.personaDropdown :global(.MuiInputBase-input) {
    color: $primary !important;
    font-size: 15px;
    font-weight: 500;
    padding: 0 !important;
}

.personaDropdown::before {
    border-bottom: 0px !important;
    background: unset !important;
}

.personaDropdown::after {
    border-bottom: 0px !important;
    background: unset !important;
}

.personaDropdown::before {
    border-bottom: 0px !important;
    background: unset !important;
}

.personaDropdown :global(.MuiSelect-select) {
    width: 100%;
    background-color: #FDFBFD !important;
    white-space: wrap;
}

.personaDropdown :global(.MuiSelect-select)::before {
    border-bottom: 0px !important;
}

.personaDropdown :global(::after) {
    border-bottom: 0px !important;
}

.personaDropdown :global(.MuiInputBase-root)::before {
    border-bottom: 0px !important;
}

.PersonaDescpTooltip {
    background-color: unset !important;
}

.PersonaDescpTooltip :global(.MuiTooltip-popper) {
    background: $white !important;
    padding: 0px !important;
    background-color: unset !important;
}

.personaMenuContainer {
    padding: 15px;
    max-width: 289px;
    background-color: $white;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    flex-wrap: nowrap !important;
}

.personaAvatarStyle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.leftBlock {
    padding-left: 10px;
}

.personaNameStyle {
    color: #2765FF;
    font-size: 12px;
    font-weight: 600;
}

.PersonaDisplayNameStyle {
    color: $grey;
    font-size: 11px;
    padding: 2px 0px 6px;
}

.personaSummaryStyle {
    color: $black;
    font-size: 11px;
    margin-top: 5px !important;
}

.personaDefaultNamesInMenu {
    color: $black;
    font-weight: 500;
}

.personaDefaultNamesInMenu .MuiSwitch-root {
    height: 33px !important;
}

.personaViewdropdownstyle {
    display: flex;
    align-items: center;
}

.personaViewNewPersonaBtnMainContainer {
    justify-content: end;
}

@media screen and (min-width:900px) and (max-width:1200px) {
    .personaViewHeader {
        flex-direction: column !important;
    }

    .personaOccupation {
        padding-left: 0px;
        border-left: 0px;
    }

    .updatePersonaButtonPhone {
        display: flex;
        align-items: center;
    }

    .updatePersonaMenuPhone {
        display: flex;
    }

    .updatePersonaMenuPhone :global(.MuiList-root) {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .updatePersonaButton {
        display: none;
    }

    .personaDefaultStyle {
        display: none;
    }

    .personaStyleMobile {
        display: none;
    }

    .individualPersonaButton {
        margin-left: 9px !important;
    }

    .personaName {
        display: none;
    }

    .personaNameMobile {
        display: flex !important;
        font-size: 17px;
        font-weight: 600;
        height: fit-content;
    }

    .personaNameButton {
        justify-content: start !important;
    }

    .personaOccupationMobile {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding-left: 0px;
        border-left: 0px;
        padding-right: 30px;
    }

    .personaOccupation {
        display: none;
    }
}

@media screen and (min-width:600px) and (max-width:900px) {
    .personaViewHeader {
        flex-direction: column !important;
    }

    .personaOccupationButton {
        padding: 0px !important;
        margin-left: 0px !important;
        height: 27px;
        min-width: 27px !important;
    }

    .personaNameButton {
        padding: 0px !important;
        margin-left: 0px !important;
    }

    .personaOccupation {
        padding-left: 0px;
        border-left: 0px;
    }

    .updatePersonaButtonPhone {
        display: flex;
        align-items: center;
    }

    .updatePersonaMenuPhone {
        display: flex;
    }

    .updatePersonaMenuPhone .MuiList-root {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .updatePersonaButton {
        display: none;
    }

    .personaDefaultStyle {
        display: none;
    }

    .personaName {
        display: none;
    }

    .personaNameMobile {
        display: flex !important;
        font-size: 15px;
        font-weight: 600;
        height: fit-content;
    }

    .personaNameButton {
        justify-content: start !important;
    }

    .personaOccupationMobile {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding-left: 0px;
        border-left: 0px;
        padding-right: 30px;
    }

    .personaOccupation {
        display: none;
    }

    .personaStyleMobile {
        display: none;
    }

    .individualPersonaButton {
        width: 90px;
        font-size: 14px !important;
    }
}

@media screen and (min-width:320px) and (max-width:600px) {
    .personaViewChildWrapper {
        padding: 18px 15px 15px 15px;
    }

    .updatePersonaButton {
        display: none;
    }

    .personaDefaultStyle {
        display: none;
    }

    .viewPersonaFullContainer {
        padding: 17px 15px;
    }

    .personaName {
        display: none;
    }

    .personaViewHeader {
        flex-direction: column !important;
    }

    .personaNameMobile {
        display: flex !important;
        font-size: 15px;
        font-weight: 600;
        height: fit-content;
    }

    .personaNameInMenu {
        color: $primary !important;
        font-size: 13px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .personaOccupationMobile {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding-left: 0px;
        border-left: 0px;
        padding-right: 30px;
    }

    .personaOccupation {
        display: none;
    }

    .updatePersonaButtonPhone {
        display: flex;
        align-items: center;
    }

    .updatePersonaMenuPhone {
        display: flex;
    }

    .updatePersonaMenuPhone .MuiList-root {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .individualPersonaButton {
        width: 77px;
        font-size: 14px !important;
    }

    .personaViewHeaderStyle {
        justify-content: space-between;
    }

    .personaStyleMobile {
        display: none;
    }

    .newPersonaTileStyle {
        padding: 9px 7px !important;
    }
}