@import "../../../utils/colorCode.scss";

.optionCard {
    border-radius: 10px;
    box-shadow: 0px 0px 2px 0px $grey;
    padding: 10px 15px;
    margin-bottom: 20px;

}

.optionCard:hover {
    box-shadow: 0px 0px 2px 0px $primary;
}

.upperBlock {
    margin-bottom: 12px !important;
}

.optionTextStyle {
    color: $grey;
    font-size: 12px;
    font-weight: 500;
}

.addToEditorTextStyle {
    color: #9831AD;
    font-size: 15px;
    font-weight: 400;
}

.copyTextStyle {
    color: $grey;
    font-size: 15px;
    font-weight: 400;
    margin: 0px 15px 0px 23px !important;
}

.deleteTextStyle {
    color: $grey;
    font-size: 15px;
    font-weight: 400;
}