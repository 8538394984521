.customizeLoaderGif {
    width: 30px;
    height: auto;
    object-fit: fill;
}

.prLoaderBufferText {
    color: #6A097D !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.prRecordingBtn {
    width: 174px !important;
    background-color: rgba(227, 54, 41, 0.1) !important;
    font-size: 15px !important;
    font-family: 500 !important;
    text-transform: capitalize !important;
    color: rgba(227, 54, 41, 1) !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.prLoaderItem1 {
    box-shadow: 0px 0px 2px 0px #6A097DB2 !important;
    padding: 15px !important;
    border-radius: 10px !important;
    background-color: #fff;
    z-index: 1;
    margin-top: 20px !important;
}

.prLoaderContainer1 {
    gap: 20px;
    align-items: center;
    justify-content: space-between;
}

.prLoaderItem2 {
    // background: linear-gradient(117deg, rgba(239, 215, 254, 0.30) 43.97%, rgba(104, 161, 230, 0.30) 71.41%) !important;
    // background: linear-gradient(117deg, #EFD7FE4D 43.97%, #68A1E64D 71.41%) !important;

    // For Dynamic code
    // background-image: url('../../Loader-bg.png');
    // background-color: white;
    // background-position: center;
    // background-size: cover;
    // padding: 20px;
    // margin: 20px 0px !important;
    // border-radius: 10px;
    // z-index: 1 !important;
}

.prDescTextItem {
    margin: 8px 0px !important;
    text-align: start;
}

.prDescText {
    font-size: 15px !important;
    font-weight: 500 !important;
    text-align: left;
}

.prLoaderHeadText {
    color: #6A097D !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.prBloggingBtn {
    // width: 180px !important;
    background-color: #6A097D !important;
    font-size: 15px !important;
    font-family: 500 !important;
    text-transform: capitalize !important;
    color: #fff !important;
    border-radius: 5px !important;
    box-shadow: none !important;
}

.prBloggingBtnItem {
    margin: 8px 0px !important;
}

.loaderGrid {
    width: 100%;
    max-width: 450px;
    position: "absolute"

}

@media screen and (min-width:1199px) and (max-width: 1295px) {
    .loaderGrid {
        width: 100%;
        max-width: 420px;
        position: "absolute"

    }
}

@media screen and (max-width:795px) {
    .loaderGrid {
        width: 100%;
        max-width: unset;
        padding: 0px 20px;
    }

}

// @media screen and (min-width:730px) and (max-width:1380px) {
//     .loaderGrid {
//         max-width: 380px;
//     }

//     .prLoaderContainer1 {
//         justify-content: center;
//     }
// }

// @media screen and (max-width:730px) {
//     .loaderGrid {
//         max-width: 300px;
//     }

//     .prLoaderContainer1 {
//         justify-content: center;
//     }
// }