.draggable {
  display: flex;
  align-items: baseline;
  gap: 0rem;
}

.category_name {
  margin: 0;
}

.heading_box {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  /* margin: 0.5rem 0rem 0.5rem 1rem; */
  margin: 8px 0px 8px 8px;
  align-items: center;
  width: 99.5%;
}

.subheading_box {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin: 0.5rem 0rem 0.5rem 1rem;
  align-items: center;
  width: 99%;
}

.brief_box {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin: 0.5rem 0rem 0.5rem 1rem;
  align-items: center;
  width: 98.9%;
}

.plus_icon_visibility {
  opacity: 0;
}

.plus_icon_visibility:hover {
  opacity: 1 !important;
}

.editable_area {
  padding: 0px !important;
  padding-right: 4px !important;
  width: 100% !important;
  font-weight: 600 !important;
}


/* .draggable:hover .plus_icon_visibility {
  opacity: 0.5 !important;
} */
.editableNodeText .MuiInputBase-root {
  padding: 5px 5px !important;
  font-weight: 500 !important;
}

.editableNodeText .MuiInputBase-root .MuiInputBase-input {
  padding-right: 5px !important;
}

@media screen and (max-width: 600px) and (min-width : 320px) {
  .editableNodeText .MuiInputBase-root {
    font-weight: 500 !important;
    font-size: 13px !important;
  }
}



@media screen and (max-width:899px) {
  .plus_icon_visibility {
    opacity: 1 !important;
  }
}

.heading-container {
  width: 96%;
}

.subheading-container {
  width: 100%;
}

.item_category,
.item_sub_category {
  box-shadow: 0px 0px 2px 0px #00000040;
  border-radius: 4px;
  /* padding: 11px; */
  padding: 8px;
  width: 100%;
  background: rgb(255, 255, 255);
}

.move_option .MuiPaper-elevation {
  box-shadow: none !important;
  /* border: 1px solid #CDAED3; */
  box-shadow: 0px 0px 2px 0px #6A097D !important;
}

.dnd_block_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section_type {
  color: #7A7A7A !important;
  font-size: 12px;
  align-items: center;
  white-space: nowrap;
}

[contenteditable] {
  outline: 0px solid transparent;
}

.icon_size {
  height: 18px;
  width: 18px;
  display: flex;
}

.section_type[placeholder]:empty:before {
  content: attr(placeholder);
  color: #555;
}

.editableNodeText input {
  padding: 3px 10px !important;
  font-weight: 600;
}

.editableNodeTextSummary input {
  padding: 3px 10px !important;
}

.editableNodeText fieldset {
  outline: none !important;
  border: none !important;
}

.editableNodeTextSummary fieldset {
  outline: none !important;
  border: none !important;
}

.contentBriefSection{
  display: flex;
  align-items: center;
}
.contentBriefDesktopIcon{
  width: 18px;
  height: 18px;
  margin-left: 8px;
  display: flex;
  align-items: center;
}
.hoverDeleteIcon{
  padding: 5px;
  border-radius: 5px; 
  display: flex;
}
.hoverDeleteIcon:hover{
  background-color: #F7E9FF; 
  box-shadow: 0px 0px 2px #6A097D;
}
.reloadDeleteMobile{
  display: none;
}
.contentBriefIcon{
  display: none;
}
.MuiInputBase-multiline{
  padding: 4px !important;
}

@media screen and (min-width:1000px) and (max-width:1557px) {
  .heading_box{
    width: 98.5%;
  }
}
@media screen and (min-width:1440px) and (max-width:1599px) {
  /* .heading_box{
    width: 99%;
  } */
  .brief_box{
    width: 97.3%;
  }
  .subheading-container{
    width: 95.5%;
  }
  .dnd_block_container{
    width: 96%;
  }
}
@media screen and (min-width:1000px) and (max-width:1439px) {
  .subheading-container{
    width: 91.5%;
  }
  .brief_box{
    width: 96%;
  }
}
@media screen and (min-width:601px) and (max-width:999px) {
  .heading_box{
    width: 97.5%;
  }
}
@media screen and (min-width:768px) and (max-width:999px) {
  .heading_box{
    width: 97.7%;
  }
  .brief_box{
    width: 96.8%;
  }
  .subheading-container{
    width: 92%;
  }
}
@media screen and (min-width:900px) and (max-width:999px) {
  .brief_box {
    width: 95%;
  }
}
@media screen and (min-width:601px) and (max-width:767px) {
  .brief_box{
    width: 95%;
  }
}
@media screen and (min-width:320px) and (max-width:600px) {
  .heading_box {
    width: 95%;
  }
  .dnd_block_container {
    align-items: start;
    flex-direction: column-reverse;
  }
  .editableNodeText .MuiInputBase-root {
    padding: 5px 5px !important;
    font-weight: 500 !important;
  } 
  .editableNodeText .MuiInputBase-root .MuiInputBase-input {
    padding-right: 0px !important;
  }
  .editableNodeTextSummary {
    padding: 3px 0px !important;
    font-size: 13px !important;
  }
  .brief_box {
    width: 92%;
  }
  .sectionHeadingVisibility{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 5px;
    width: 100%;
  }
  .deleteDesktopIcon{
    display: none;
  }
  .reloadDeleteMobile{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .section_type {
    width: 100%;
  }
  .MuiInputBase-multiline{
    padding: 0 !important;
  }
  .contentBriefDeleteSection{
    display: flex;
    width: 100% !important;
    padding: 2px 0px;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .contentBriefSection{
    gap: 8px;
  }
  .contentBriefIcon{
    display: flex;
  }
  .contentBriefDesktopIcon{
    display: none;
  }
  .subheading-container {
    width: 81%;
  }
}