@import "../../../utils/colorCode.scss";



.upperGrid {
    background: linear-gradient(to bottom, #FFFFFF 0%, #EFD7FE 100%);
    padding: 0px 30px 25px;
    border-radius: 0px 0px 12px 12px;
    width: 100%;
}

.meterWrapper {
    padding: 30px 0px 20px
}

.heading {
    font-size: 18px;
    font-weight: 600;
    color: $primary;
    margin-bottom: 10px !important;
    text-align: center;
}

.subHeading {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: $textColor !important;
}

.coloredSubheading {
    color: $primary !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.creditText {
    color: $black !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.textAlignCenter {
    text-align: center;
}

.signUpBtnWrapper {
    min-width: 161px;
    margin: 25px 0px 5px !important;
    font-size: 15px !important;
}

.secondGrid {
    padding: 9px 0px !important;
}

.bottomText {
    font-size: 12px !important;
    color: $black !important;
    font-weight: 500 !important;
}

.bottomColoredText {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: $primary !important;
    padding-left: 3px;
    cursor: pointer;
}

.confirmationText {
    color: $textColor;
    font-size: 18px;
    font-weight: 600;
    margin: 23px 0px 7px !important;
}

.subText {
    max-width: 284px !important;
    text-align: center !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-bottom: 20px !important;
}

.buttonWrapper {
    width: 100px;
    margin: 0px 10px !important;
}