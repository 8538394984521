.globalSearch :global(.MuiOutlinedInput-input) {
    padding: 11.5px 8px !important;
}

.globalSearch :global(.MuiOutlinedInput-notchedOutline) {
    border: none !important;
}

.globalSearch :global(.Mui-focused) {
    border: none !important;
}

.globalSearch {
    box-shadow: 0px 0px 2px 0px #5F6368 !important;
    border-radius: 5px !important;
}