.sectionHeaderButtonWrapper {
    padding-left: 50px !important;
}
.sectionOutlineGrid{
    padding: 28px 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px #6A097D;
    margin-top: 25px;
    flex-wrap: nowrap !important;
    min-height: 300px !important;
    max-height: calc(100vh - 198px);
}
.sectionOutlineItem{
    text-align: start !important;
    padding-left: 50px;
    padding-right: 27px;
}
.nestedListComponent{
    overflow-y: scroll !important;
}

@media screen and (min-width:320px) and (max-width:600px) {
    .sectionOutlineItem{
        padding-left: 5px;
        padding-right: 0px;
    }
    .sectonOutlineContainer{
        gap: 10px;
    }
    .sectionHeaderButtonWrapper {
        padding-left: 0px !important;
    }
    .sectionOutlineGrid{
        padding: 15px 10px;
        margin-top: 0px;
        max-height: calc(100vh - 165px) !important;
    }  
}
@media screen and (min-width:900px) and (max-width:952px) {
    .sectonOutlineContainer{
        flex-wrap: nowrap !important;
    } 
    .sectionOutlineItem{
        padding-left: 20px;
        padding-right: 0px;
    }
}