/* ------------------------------------------Pricing------------------------------------------ */

.pp-main-item {
    margin: 0px auto 0px !important;
}

.pp-title-item {
    margin: 20px auto 0px !important;
    text-align: center !important;
}

.pp-main-title {
    font-weight: 600 !important;
    color: #6A097D;
    font-size: 24px !important;
}

.pp-main-color-title {
    color: #4C4A4D;
    font-weight: 600 !important;
    font-size: 24px !important;
}

.pp-sub-title-item {
    text-align: center !important;
    margin: 10px 0px !important;
}

.pp-monthly-annually-item {
    margin: 32px auto !important;
}

.pp-monthly-annually-container {
    justify-content: center;
}

.mask-box {
    position: relative;
    background: rgba(247, 233, 255, 0.50) !important;
    color: black;
    padding: 7px 10px 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.mask-box .mask {
    width: 150px;
    height: 45px;
    background: #6A097D;
    position: absolute;
    transition: all 0.5s ease;
    color: white !important;
    border-radius: 5px;
}

.mask-box .MuiButton-root {
    width: 150px;
    transition: all 0.2s 0.1s ease;
    color: black;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

.mask-box .selected-monthly-button {
    width: 150px;
    background: #6A097D;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

.mask-box .selected-yearly-button {
    width: 150px;
    background: #6A097D;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

.pp-plans-panel-main-item {
    /* padding: 40px 40px !important; */
    margin: 0 auto !important;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    /* background-color: #fdfdfd !important; */
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important; */
}

.pp-global-plan-item {
    /* margin: 0 auto !important; */
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background-color: #fdfdfd !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.pp-global-plan-item:hover {
    border: 1px solid #6A097D;
}

/* .pp-premium-plan-main-item {
    background-color: #FBF4FF !important;
} */


.pp-premium-plan-main-item:hover {
    border: 1px solid #6A097D;
    border-radius: 15px !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}



.pp-global-plan-item:hover {
    border: 1px solid #6A097D;
}

.pp-plan-feature-item {
    margin: 0px auto 0px !important;
}

/* ------------------------------------------Pricing------------------------------------------ */


/* ------------------------------------------Pricing Card--------------------------------------------- */

.pp-plans-bg-main-item {
    background: #FDF9FF !important;
    margin: 100px 0px 0px !important;
    padding: 80px 0px !important;
}

.pp-plans-panel-main-container {
    display: grid;
    gap: 35px;
    justify-content: center;
    /* comment out due to dynamic plan */
    /* justify-content: space-between; */
}

/* .pp-plans-panel-sub-item {
    border-radius: 15px !important;
    min-width: 100% !important;
} */

.pp-plans-panel-sub-item {
    background-color: #fdfdfd !important;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important; */
    border-radius: 15px !important;
    min-height: 662px !important;
    min-width: 100% !important;
}

.pp-plans-panel-sub-item:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
}

.pp-global-plan-main-container-height {
    height: 100% !important;
}

.pp-recommended-label {
    position: absolute;
    background-color: #6A097D;
    color: white;
    padding: 4px 8px;
    border-radius: 0 0 0 8px;
    font-size: 12px;
    z-index: 1;
    position: relative;
}

.pp-plan-card-item1 {
    margin: 20px auto 0px !important;
    padding: 5px !important;
}

.pp-plan-card-item2 {
    margin: 0px auto !important;
    padding: 5px !important;
}

.pp-plan-subtitle-item {
    /* min-height: 8vh !important;
    max-height: 8vh !important; */
    margin-bottom: 20px !important;
}

.pp-plan-package-label-title {
    font-size: 20px !important;
    font-weight: 500 !important;
}

.pp-plan-package-label-sub-title {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #7A7A7A;
}

.pp-plan-package-amount-label-item {
    margin: 0px 0px 5px 0px !important;
}

.pp-plan-amount-discount-container {
    justify-content: space-between;
    align-items: center;
}

.pp-plan-package-amount-label {
    font-size: 25px !important;
    font-weight: 500 !important;
}

.pp-plan-package-amount-month-label {
    font-size: 16px !important;
    font-weight: 500 !important;
}

.pp-free-plan-label-item {
    text-align: end !important;
}

.pp-free-plan-label {
    color: #6A097D;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.pp-plan-amount-discount-item {
    text-align: end !important;
    border-left: 2px solid #1D944C !important;
}

.pp-plan-package-dis-amount-label-item {
    margin: 10px 0px 10px 0px !important;
}

.pp-plan-package-dis-amount-label {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #1D944C !important;
}

.pp-plan-btn-item {
    margin: 10px 0px !important;
}

.pp-plan-btn {
    border: 1px solid #6A097D !important;
    text-transform: capitalize !important;
    color: white !important;
    border-radius: 5px !important;
    color: #6A097D !important;
}

.pp-plan-btn-selected {
    background-color: #6A097D !important;
    text-transform: capitalize !important;
    color: white !important;
    border-radius: 5px !important;
}

.pp-plan-check-icon {
    min-width: 35px !important;
}

.pp-info-check-icon {
    min-width: 30px !important;
    justify-content: end !important;
}

.pp-plans-points {
    color: black;
    font-size: 14px;
    font-weight: 400;
}

.pp-plan-highlighted-text {
    color: black;
    font-size: 14px;
    font-weight: 600;
}

.pp-plans-list-points {
    padding-left: 0 !important;
    padding-right: 0 !important;
    align-items: center !important;
}





.pp-premium-plans-panel-sub-item {
    background-color: #FBF4FF !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    border-radius: 15px !important;
    min-height: 662px !important;
    min-width: 100% !important;
}


.pp-plans-panel-sub-item:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
}

.selected-item {
    box-shadow: 5px -5px 20px 0px rgba(16, 24, 40, 0.03), 0px 24px 48px -12px rgba(16, 24, 40, 0.18) !important;
    border-radius: 10px !important;
    background-color: #FBF4FF !important;
    /* background: rgba(247, 233, 255, 0.50) !important; */
}

.pp-plan-slider-item {
    margin: 5px auto 10px !important;
}

.pp-plan-credit-btn-item {
    /* margin: 0px 10px 10px 0px !important; */
}

.pp-plan-credit-btn-selected {
    background-color: #6A097D !important;
    color: white !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.pp-plan-credit-btn {
    background-color: #dadada !important;
    color: #6A097D !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border: none !important;
}

.pp-plan-premium-credit-btn {
    background-color: #F0F0F0 !important;
    color: #6A097D !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border: none !important;
}

.pp-plan-slider-input-item {
    margin: 5px auto !important;
}

.pp-plan-slider-input-container {
    align-items: baseline;
    justify-content: start;
    /* margin-top: -33px; */
}

.pp-plan-slider-input {
    max-width: 23px !important;
}

.pp-plan-slider .MuiSlider-mark {
    height: 0px !important;
}

.pp-plan-head-label-main-item1 {
    margin: 0px auto !important;
    padding: 0px 5px 5px !important;
}

.pp-plan-RecommendedLabel {
    display: flex !important;
    justify-content: end;
    min-height: 30px !important;
    max-height: 30px !important;
}

.pp-plan-RecommendedLabel-img {
    margin-right: -15px !important;

}

.pp-plan-head-label-item {
    min-height: 30px !important;
    max-height: 30px !important;
    padding-left: 25px !important;
    margin-top: 20px !important;
}

.pp-plan-head-label-container {
    min-height: 30px !important;
    max-height: 30px !important;
}

.pp-plan-head-label-sub-item {
    min-height: 30px !important;
    max-height: 30px !important;
}

/* ------------------------------------------Pricing Card--------------------------------------------- */



/* ------------------------------------------Plans Features------------------------------------------ */

.pp-compare-plans-main-container {
    justify-content: space-between;
    min-height: 100px;
}

.pp-cp-feature-grid-title {
    font-size: 25px !important;
    font-weight: 600 !important;
    color: #6A097D !important;
}

.pp-cp-feature-grid-sub-title {
    color: #717171;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.pp-cp-title-item {
    text-align: center !important;
    margin: 20px 0px 5px !important;
}

.pp-cp-outlined-btn {
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 5px !important;
    border: 1px solid #6A097D !important;
    color: #6A097D !important;
    width: 80% !important;
}

.pp-compare-plans-content-white-sub-container {
    justify-content: space-between;
    align-items: center;
}

.pp-cpc-gb-help-icon {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #9ba5b5 !important;
}

.pp-cpc-gb-feature-text {
    color: #475467 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
}

.pp-cpc-gb-feature-item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    height: 100px;
}

.pp-cpc-gb-check-icon {
    margin-bottom: -14px;
}

.pp-cp-feature-grid-title-item {
    margin-top: 15px !important;
}

.pp-plan-right-border-item {
    border-right: 1px solid #ecebeb !important;
}

.pp-plan-feature-container1 {
    justify-content: space-between;
}

.pp-plan-feature-container2 {
    justify-content: space-between;
    min-height: 100px;
    height: 100px;
}

.pp-plans-table-item1 {
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #FFF;
    box-shadow: 0px 2px 2px 0px rgba(230, 228, 228, 0.75);
}

.pp-plans-table-item2 {
    background-color: white !important;
    background: #FFF !important;
    min-height: 60px;
    box-shadow: 0px 10px 10px -10px rgba(230, 228, 228, 0.75)
}

.pp-plans-table-item2-last-row {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.pp-cpc-gb-left-head-item {
    display: flex !important;
    align-items: center !important;
}

.pp-cpc-gb-left-head-text {
    font-size: 15px !important;
    color: #404754 !important;
    font-weight: 600 !important;
}

.pp-cpc-gb-left-sub-head-text {
    font-size: 12px !important;
    color: #404754 !important;
    font-weight: 500 !important;
}

.pp-plans-table-container1 {
    justify-content: space-between;
    align-items: center;
}

.pp-plans-table-container2 {
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
    height: 100px;
}

.pp-plan-subtitle-text {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #7A7A7A !important;
}

.pp-plan-feature-btn-item {
    margin: 30px 0px !important;
    text-align: center !important;
}

.pp-plan-feature-subtitle-item {
    margin: 5px 0px !important;
}

.pp-cp-plan-names {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: black !important;
}

.pp-free-plan-sub-item1 {
    padding: 0px 30px !important;
}

.pp-starter-plan-sub-item1 {
    padding: 0px 30px !important;
}

.pp-premium-plan-sub-item1 {
    padding: 0px 30px !important;
}

.pp-plan-dropdown {
    border-radius: 5px !important;
    background: #FBF4FF !important;
}

.pp-plan-dropdown .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

/* compare plans */

@media only screen and (min-width: 320px) and (max-width: 899px) {

    .pp-compare-plans-main-item {
        padding: 5px !important;
    }
}

@media only screen and (min-width: 725px) and (max-width: 766px) {
    .pp-cp-outlined-btn {
        font-size: 14px !important;
    }
}

@media only screen and (min-width: 641px) and (max-width: 725px) {
    .pp-cp-outlined-btn {
        padding: 10px 0px !important;
        font-size: 14px !important;
    }
}


@media only screen and (min-width: 575px) and (max-width: 641px) {

    .pp-cp-outlined-btn {
        text-transform: capitalize !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        border-radius: 8px !important;
        border: 1px solid #6A097D !important;
        color: #6A097D !important;
        padding: 10px 0px !important;
    }

}

@media only screen and (min-width: 457px) and (max-width: 575px) {

    .pp-cp-outlined-btn {
        font-size: 11px !important;
        font-weight: 500 !important;
        padding: 10px 0px !important;
    }
}


@media only screen and (min-width: 500px) and (max-width: 599px) {

    .pp-cpc-gb-left-head-text {
        font-size: 12px !important;
        color: #404754 !important;
        font-weight: 400 !important;
    }

    .pp-cpc-gb-feature-text {
        color: #475467 !important;
        font-size: 12px !important;
        font-weight: 400 !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {

    .pp-cpc-gb-left-head-text {
        font-size: 11px !important;
        color: #404754 !important;
        font-weight: 400 !important;
    }

    .pp-cpc-gb-feature-text {
        color: #475467 !important;
        font-size: 11px !important;
        font-weight: 400 !important;
    }

}


/* @media only screen and (min-width: 320px) and (max-width: 1535px) {

    .pp-global-plan-item {
        margin: 0px auto 20px !important;
    }

} */

@media only screen and (min-width: 320px) and (max-width: 1199px) {

    .pp-plans-panel-sub-item {
        margin: 0px 0px 30px !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 899px) {

    .pp-plans-panel-main-container {
        justify-content: center;
    }
}


@media only screen and (min-width: 320px) and (max-width: 599px) {

    .pp-main-title {
        font-size: 30px !important;
        font-weight: 600 !important;
    }

    .pp-main-color-title {
        color: #6A097D;
        font-size: 30px !important;
        font-weight: 600 !important;
    }

    .pp-title-item {
        padding: 0px 10px !important;
    }

    .pp-plans-panel-main-item {
        padding: 0px !important;
    }

    .pp-plans-panel-main-item {
        margin: 0 !important;
    }

    .mask-box {
        padding: 7px 0px 5px;
    }

}

@media only screen and (min-width: 600px) and (max-width: 899px) {

    .pp-plan-feature-container2 {
        min-height: 150px;
        height: 150px;
    }

    .pp-plans-table-container2 {
        min-height: 150px;
        height: 150px;
    }

    .pp-cpc-gb-feature-item {
        min-height: 150px;
        height: 150px;
    }

}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {

    .pp-plans-panel-main-item {
        padding: 15px !important;
    }

}

@media only screen and (min-width: 900px) and (max-width: 1200px) {

    .pp-plans-panel-main-item {
        padding: 0px !important;
    }

}

/* @media only screen and (min-width: 320px) and (max-width: 599px) {

} */





/* ------------------------------------------Plans Features------------------------------------------ */