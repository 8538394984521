@import "../../../utils/colorCode.scss";

.mainBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    background: $white;
    padding: 15px;
    font-size: 14px !important;
    color: $black !important;
    font-weight: 500 !important;
    border-radius: 5px;
    align-items: center;
}

.mainBlockMobile {
    display: flex;
    justify-content: center;
    // margin-bottom: 15px;
    // background: $white;
    padding: 17px 10px;
    padding-top: 0px !important;
    font-size: 14px !important;
    color: $black !important;
    font-weight: 500 !important;
    border-radius: 5px;
    align-items: center;
    cursor: pointer;
}

.nameStyle {
    font-size: 14px !important;
    color: $black !important;
    font-weight: 500 !important;
    margin-left: 8px !important;
}

.menuLogout {
    color: $primary !important;
    margin: 2px 10px !important;
    border-radius: 7px !important;
    background: white;
    display: flex !important;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: unset !important;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    min-height: 48px;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    box-sizing: border-box !important;
    white-space: nowrap !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.menuLogout :global(.MuiListItemIcon-root) {
    color: $primary;
}

.menuLogout:hover {
    background: #EFD7FE !important;
    color: $primary;

}

.menuStyleSidePanel {
    box-shadow: 0px 0px 4px 0px #6A097D4D;
}

@media screen and (max-height:745px) {

    .mainBlock {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        background: $white;
        padding: 8px 15px !important;
        font-size: 14px !important;
        color: $black !important;
        font-weight: 500 !important;
        border-radius: 5px;
        align-items: center;
    }

}