:root {
    --formWiz-border: 1px solid #b00;
    --formWiz-outline: none;
    --formWiz-box-shadow: none;
    --formWiz-font-weight: none;
    --formWiz-letter-spacing: none;
    --formWiz-line-height: none;
    --formWiz-line-height: #fff;
    --formWiz-widget_theme_color: #000;
   
}

.mainContainerTestForm {
    /* height: 100vh; */
    /* overflow-x: hidden !important; */
}

.formWiz-fullform .Mui-checked {
    color: var(--formWiz-widget_theme_color) !important;
}

.formWiz-tooltipLabel {
    font-size: inherit !important;
    color: var(--formWiz-widget_theme_color) !important;
}

.circle {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 8px;
    border: 1px solid var(--formWiz-widget_theme_color) !important;
    border-radius: 50%;
    width: 30px;
    float: right;
}

.Loadercircle {
    cursor: pointer;
    /* display: flex;
    align-items: center;
    margin-left: 8px; */
    float: right;
}

.flashLoader {
    position: relative;
    /* font-weight: bold;
      font-size: 35px;
      display: inline-block;
      width: 55px;
      height: 55px;
      text-align: center;
      border-radius: 50%;
      cursor: pointer;
      margin: 0px 15px;
      color: #fff; */
}

.flashLoader::before {
    position: absolute;
    content: '';
    width: 29px;
    height: 27px;
    /* z-index: -1; */
    border-radius: 50%;
    top: 47%;
    left: 49%;
    transform: translate(-50%, -50%);
    animation: rotate 20s linear infinite;
}

.flashIcon::before {
    border: 3px dashed #6a097d;
}

@keyframes rotate {
    from {
        transform: translate(-50%, -50%) rotate(360deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

.-lS-461 {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.--Ef-462 {
    display: block;
    position: absolute;
    top: -100%;
    left: -100%;
    width: 300%;
    height: 300%;
    background: conic-gradient(from 180deg at 50% 50%, rgb(166, 231, 179), rgb(205, 146, 253), rgb(159, 235, 237), rgb(166, 231, 179));
    animation: 1s linear 0s infinite normal none running css-98;
    transform-origin: 50% 50%;
}

.--sg-668 {
    background: linear-gradient(rgb(240, 249, 250), rgb(240, 249, 250), rgb(245, 245, 245));
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 3px;
}

@keyframes css-98 {
    from {
        transform: translate(-50%, -50%) rotate(360deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

.formWiz-input-field input{
    color: #000000DE !important;
}
.formWiz-input-field {
    /* border: 1px solid #ced7e0 !important; 
    border-radius: 5px !important;  */
    box-shadow: rgb(0 0 0 / 60%) 0px 0px 2px 0px;
    border: none !important;
    background-color: white !important;
    outline: none !important;
    /* font-inherit !important/
    font-family:inherit !important;
    /* font-size: 13px !important; */
    /* padding: 5px !important; */
    width: 100% !important;
    border-color: #cccccc !important;
    /* border-style: groove !important; */
    font-weight: 500;
    /* margin: 5px 0px 0px 0px !important; */
    letter-spacing: 0.13px !important;
    /* line-height: 20px !important; */
    border-radius: 4px;
}

.formWiz-smallWidthParent {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    height: 100vh;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;

}

.FormWith-backgroundImage {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    width: 100%;
}

.formWiz-fullWidth {
    width: 100%;
    overflow: unset !important;
}

.formWiz-smallWidth {
    width: 50%;
    margin: 0 auto !important;
}

.mainContainerTest {
    /* width: 50% !important; */
    margin: 0 auto;
    background-color: transparent;
}

.mainContainerTestForm {
    background: white;
    opacity: 0.96;
    /* padding: 20px; */
    border-radius: 0 0 5px 5px;
    /* max-height: 77vh; */
    /* overflow: auto; */
    height: 100%;
}

.formWiz-fullformChildDiv {
    height: 100% !important;
}

.mainContainerTestFormwithPadding {
    background: white;
    opacity: 0.96;
    /* padding: 20px; */
    height: 100%;
    border-radius: 0 0 5px 5px;
    max-height: 77vh;
    overflow: auto;
}

.formWiz-input-field .select__control {
    /* border-color: #cccccc !important; */
    /* border-style: groove !important; */
    min-height:50px !important


}

.formWiz-input-field.input_class_widget_preview {
    width: 100%;
    padding: 0px !important;
    /* margin: 5px 0px 0px 0px !important; */
}

.formWiz-input-field .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.formWiz-input-field .MuiInputBase-input {
    /* height: 100% !important; */
    padding: 0px 10px !important;
    font-family: inherit !important;
    font-weight: 500;
    min-height: 50px !important;

}

.formWiz-input-field .MuiOutlinedInput-root {
    min-height: 34px !important;
    /* background: #F3F5F9; */
    background: white;
    /* font-size: 14px !important; */
}

.formWiz-input-field .select__control {
    outline: none !important;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    /* padding-left: 9px; */
    padding-left: 4px;

}
.formWiz-input-field .select__placeholder {
    /* color: #84878b;
     */
     color: #989fa5;
    font-weight: 400;
    opacity:1;

}
.MuiAutocomplete-input::placeholder{
    color: #84878b;
    font-weight: 400;
    opacity:1;
}
/* .MuiInputBase-input{
    border:1px solid #ced7e0 !important
} */
.formWiz-input-field .select__multi-value {
    background-color: white !important;
    border: 1px solid #c4c4c4 !important;
    padding: 3px !important;
    border-radius: 6px !important;
    margin: 3px !important;
    /* font-size: 13px; */
}
.select__multi-value .MuiSvgIcon-root {
    /* cross icon of select */
    font-size: 15px; 
}
.formWiz-input-field .select__multi-value__label {
    font-size: 14px !important;
    color: #000000DE !important
}

.formWiz-input-field .select__multi-value__remove {
    cursor: pointer;
    font-size: 20px;
    line-height: 20px;
    color: #000000DE !important
    /* color: #010101;
     */
}

.formWiz-input-field .select__multi-value__remove:hover {
    color: #010101;
}

.select__multi-value__remove:hover {
    background-color: inherit !important;
    color: inherit !important;
}

.formWiz-input-field .select__value-container {
    padding: 2px !important;
}

.widget_preview_form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 20px; */
}


select,
.MuiTextarea-root,
.MuiInputBase-input,
.MuiChip-label,
.MuiInputBase-root,
.MuiFormControl-root,
.MuiChip-root {
    font-family: inherit !important;
    font-size: inherit !important;
}

.MuiFormLabel-root {
    font-family: inherit !important;
    text-transform: inherit !important;
}

.react-tel-input .form-control {
    width: 100% !important;
    /* background: #F3F5F9 !important; */
    background: white !important;
    border: none !important;
}

.react-tel-input .flag-dropdown {
    background: white;
    border: none;
}

.chips_created {
    height: 24px;
    border: 1px solid grey;
    background: white;
    padding: 0px 8px;
    margin: 0px 4px !important;
    border-radius: 15px;
    display: flex;
}

.stack_inside_chip {
    /* border: 1px solid darkgrey !important; */
    border: none !important;
    box-shadow: 0px 0px 2.5px 0px #6A097D;
    border-radius: 5px !important;
}

.stack_inside_chip .MuiChip-label {
    color: #6A097D !important;
}

.stack_inside_chip.AddAll {
    background-color: #F7E9FF !important;
}

.stack_inside_chip:hover {
    background-color: #F7E9FF !important;
}

/* ====== multiple chips */

.multiple_chips__autocomplete .MuiChip-deleteIcon {
    color: #010101 !important;
}

.multiple_chips__autocomplete .MuiFormControl-root {
    background: #ffffff;
    border: none !important;
    border-radius: 4px;
    min-height:50px

}

.multiple_chips__autocomplete .MuiAutocomplete-tag {
    /* margin: 6px 1px 6px 6px !important; */
    margin: 6px !important;
}

.formWiz-input-field .MuiChip-filled {
    color: #010101 !important;
    font-family: inherit !important;
    font-weight: 500;
}

.multiple_chips_item {
    width: 100%;
}

.multiple_chips__autocomplete {
    width: 100%;
}

.multiple_chips__autocomplete .MuiAutocomplete-inputRoot {
    padding: 0px !important;
}

.multiple_chips__autocomplete input {
    border: none !important;
}

.formWiz-input-field .MuiChip-root,
.formWiz-input-field .select__multi-value {
    border-radius: 6px !important;
    background-color: white !important;
    border: 1px solid var(--formWiz-widget_theme_color) !important;

}
.avatar_file_upload {
    position: relative;
}
.close-btn {
    position: absolute;
    top: -1px;
    left: 32px;
    height: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    transition: all .3s;
}
/* .stack_inside_chip  {
    margin: 100px auto;
    width: 350px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .stack_inside_chip ::before {
    content: "";
    position: absolute;
    width: 345px;
    height: 45px;
    z-index: 1;
    background-color: #eee;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
  }
  
  .stack_inside_chip::after {
    content: "";
    width: 400px;
    height: 400px;
    position: absolute;
    border-radius: 50px;
    background: conic-gradient(
      #3a7cec 0%12.5%,
      #2ca24c 12.5%25%,
      #f1b500 25%37.5%,
      #e33e2b 37.5%50%,
      #3a7cec 50%62.5%,
      #2ca24c 62.5%75%,
      #f1b500 75%87.5%,
      #e33e2b 87.5%100%
    );
    animation: border-animation 5s linear infinite;
    -webkit-animation: border-animation 5s linear infinite;
  }
  
  @keyframes border-animation {
    to {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
    }
  } */

/* .multiple_chips__autocomplete .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 0px !important;
}
.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root{
    height: 34px;
}
 .css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-tag{
    margin: 0px !important;
}
 .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
    padding: 0px !important;
}
 .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root{
    height: 34px;
    padding: 0px 10px !important;
}
 .css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-tag{
    height: 25px;
    margin: 2px !important;
} */
/* ============= */
.show_error_in_form {
    color: red !important;
    /* font-size: 14px !important; */
}

.input_label {
    color: #4C4A4D !important;
    /* font-size: 18px !important; */
    margin: 6px 0px 6px 0px !important;
    font-weight: 600 !important;
    /* font-size: 15px !important; */
}

.label_class_widget_preview {
    width: 100%;
    color: #4C4A4D !important;
    /* font-size: 15px !important; */
    /* margin-top: 18px !important; */
    font-weight: bold !important;
    white-space: pre-wrap !important;
    display: block !important;
}

/* .long_text_input{
    width: 100%;
    padding: 0px !important;
    margin: 5px 0px 0px 0px !important;
} */
.long_text_input .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

/* .JoyTextarea-root:before {
    outline: none !important;
    box-shadow:#e1d9d9 !important;
} */
.MuiTextarea-root {
    --Textarea-focusedHighlight: transparent !important;
    /* padding: 10px 10px !important; */
    padding: 10px 0px 10px 10px !important;
}

.long_text_input textarea {
    /* font-size:inherit !important; */
    resize: vertical !important;
}

/* .long_text_input .MuiOutlinedInput-root{
    background: #F3F5F9; 
    background: white;
    font-size: 14px !important; 
} */
.file_preview_input {
    margin: 5px 0px 0px 0px !important;
    width: 100%;
}

.radio_option_label_widget_preview {
    /* color: black; */
    margin: 0px !important;
    /* font-size: 14px; */
    display: flex;
}

.radio_option_label_widget_preview .MuiButtonBase-root {
    padding: 0px 5px 0px 0px !important;
}

.radio_option_label_widget_preview .MuiTypography-root {
    /* font-size: 14px; */
}

.radio_option_label_widget_preview .MuiSvgIcon-root {
    width: 20px !important;
    height: 20px !important;
}

.radio_option_input_widget_preview {
    margin: 3px 8px 3px 4px !important;
}

.radio_option_label {
    /* font-size: 15px; */
    margin-right: 20px !important;
}

.radiogroupColumn_class {
    flex-direction: column !important;
}

.radiogroupColumn_class label {
    padding-bottom: 7px !important;
}

.radiogroupRow_class {
    flex-direction: row !important;
}

.dropdown_label_widget_preview {
    width: 100%;
    padding: 0px !important;
    /* background: #F3F5F9; */
    background: white;
    color: currentColor;
    margin: 5px 0px 0px 0px !important;
    height: 34px;
    border: none;
    border-radius: 4px;
}

.dropdown_label_widget_preview .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.dropdown_label_widget_preview .MuiInputBase-input {
    height: 100% !important;
    padding: 0px 10px !important;
    display: flex;
    align-items: center;
    /* font-size: 14px !important; */
}

.dropdown_option_placeholder {
    /* font-size: 14px !important; */
    color: #BABABA !important;
}

.dropdown_options {
    /* font-size: 14px !important; */
}

.date_picker_class_preview {
    background: #F3F5F9;
    width: 99.9%;
    margin: 5px 0px 0px 0px !important;
    border-radius: 4px;
}

.date_picker_class_preview .MuiOutlinedInput-notchedOutline {
    border: none;
    width: 100%;
}

.date_picker_class_preview .MuiInputBase-root {
    height: 34px;
    padding-right: 4px !important;
    background: white;
}

.date_picker_class_preview .MuiInputBase-input {
    padding: 0px 0px 0px 10px !important;
    /* font-size: 14px; */
}

.date_picker_container_class_preview {
    width: 100%;
}

.date_picker_item_class_preview>.css-1xhypcz-MuiStack-root {
    overflow: visible !important;
    padding-top: 0px !important;
}

.option_margin_class {
    margin: 5px 0px 0px 0px !important;
}

.option_space_class {
    margin-right: 20px !important;
}

.check {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: lightgray;
    border-radius: 50%;
}

.show_file_data_container {
    padding: 0px !important;
    margin: 5px 0px 18px 0px !important;
    background: #F3F5F9;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 4px;
}

.reset_all_fields_input {
    /* font-size: 15px; */
    width: 100%;
    display: flex;
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
}

/* formpreviewold css */
.widget_preview_form_save_button {
    width: 160px;
    height: 38px;
    /* background: #2765FF !important; */
    background: #D9A22E !important;
    color: white !important;
    /* font-size: 18px !important; */
    text-transform: none !important;
}

.widget_preview_form_save_item {
    display: flex;
    justify-content: left;
    padding: 0 !important;
    /* padding: 20px 0px; */
}

/* multiselect class */
.multiselect_class {
    padding: 10px 0px 0px 0px;
    width: 100%;
}

.multiselect_class .css-1o500h {
    background-color: #FFFFFF;
    width: 100%;
    height: 34px;
    padding: 0px !important;
    border-radius: 4px !important;
    box-shadow: none !important;
}

.multiselect_class .css-1dj9hp6 {
    background-color: #FFFFFF;
    z-index: 9999;
}

.multiselect_class .css-autkim {
    display: flex;
    padding-right: 7px;
}

.multiselect_class .css-imhb7i {
    padding-left: 10px;
}

.multiselect_class .css-1nq0kav {
    background-color: #FFFFFF;
    z-index: 9999;
    width: 99% !important;
}

.multiselect_class .css-1dkeni9 {
    background: #e8f7f6;
    padding: 1px;
    border-radius: 4px;
    margin: 4px;
}

/* --------- */
.helper_text_input {
    font-size: 10px !important;
    padding-left: 5px !important;
}

.css-671 {
    /* display: inline-flex; */
    /* flex-flow: row nowrap; */
    /* align-items: center; */
    /* justify-content: center; */
    border: 0.125rem solid transparent;
    /* vertical-align: middle; */
    /* margin: 0px; */
    cursor: pointer;
    font-family: inherit !important;
    text-decoration-line: none;
    outline-style: none;
    width: -moz-fit-content;
    /* width: fit-content; */
    color: white;
    /* background: #D9A22E; */
    border-radius: 0.25rem;
    padding: 0.625rem 1.25rem;
    /* min-width: 7rem; */
    /* min-height: 2.5rem; */
    /* line-height: 1.375rem; */
    font-weight: 600;
    /* font-size: 16px; */
    /* display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border: 0.125rem solid transparent;
    vertical-align: middle;
    margin: 0px;
    cursor: pointer;
    text-decoration-line: none;
    outline-style: none;
    width: fit-content;
    font-family:inherit !important;
    color: white;
    background: #D9A22E;
    border-radius: 0.25rem;
    padding: 0.625rem 1.25rem;
    min-width: 6rem;
    min-height: 2.5rem;
    line-height: 1.375rem;
    font-weight: 600;
    font-size: 15px; */
}

.widget_preview_text {
    font-weight: 545;
    /* font-size: 20px; */
    color: #2765FF;
    display: flex;
    justify-content: center;
    margin: 20px 10px 0px 10px !important;
}

.db_widget_preview_full_container {
    /* background: #F3F5F9; */
    background: white;
    height: 100%;
    flex-direction: column !important;
}

.company_logo_ {
    display: flex;
    /* padding: 22px 0px 0px 0px !important; */
}

.company_logo_image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 86px;
    object-fit: contain;
    padding: 10px 10px;
}

.company_logo_text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 157px;
    height: 39px;
    color: #8A8A8A;
    background: #F3F5F9;
    border-radius: 30px;
    /* font-size: 17px; */
    font-weight: 500;
}

.preview_switch {
    position: absolute;
}

.widget_preview_top_text_item {
    display: flex;
}

.widget_preview_form_view_item {
    background: white;
    margin: 20px !important;
    /* border-radius: 20px 0px; */
    overflow-y: auto;
    /* height: 75vh; */
}

.widget_title_text {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: rgb(0, 0, 0);
    padding: 5px 0 5px 0px;
    width: 100%;
}

.widget_subtitle_text {
    display: flex;
    justify-content: left;
    font-weight: 500;
    font-size: 14px;
    color: rgb(0, 0, 0);
    padding: 0px 0 7px 1px;
    width: 100%;
}

.widget_preview_middle_text {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    min-height: 53vh;
    align-items: center;
    font-weight: 500;
    padding: 0px 25px 25px 25px;
    /* font-size: 17px; */
    line-height: 20px;
    color: #8A8A8A;
    height: 500px;
}

.widget_preview_middle_form {
    display: flex;
    flex-direction: column !important;
    justify-content: start;
    min-height: 53vh;
    align-items: center;
    font-weight: 500;
    /* font-size: 17px; */
    line-height: 20px;
    color: #8A8A8A;
}

.new_fields_btn_widget_preview {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 160px;
    left: 640px;
    top: 503px;
    background: #2765FF;
    border-radius: 5px;
    color: white;
    margin: 10px !important;
}

.widget_logo_widget_preview_item_full {
    justify-content: flex-end;
    display: flex;
    margin-bottom: 20px !important;
    width: 100%;
}

.widget_logo_widget_preview_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 15px;
    width: 217px !important;
    background: linear-gradient(96.43deg, #AA2EFF 0%, #4E61FF 100%);
    border-radius: 10px 0px 0px 10px;
    color: white;
    /* font-size: 18px; */
}

.widget_logo_class_widget_preview {
    width: 26px;
    height: 26px;
    left: 15px;
    top: 8px;
    background: #FFFFFF;
    display: flex;
    justify-content: cenn;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 16px !important;
    object-fit: contain;
}

.widget_preview_header_container {
    background: #D9A22E;
    border-radius: 18px 18px 0px 0px;
}

/* @media only screen and (min-width: 320px) and (max-width: 480px){
   
    .widget_preview_header_container{
        margin-left: 0px !important;
    }
    .widget_logo_widget_preview_item_full{
    }
    .widget_preview_header_container{
        margin-left: 0px !important;
    }
    .widget_logo_widget_preview_item_full{
    }
}
@media only screen and (max-width: 480px) {
   
}
@media only screen and (min-width: 481px ) and (max-width: 768px){
   
}
@media only screen and (min-width: 769px ) and (max-width: 1544px){
    
} */

.np-slide-container {
    width: 300px;
    margin: 0 auto;
}

.np-img-container {
    max-width: 600px;
    margin: 0 auto;
}

.np-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 20px;
    background: #131313;
    border-radius: 20px;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    margin: 10px 0;
}

.np-slider:hover {
    opacity: 1;
}

.np-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 35px;
    height: 20px;
    background: #acacac;
    border-radius: 20px;
    cursor: pointer;
}

.np-slider::-moz-range-thumb {
    width: 35px;
    height: 20px;
    background: #acacac;
    border-radius: 20px;
    cursor: pointer;
}

.loader {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 10rem;
    border: 3px solid white;
    background: linear-gradient(#eb31b0, #e4c352, #7df8d6);
    box-shadow: 0px 0px 100px -50px black;
    animation: animate 1s linear infinite;
}

.loader::before {
    position: absolute;
    content: "";
    background: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    border-radius: 10rem;
    border: 3px solid white;
    box-shadow: inset 0px 0px 100px -70px #111;
}

@keyframes animate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


/* SPIN */
.circle .border {
    /* content: ''; */
    background: transparent;
    border-radius: 50%;
    border: 2px dashed #000;
    -webkit-animation-name: Rotate;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: Rotate;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: Rotate;
    -ms-animation-duration: 2s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
}

@keyframes Rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes Rotate {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes Rotate {
    from {
        -ms-transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
    }
}

@media screen and (max-width: 992px) {
    .formWiz-smallWidth {
        width: 100% !important;
    }
}

/* width */
.formWiz-fullform ::-webkit-scrollbar {
    width: 0.1px;
}

/* Track */
.formWiz-fullform ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.formWiz-fullform ::-webkit-scrollbar-thumb {
    background: #888;
}

.formWiz-fullform .scaleRatingClass {
    display: flex;
    background-color: #fff;
    justify-content: center;
    border: 1px solid #dcd0d0;
    height: 34px;
    cursor: pointer;
    font-size: 15px;
    align-items: center;
    width: 100%;
}

.formWiz-fullform .MuiFormControlLabel-label {
    font-size: inherit !important;
}

.formWiz-fullform .selectedScaleRatingClass {
    border: 1px solid var(--formWiz-widget_theme_color) !important;
    color: var(--formWiz-widget_theme_color) !important;
}

.scaleRatingClass:first-child {
    border-radius: 5px 0 0 5px !important;
}

.scaleRatingClass:last-child {
    border-radius: 0 5px 5px 0 !important;
}

.formWiz-fullform .scaleRatingClass:hover {
    border: 1px solid var(--formWiz-widget_theme_color);
}

.formWiz-fullform .MuiRating-iconFilled {
    color: var(--formWiz-widget_theme_color) !important;
}

.formWiz-fullform .MuiRating-iconHover {
    color: var(--formWiz-widget_theme_color) !important;
}

/* writewiz css */
.globalTextfieldStyle {
    width: 100%;
    display: flex;
    justify-content: space-around;
    background: #fff;
    border: none !important;
    border-color: unset !important;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) !important;
}

.globalTextfieldStyle fieldset {
    border-width: 0px !important;
    border-color: unset !important;
}

.globalTextfieldStyle .MuiInputBase-input {
    /* padding: 14px !important; */
    min-height: 50px !important;
    border-radius: 3px;
}

.globalPhoneStyle {
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) !important;
    padding: 7.5px !important;
}

.recaptchaBlock {
    transform: scale(1.25);
    display: flex;
    justify-content: center;
    margin-top: 12px !important;
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:450px) and (min-width:390px) {
    .recaptchaBlock {
        transform: scale(1.05) !important;
    }
}

@media screen and (max-width:390px) {
    .recaptchaBlock {
        transform: unset !important;
    }
}
.formWiz-input-field .select__multi-value__label
.formWiz-input-field .select__multi-value__label {
   font-size: 13px !important;
}
.select__menu{
    position: unset !important;
    margin-bottom: 0px !important;
}
.modifiedAutocompleteStyle fieldset {
    box-shadow: rgb(0 0 0 / 60%) 0px 0px 2px 0px;
border-color: unset;
border: unset;
}
.modifiedAutocompleteStyle .MuiChip-root {
    border-radius: 6px !important;
    background-color: white !important;
    border: 1px solid #E1CEE5 !important;
    font-size: 14px !important;
    font-weight: 400;
}
.modifiedAutocompleteStyle .MuiChip-deleteIcon {
    color: #010101 !important;
    font-size: 15px !important;
}
.modifiedAutocompleteStyle .MuiInputBase-root {
    padding: 8px !important;
    min-height: 50px;
}
.modifiedAutocompleteStyle textarea {
    padding: 0px !important;
}
.modifiedAutocompleteStyle .MuiChip-label {
    font-size: 14px !important;
    font-weight: 500;
    color: #000000DE;
    padding: 3px 12px 3px 7px;
}

.test textarea::placeholder {
    /* Your styling here */
    color: #969A9D; /* Placeholder text color */
    opacity: 1;
    z-index: 1;
    font-style: bold; /* Italicize the placeholder text */
    /* Add any other styles you want to apply */
  }
/* .customTemplateCss{
    min-height: 400px;
    max-height: 400px;
} */