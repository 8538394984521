@import "../../../utils/colorCode.scss";

.authButtonStyle {
    background: $primary !important;
    color: $white !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    border-radius: 3px !important;
    height: 56px;
}

.primaryButtonStyle {
    background: $primary !important;
    color: $white !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 8px 20px !important;
    border-radius: 5px !important;
}
.LogoutButtonStyle {
    background: transparent !important;
    color: $primary !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    padding-top: 9px !important; 
    padding-bottom: 9px !important; 
    border-top: 1px solid #E9E9E9 !important; 
    border-bottom: 1px solid #E9E9E9 !important;
}

.primaryButtonStyleIcon {
    background: $primary !important;
    color: $white !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 5px !important;
    border-radius: 5px !important;
}

.primaryButtonStylePersona {
    background: $primary !important;
    color: $white !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 6px 10px !important;
    border-radius: 5px !important;
    margin-left: 5px !important;
    min-width: 43px !important;
}

.individualButton {
    background: #FBF4FF !important;
    color: $primary !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 6px 10px !important;
    border-radius: 5px !important;
    font-size: 15px;
}

.primaryButtonStyle:hover {
    background: $primary
}

.primaryButtonStylePersona:hover {
    background: $primary
}

.secondaryButtonStyle {
    background: $secondary !important;
    color: $primary !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    padding: 6px 10px !important;
    box-shadow: unset !important;

}

.borderedButtonStyle {
    background: $white !important;
    color: $primary !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    padding: 6px 10px !important;
    box-shadow: 0px 0px 2px 0px #6A097D !important;
}

.transparentBorderedButtonStyle {
    background: transparent !important;
    color: $primary !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    padding: 6px 10px !important;
    box-shadow: 0px 0px 2px 0px #6A097D !important;
}

.borderedButtonStyle:hover {
    // background: $secondary !important;
    box-shadow: 0px 0px 4px 0px #6A097D;
}
.linearGredientAnimatedButton{
    position: relative !important;
    z-index: 0 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    color: $primary !important;
    padding: 6px 12px !important;
    border-radius: 5px !important;
    /* box-shadow: 0px 0px 2px #6A097D; */
    overflow: hidden !important;
    padding: 8px 10px !important; 
    text-transform: unset !important;
}
.linearGredientAnimatedButton::before {
    content: "" !important;
    position: absolute !important;
    z-index: -2 !important;
    width: 400% !important;
    height: 1000% !important;
    background-color: #fff !important;
    background-repeat: no-repeat !important;
    background-size: 100%100%, 50%50% !important;
    background-position: 0 0, 50% 50%, 0 50%, 0 50% !important;  
    background-image: linear-gradient(#6A097D, #2B61A1, #2D64A3, #A2DEF1 , pink) !important;
    animation: bgRotate 2.5s linear infinite !important;
  }
  
  .linearGredientAnimatedButton::after {
    content: "" !important;
    position: absolute !important ;
    z-index: -1 !important;
    width: calc(100% - 4px) !important; 
    height: calc(100% - 4px) !important;
    background-color: #fff !important;
    border-radius: 3px !important;
  }
  
  @keyframes bgRotate {
    100% {
      transform: rotate(360deg); /* changed from 1turn to 2turns */
    }
  }


.secondaryButtonStyle .Mui-disabled {
    cursor: not-allowed !important;
    border: 1px solid red !important;
}

.upgradeButtonStyle {
    // background: linear-gradient(to bottom, #6A097D 0%, #853495 100%, #C887D5 100%);
    // background: linear-gradient(to left, #6A097D 0%, #C887D5 100%);
    background: linear-gradient(to left, #6A097D 32%, #c985d7 100%);
    color: $white !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 6px 10px !important;
    border-radius: 5px !important;
}

.cancelButton {
    background: #FBF4FF !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 6px 21px !important;
    border-radius: 5px !important;
    font-size: 15px !important;
    border: 1px solid #CFCFCF !important;
    color: #767676 !important;
    box-shadow: unset !important;
}

.cancelButtonPrimaryBorder {
    border-radius: 5px !important;
    font-size: 15px !important;
    border: 1px solid $primary !important;
    color: $primary !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 7px 20px !important;
    margin-right: 10px !important;
    background: transparent !important;
}

.deleteButton {
    background: #CF2C2C !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 6px 20px !important;
    border-radius: 5px !important;
    font-size: 15px !important;
    border: 1px solid #CF2C2C !important;
    color: #ffffff !important;
    box-shadow: unset !important;
}

.primaryButtonStylePersonaEdit {
    background: $primary !important;
    color: $white !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 8px 27.5px !important;
    border-radius: 5px !important;
}

.primaryButtonStyleCreatePersona {
    background: $primary !important;
    color: $white !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    padding: 8px 20px !important;
    border-radius: 5px !important;
    height: 40px !important;
}

@media screen and (min-width:0px) and (max-width:600px) {
    .cancelButtonPrimaryBorder {
        width: 97%;
    }

    .primaryButtonStylePersonaEdit {
        width: 97%;
    }
}

.cancelDomainButton {
    background: #FBF4FF !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 5px 20px !important;
    border-radius: 5px !important;
    font-size: 15px !important;
    border: 1px solid #CFCFCF !important;
    color: #767676 !important;
    box-shadow: unset !important;
}

.addButton {
    background: #6a097d !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 5px 30px !important;
    border-radius: 5px !important;
    font-size: 15px !important;
    border: 1px solid #CFCFCF !important;
    color: white !important;
    box-shadow: unset !important;
}

.textVariantStyle {
    color: $primary !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    text-transform: none !important;
    padding: 0 !important;
}

@media (hover: hover) {
    .borderedButtonStyle:hover {
        background: $secondary !important;
    }
}

.headerUpgradeButton {
    color: $white;
    background: linear-gradient(to left, #0A6ADD 3%, #6A097D 95%);
    box-shadow: 0px 0px 4px 0px rgba(104, 41, 123, 0.5);
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}

.boxShadowButtonStyle {
    box-shadow: 0 0 3px 0 rgba(106, 9, 125, 0.4);
    font-size: 13px;
    font-weight: 600;
    background: $white !important;
    color: $primary !important;
    border-radius: 5px;
    height: 29px;
    width: 80px;
}