@import "../../../utils/colorCode.scss";

.customTemplateWrapper {
    // padding: 25px 40px 10px !important;
    padding: 30px 40px 20px !important;
}

.customTemplateTextStyle {
    font-size: 18px;
    font-weight: 600;
    color: $black;
}

.customTemplateDescTextStyle {
    font-size: 15px;
    font-weight: 400;
    color: $grey;
}

.saveTemplateButtonWrapper {
    width: 150px;
}

.customTemplateContentStyle {
    border-top: 0.5px solid #B3B3B3;
}

.rightPanelStyle {
    background: #FBF4FF;
    padding: 25px;
}


.ctMainHeadItem {
    margin: 0px 0px 10px !important;
}


.ctMainItem {
    // border: 1px solid#B3B3B3;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 30px 25px 0px !important;
    margin: 10px 0px !important;
}

.ctHeadItem {
    margin: 2px 0px !important;
}

.ctSubHeadItem {
    margin: 2px 0px !important;
}

.ctSaveBtnContainer {
    justify-content: end;
}

.ctSaveBtnItem {
    margin: 10px 0px;
}

.ctLeftMainItem {
    padding: 0px 20px 12px 0px;
    background-color: #FFF !important;
}

.ctContentMainContainer {
    justify-content: space-between !important;
}

.ctRightMainItem {
    padding: 10px 20px 10px !important;
    background-color: rgba(251, 244, 255, 0.50) !important;
}

.ctMainContentItem {
    margin: 20px 0px 0px !important;
    // padding: 20px 0px;
    border-top: 0.5px solid #D9D9D9 !important;
}

.ctFieldLabelSpacing {
    margin: 5px 0px !important;
}

.ctGlobalFieldLebel {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: "#1F384C" !important;
}

.ctFieldsGlobalItem {
    margin: 8px 0px !important;
}

.previewTextWrapper {
    color: #7A7A7A;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 35px !important;
}

.ctTextarea {
    outline: none;
    border: 1px solid #D9D9D9;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #4C4A4D;

    &::placeholder {
        color: rgba(0, 0, 0, 0.4) !important;
        opacity: 0.9
    }
}

.ctTextarea {
    outline: none;
    border: 1px solid #D9D9D9;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #4C4A4D;
}

.ctTryTemplate {
    background-color: #F7E9FF !important;
    color: #6A097D !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-decoration: none !important;
}

.ctUpdatedSaveBtn {
    background-color: #6A097D !important;
    color: #F7E9FF !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-decoration: none !important;
}

.ctTemplateNameTextfield input {
    padding: 12px 14px !important;
    border-radius: 5px !important;
}



@media screen and (min-width:320px) and (max-width:600px) {
    .customTemplateWrapper {
        padding: 30px 15px !important;
    }

    .ctMainItem {
        padding: 30px 15px !important;
    }

    .ctLeftMainItem {
        padding: 0px 5px 10px 5px;
        background-color: #FFF !important;
    }

    .ctRightMainItem {
        padding: 10px 10px 10px !important;
        background-color: rgba(251, 244, 255, 0.50) !important;
    }

    .ctSaveBtnContainer {
        justify-content: center;
        margin: 30px 0px 0px;
    }


}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .customTemplateWrapper {
        padding: 30px 15px !important;
    }

    .ctMainItem {
        padding: 30px 10px !important;
    }

    .ctLeftMainItem {
        padding: 0px 5px 10px 5px;
        background-color: #FFF !important;
    }

    .ctRightMainItem {
        padding: 10px 10px 10px !important;
        background-color: rgba(251, 244, 255, 0.50) !important;

    }


}

@media screen and (min-width: 900px) and (max-width: 1200px) {
    .customTemplateWrapper {
        padding: 30px 15px !important;
    }

    .ctMainItem {
        padding: 30px 20px !important;
    }

    .ctLeftMainItem {
        padding: 0px 0px 10px;
        background-color: #FFF !important;
    }

    .ctRightMainItem {
        margin: 20px 0px !important;
        padding: 10px 10px 10px !important;
        background-color: rgba(251, 244, 255, 0.50) !important;
    }


}

.tryTemplateButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
}

.outputContainer {
    background: $white;
    min-height: 140px;
    padding: 15px;
    margin-top: 10px !important;
}

.TryTemplateText {
    color: $grey;
    font-size: 14px;
}