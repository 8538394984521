.smallMenu {
    maxHeight: 150px;
}

.welcome_pg_wz_logo {
    width: 170px;
}

.welcomePageProgress {
    stroke: #6A097D !important;
}

.skipBackBtn {
    border: none !important;
    color: rgba(0, 0, 0, 0.7) !important;
    font-weight: 600 !important;
    text-transform: none !important;
    font-size: 18px !important;
    align-items: center !important;
    column-gap: 0px;
    justify-content: center !important;
    align-content: end;
}

.questionsContainer {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: start !important;
    height: 60vh !important;
    width: 480px !important;
    max-width: 90% !important;
    margin: auto !important;
}

.questionsWrapper {
    width: 100% !important;
}

.navContainer {
    justify-content: space-between;
    align-items: center;
    width: 90% !important;
    padding-top: 20px;
    margin-inline: auto;
}

.staticDataWrapper {
    padding-bottom: 35px;
}

.backSkipContainer {
    height: 25vh;
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 90%;
    margin: auto;
}

.dropdownForOptions {
    margin-top: 10px;
    width: 100%;
}

.textField {
    border: none !important;

}

.textField>div {
    border: none !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25) !important;
}

.textField>div:focus {
    // border: 1px solid rgba(0, 0, 0, 0.25) !important;
    // border: 0px solid black !important;
    outline: 1px solid rgba(0, 0, 0, 0.5) !important;
}


.textField input {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}


.textField input::placeholder {
    font-size: 14px !important;
}

.startWritingBtn {
    font-size: 17px !important;
    background: rgb(106, 9, 125) !important;
    color: white !important;
    text-transform: none !important;
    margin-top: 35px !important;
}

.startWritingBtn:disabled {
    background: rgba(106, 9, 125, 0.5) !important;
}

.optionsList {
    margin-top: 10px;
    padding: 0;
    border-radius: 8px;
    font-size: 10px !important;
    width: 90%;
}

.optionsList div {
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 4px;
    // background: #f1f1f1 !important;
    border-radius: 6px;
}

.optionsList span {
    font-size: 14px !important;
    font-weight: 600;
}

.optionsList li:hover div {
    background-color: #f7e9ff !important;
    color: black !important;
}

.optionsList li:active div {
    background-color: #f7e9ff !important;
    color: black !important;
}

@media (max-width: 500px) {
    .welcome_pg_wz_logo {
        width: 140px;
    }
}