@import '../../../utils/colorCode.scss';

.optionStyle {
  padding: 13px;
  border-bottom: 1px solid #EDEDED;
  border-radius: 10px;
  font-size: 15px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;

}

.paginationStyle{
  font-weight: 400 !important;
}

.disabledOptionStyle {
  padding: 13px;
  border-bottom: 1px solid #EDEDED;
  border-radius: 10px;
  font-size: 15px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.optionStyle:hover {
  background: #FBF3FF;
  border-bottom: unset;
  border-radius: 10px;
}

.selectedOptionStyle {
  position: relative;
  font-size: 15px;
  padding: 13px;
  border-radius: 10px;
  font-weight: 600;
  color: #6A097D;
  overflow: hidden;
  /* Hide overflowing animations */
  background-color: #fbf3ff;
  /* Default background color */
}

.selectedOptionStyle::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  font-weight: 600;
  background-color: #6A097D;
  /* Ripple color, change it to match the text color */
  opacity: 0.2;
  /* Adjust the opacity as desired */
  animation: rippleEffect 1s ease;
}

@keyframes rippleEffect {
  to {
    width: 150%;
    /* Adjust the size of the ripple effect */
    height: 150%;
    opacity: 0;
  }
}

.optionHeader {
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.textFieldStyle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.textFieldStyle .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $primary !important;
  border-width: 1px !important;
}

.questionInputLabelStyle {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
  color: $black
}

.blogTitleWrapper {
  margin: 8px 0px 20px !important;
}

.generatedTitlesGrid{
  width: 100% !important;
  padding-left: 13px !important; 
}
.gereratedTitleGridContainer{
  border-bottom: 1px solid #EDEDED !important;
  padding-bottom: 10px !important;
}
.generatedTitlesItemGrid{
  width: 100% !important;
}
.regenerateTitleGrid{
  margin-top: 20px !important;
}