.rewriteDialog .MuiModal-backdrop {
    background-color: transparent !important;
}

.rewriteDialog .MuiPaper-elevation24 {
    width: 40% !important;
}

.rewriteTextField div {
    border-radius: 10px !important;
}

.rewriteTextField input {
    padding: 14px 14px !important;
}

.rewriteTextField fieldset {
    box-shadow: 0px 0px 2px 0px #00000080;
}

.tabStyle {
    min-height: 30px !important;
}

.tabStyle {
    text-transform: capitalize !important;
}

.tabGroupStyle {
    border-bottom: 1px solid #EDEDED !important;
    min-height: 40px !important;
}

.rewriteMore {
    position: absolute;
    right: 10px;
}

.rewriteMoreBtn {
    border-bottom: 1px solid #EDEDED !important;
    color: black !important;
    padding: 7px 8px !important;
    text-transform: capitalize !important;
}

.rewriteDialog .MuiPaper-root {
    box-shadow: 0px 0px 15px 0px #E3E3E3 !important;
    border-radius: 10px !important;
}

.rewriteContent {
    max-height: 180px;
    overflow-y: scroll
}

.moreOptionPopover .MuiPaper-root {
    height: 210px !important;
    box-shadow: 0px 0px 12px 0px #0000001A;
    width: 15% !important;
}

.rewriteContent::-webkit-scrollbar {
    width: 0.2em;
    height: 2px
}

.rewriteContent::-webkit-scrollbar-track {
    max-height: 50%;
    height: 50%;
}

.rewriteContent::-webkit-scrollbar-thumb {
    background-color: #8f8585;
    outline: 1px solid slategrey;
    border-radius: 5px;
    opacity: 0.50 !important;
}

.moreOptionItem {
    padding: 10px 10px 10px 10px !important;
    cursor: pointer;
}

.moreOptionItem:hover {
    background-color: #F8ECFF;
}

.moreOptionMainItem::-webkit-scrollbar {
    width: 0.2em;
    height: 2px
}

.moreOptionMainItem::-webkit-scrollbar-track {
    max-height: 50%;
    height: 50%;
}

.moreOptionMainItem::-webkit-scrollbar-thumb {
    background-color: #8f8585;
    outline: 1px solid slategrey;
    border-radius: 5px;
    opacity: 0.50 !important;
}

.rewriteTextField .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #f7e9ff !important;
}