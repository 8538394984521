@import "../../../utils/colorCode.scss";

.templateWrapper {
    overflow: scroll;
}

.templateSearchbarStyle {
    // position: -webkit-sticky;
    // position: sticky;
    // top: 2%;
    background: $white;
}

.featuredTemplateTileStyle {
    // max-width: 220px;
    // width: 100%;
    // display: flex;
    // justify-content: start;
    // align-items: center;
    // height: 54px;
    padding: 15px !important;
    box-shadow: 0px 0px 4px 0px #D9D9D9;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    margin-right: 15px !important;
    margin-bottom: 10px !important;
    color: $black;
}

.featuredTemplateTileStyle:hover {
    // box-shadow: 0px 0px 4px 0px #dfa5ff;
    box-shadow: 0px 2px 4px 0px #00000040;
}

.featureIconStyle {
    // margin-right: 15px !important;
    height: 18px;
    width: 18px;
}

.categoryTileStyle {
    padding: 11px 18px;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 15px;
    font-weight: 400;
    // color: #A5A5A5;\
    color: $black;
    margin-right: 10px !important;
    margin-bottom: 20px !important;
}

.categoryTileStyle:hover {
    padding: 11px 18px;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 15px;
    font-weight: 400;
    color: $white;
    margin-right: 10px !important;
    background: $primary;
    margin-bottom: 20px !important;
    // padding: 11px 18px;
    // border-radius: 5px;
    // // border: 1px solid $primary;
    // box-shadow: 0px 0px 3px 0px #6A097D;
    // font-size: 15px;
    // font-weight: 400;
    // color: #A5A5A5;
    // margin-right: 10px !important;
    // margin-bottom: 20px !important;
}

.selectedCategoryTileStyle {
    padding: 11px 18px;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 15px;
    font-weight: 400;
    color: $white;
    margin-right: 10px !important;
    background: $primary;
    margin-bottom: 20px !important;
}

.categoryNameStyle {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #000 !important;
    margin: 0px 0px 1px !important;
    // color: $black !important;
    // margin-top: 5px !important;
    // margin-bottom: 20px !important;
}

.categoryDescriptionStyle {
    font-size: 13px;
    font-weight: 500;
    color: $grey;
    margin: 1px 0px 20px !important;
}

.templateTileStyle {
    // max-width: 289px;
    // max-width: 299px !important;
    height: 190px;
    padding: 20px 25px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 0px #00000014;
    margin-right: 20px !important;
    margin-bottom: 15px !important;
}

.templateTileStyle:hover {
    box-shadow: 0px 0px 3px 0px #6A097D;
}

.templateTitleTextstyle {
    font-size: 15px;
    font-weight: 500;
    color: $black;
    margin-bottom: 10px !important;
}

.templateDescriptionTextStyle {
    font-size: 14px;
    font-weight: 400;
    color: $grey;
}

.templateIconStyle {
    margin-bottom: 10px !important;
}

.featuredTilesWrapper {
    margin-bottom: 5px !important;
}

.categoryListWrapper {
    // position: -webkit-sticky;
    // position: sticky;
    // top: 10%;
    background: $white;
}



.hideFeatureBtnItem {
    text-align: end !important;
    margin: 0px 0px 10px !important;
}

.hideFeatureUpIconBtn {
    color: #6A097D !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    text-transform: none !important;
    justify-content: end;
}

.categoryListDropDown {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}


.categodydropdowntextfield .MuiIconButton-root {
    padding: 0 !important;
}

.templateItem {
    // margin-bottom: 20px !important;
}

.tempSearchbarItem {
    text-align: end !important;
}

.tempSearchbarItemPersonaList {
    text-align: end !important;
}

//  .tempSearchbarItemPersonaList .globalSearchBar{
//     height: auto !important;
//  }

// .titleSticky {
//     position: sticky;
//     top: 0;  /* Always at the top */
//     z-index: 10;  /* Highest layer */
// }

// .stickyCategory {
//     position: sticky;
//     top: [HeightOfYourTitleHeader]px;  /* replace [HeightOfYourTitleHeader] with the actual height value */
//     z-index: 9;  /* Below the title header */
// }


.titleSticky {
    // position: sticky !important;
    top: 40px !important;
    z-index: 10 !important;
    background-color: white !important;
}

.stickyCategory {
    top: 150px !important;
    position: sticky !important;
}

.templateMainItem {
    background-color: white !important;
    z-index: 1 !important;
    height: calc(100vh - 300px) !important;
}

.featuredTemplateViewAllStyle {
    background: #f6f2f9;
    box-shadow: 0px 0px 2px 0px #6A097D;
}

.bannerImgDesktop {
    border-radius: 10px;
    object-fit: fill;
    width: inherit;
}

.bannerImgMobile {
    display: none;
}

.headerToolbar {
    width: 95% !important;
    margin: 0 auto !important;
}

.featuredTemplateImgItem {
    display: flex;
}

.templateDescOverflowStyle {
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    /* Limit the text to 3 lines */
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.cbtMainHead {
    font-size: 18px;
    font-weight: 600;
    color: $black;
    margin-bottom: 8px !important;
}

.noDataFoundItem {
    height: calc(100vh - 250px) !important;
    display: flex;
    align-items: center;
}

.tempCardsMainItem {
    margin: 0px 0px 5px !important;
}

.templatCategoryMainHeadingItem {
    margin: 10px 0px 15px !important;
}

.templatCardMainHeadingItem {
    margin: 10px 0px !important;
}

.templatCardMainHeading {
    color: #1F384C !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}


.homeTableSubHeading {
    color: #7A7A7A !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.HomeTableHeadingMainItem {
    margin: 0px 0px 15px !important;
}

.homeWelcomeText {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #1F384C !important;
}

.homeWelcomeHighlightedText {
    color: #6A097D !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}



// Home Banner 

.HomeBannerBgDesktopItem {
    background-image: url('../../../assets/HomeDesktopBanner.png');
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    object-fit: fill !important;
    height: auto !important;
    width: 100% !important;
    border-radius: 10px !important;
}

.HomeBannerBgHomeItem {
    background-image: url('../../../assets/HomeMobileBanner.png');
    display: none !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    object-fit: fill !important;
    width: 100% !important;
    border-radius: 10px !important;
    height: auto;
}

.HomeBannerMainText {
    color: #FFF !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.HomeBannerSubText {
    color: #FFF !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.HomeBannerBtn {
    border-radius: 5px !important;
    background: #F7E9FF !important;
    text-transform: none !important;
    color: #6A097D !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}


// Home Banner 


// Can not find New Card 

.cfcMainItem {
    cursor: pointer;
    background-color: #FBF4FF !important;
}

.cfcMainText {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: black !important;
}

.cfcSubtext {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #7A7A7A !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.cfcItemSpacing {
    margin-bottom: 10px !important;
}

// New Card 






@media screen and (min-width:320px) and (max-width:600px) {

    .templateSearchbarStyle {
        margin: 0px 0px 10px !important;
    }

    .featureItem {
        margin-top: 15px !important;
    }

    .HomeBannerBgHomeItem {
        display: block !important;
    }

    .HomeBannerBgDesktopItem {
        display: none !important;
    }

}

@media screen and (min-width: 0px) and (max-width: 369px) {
    .dashboardChildSectionTextStyle {
        font-size: 14px;
    }

    .hideFeatureBtnItem {
        max-width: 38% !important;
    }
}



@media screen and (min-width:900px) and (max-width:1200px) {
    .tempBlogCards {
        flex-wrap: wrap !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 900px) {
    .templateTileStyle {
        height: 190px;
        padding: 20px 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
        margin-bottom: 15px !important;
        margin-right: 0px !important;
    }

    // .featuredTemplateTileStyle {
    //     padding: 0px 15px;
    //     // display: flex;
    //     // align-items: center;
    //     height: 54px;
    //     box-shadow: 0px 0px 4px 0px #D9D9D9;
    //     border-radius: 5px;
    //     font-size: 15px;
    //     font-weight: 600;
    //     margin-bottom: 15px !important;
    //     margin-right: 15px !important;

    // }

    .featuredTilesWrapper {
        margin-bottom: 5px !important;
        // padding: 12px 8px 8px !important;
        // justify-content: space-between;
    }


}

@media screen and (min-width: 320px) and (max-width: 600px) {

    .featuredTemplateImgItem {
        width: 100% !important;
        // margin: 0px 0px 5px !important;
    }

    .featuredTemplateTileStyle {
        padding: 15px !important;
        margin: 10px 0px !important;
    }

    .featureTemplateAlignment {
        justify-content: space-between;
    }

    .fetureTempleLength {
        min-width: 47% !important;
        max-width: 47% !important;
    }

    .bannerImgDesktop {
        display: none;
    }

    .bannerImgMobile {
        border-radius: 10px;
        object-fit: fill;
        width: inherit;
        display: flex !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 1200px) {
    .bannerImgDesktop {
        display: none;
    }

    .bannerImgMobile {
        border-radius: 10px;
        object-fit: fill;
        width: inherit;
        display: flex !important;
    }
}

.menuStyleSidePanel {
    box-shadow: 0px 0px 4px 0px #6A097D4D;
}

// .menuStyleSidePanel :global(.MuiList-root) {
//     display: flex;
//     flex-direction: column;
//     padding: 15px;
// }

// .menuStyleSidePanel :global(.MuiButtonBase-root) {
//     justify-content: start;
//     padding: 5px 0px;
// }