.otpInputError {
    font-size: 16px !important;
    // width: 60px !important;
    width: 56px !important;
    height: 40px !important;
    margin: 4px !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border: 1px solid #FF0000;
    outline: none;
    text-align: center;
    justify-content: center
}

.inputOtpStyle {
    font-size: 16px !important;
    // width: 60px !important;
    // width: 47px !important;
    // width: 56px !important;
    width: 88% !important;
    height: 40px !important;
    margin: 4px !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    outline: none;
    text-align: center;
    justify-content: center
}

@media only screen and (max-width: 530px) {
    .g-recaptcha {
        transform: scale(0.77);
        transform-origin: 0 0;
    }

    .inputOtpStyle {
        border-radius: 0px !important;
        // width: 50px !important;
        height: 50px !important;
        font-size: 16px !important;
        color: #4C4A4D !important;
        font-weight: 400 !important;
        caret-color: blue !important;

    }

    .otpInputError {
        border-radius: 0px !important;
        width: 50px !important;
        height: 50px !important;
        font-size: 16px !important;
        color: #4C4A4D !important;
        font-weight: 400 !important;
        caret-color: blue !important;
        border: 1px solid #FF0000;
        outline: none;
        text-align: center;
        justify-content: center
    }
}


@media only screen and (max-width: 450px) {
    .g-recaptcha {
        transform: scale(0.77);
        transform-origin: 0 0;
    }

    .inputOtpStyle {
        border-radius: 0px !important;
        // width: 40px !important;
        height: 50px !important;
        font-size: 16px !important;
        color: #4C4A4D !important;
        font-weight: 400 !important;
        caret-color: blue !important;

    }

    .otpInputError {
        border-radius: 0px !important;
        width: 40px !important;
        height: 50px !important;
        font-size: 16px !important;
        color: #4C4A4D !important;
        font-weight: 400 !important;
        caret-color: blue !important;
        border: 1px solid #FF0000;
        outline: none;
        text-align: center;
        justify-content: center
    }
}


@media only screen and (max-width: 400px) {
    .g-recaptcha {
        transform: scale(0.77);
        transform-origin: 0 0;
    }

    .inputOtpStyle {
        border-radius: 0px !important;
        // width: 40px !important;
        height: 50px !important;
        font-size: 14px !important;
        color: #4C4A4D !important;
        font-weight: 400 !important;
        caret-color: blue !important;

    }

    .otpInputError {
        border-radius: 0px !important;
        width: 40px !important;
        height: 50px !important;
        font-size: 14px !important;
        color: #4C4A4D !important;
        font-weight: 400 !important;
        caret-color: blue !important;
        border: 1px solid #FF0000;
        outline: none;
        text-align: center;
        justify-content: center
    }

}