@import "../../../utils/colorCode.scss";

.sideNavigationPanelStyle :global(.MuiPaper-root) {
    background: $sideNavigationPanel;
    border-right: unset !important;
}

.sideNavigationPanelStyle :global(.MuiToolbar-root) {
    justify-content: center;
    min-height: unset !important;
    padding: 30px 30px 20px 30px !important;
    cursor: pointer;
}

.sideNavigationPanelStyle .selectedListItemStyle {
    font-size: 14px;
    font-weight: 500;
    background: $primary;
    color: $white;
    cursor: pointer;
}

.sideNavigationPanelStyle .selectedListItemStyle:hover {
    font-size: 14px;
    font-weight: 500;
    background: $primary !important;
    color: $white !important;
    cursor: pointer;
}

.sideNavigationPanelStyle :global(.MuiListItem-root:hover) {
    background: #EFD7FE;
    color: $primary;
    cursor: pointer;
}

.sideNavigationPanelStyle :global(.MuiListItemText-primary) {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.sideNavigationPanelStyle :global(.MuiListItemButton-root) {
    padding-left: 20px !important;
    border-radius: 7px;
    cursor: pointer;
}

.sideNavigationPanelStyle :global(.MuiListItemButton-root:hover) {
    background-color: unset;
    cursor: pointer;
}

.sideNavigationPanelStyle :global(.MuiListItem-root) {
    padding: 0px;
    height: 42px;
    margin: 10px 0px;
    border-radius: 7px;
    cursor: pointer;
}

.sideNavigationPanelStyle :global(.MuiListItemIcon-root) {
    min-width: unset;
    margin-right: 20px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    // height: 18px;
    // width: 18px;
}

.sideNavigationPanelStyle .listItemStyle {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}


.creditUsedComponentWrapper {
    margin-bottom: 20px !important;
}

.downloadExtensionWrapper {
    margin: 0px 15px 20px !important;
}

.profileWrapper {
    margin: 0px 15px 10px !important;
}

.navigationPanelContentWrapper {
    height: 100% !important;
    flex-wrap: nowrap !important;
}

.navigationPanelContentWrapperAnonymous {
    height: 100% !important;
    flex-wrap: nowrap !important;
}

.imageNavigationMenu {
    margin: 0px 20px !important;
    height: 18px;
    width: 18px;
    padding-left: 0px !important;
}


.writewizLogoStyle {
    height: auto;
    width: 150px;
    display: flex;
    flex-wrap: nowrap;
}

.drawerOpen {
    transition: width 0.3s ease-in-out;
}

/* Define a class for the closed state transition */
.drawerClosed {
    transition: width 0.3s ease-in-out;
}

// to remove scrollbar in small screens
@media screen and (max-height:785px) {

    .sideNavigationPanelStyle :global(.MuiToolbar-root) {
        justify-content: center;
        min-height: unset !important;
        padding: 20px 30px 10px 30px !important;
        cursor: pointer;
    }

    .sideNavigationPanelStyle :global(.MuiListItem-root) {
        padding: 0px;
        height: 39px;
        margin: 7px 0px;
        border-radius: 7px;
        cursor: pointer;
    }

    // .creditUsedComponentWrapper {
    //     margin-bottom: 10px !important;
    // }
}

.navigationPanelContentWrapper :global(.MuiList-root) {
    max-height: calc(100vh - 330px);
    overflow-y: scroll !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.navigationPanelContentWrapperAnonymous :global(.MuiList-root) {
    max-height: calc(100vh - 190px);
    overflow-y: scroll !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.navigationPanelContentWrapper :global(.MuiList-root) {
    &::-webkit-scrollbar-thumb {
        background-color: #D9D9D9 !important;
        outline: unset !important;
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        width: 5px !important;
    }
}


.navigationPanelContentWrapperAnonymous :global(.MuiList-root) {
    &::-webkit-scrollbar-thumb {
        background-color: #D9D9D9 !important;
        outline: unset !important;
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        width: 5px !important;
    }
}


// @media screen and (max-height: 703px) and (min-height: 697px) {
//     .navigationPanelContentWrapper :global(.MuiList-root) {

//         max-height: 390px !important;
//         overflow-y: scroll !important;
//         padding-bottom: 0px !important;
//     }
// }

// @media screen and (max-height: 697px) and (min-height: 500px) {
//     .navigationPanelContentWrapper :global(.MuiList-root) {

//         max-height: 350px !important;
//         overflow-y: scroll !important;
//         padding-bottom: 0px !important;
//     }
// }