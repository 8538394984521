@import "../../../utils/colorCode.scss";


.templateSearchbarStyle {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: $white;
}

.docTitleTextStyle {
    font-size: 18px !important;
    font-weight: 600;
    color: $black;
}

.featuredTemplateTileStyle {
    max-width: 220px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 54px;
    box-shadow: 0px 0px 4px 0px #D9D9D9;
    border-radius: 5px;
    margin-right: 10px !important;
}

.newDocText {
    font-size: 15px;
    font-weight: 600;
}

.docDescription {
    font-size: 12px;
    font-weight: 400;
    color: #7A7A7A;
}



.docSectionTextStyle {
    font-size: 15px;
    font-weight: 600;
    // color: $grey;
    color: #1F384C;
    margin-top: 10px !important;
    margin-bottom: 18px !important;
}

.featureIconStyle {
    margin: 15px !important
}

.categoryTileStyle {
    padding: 11px 18px;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 15px;
    font-weight: 400;
    color: #A5A5A5;
    margin-right: 10px !important;
}

.categoryNameStyle {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: $black !important;
    margin-top: 20px !important;
}

.categoryDescriptionStyle {
    font-size: 13px;
    font-weight: 400;
    color: $grey;
    margin: 10px 0px 20px !important;
}

.templateTileStyle {
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    max-width: 299px !important;
    margin-right: 20px !important;
}

.templateTitleTextstyle {
    font-size: 15px;
    font-weight: 500;
    color: $black;
    margin-bottom: 10px !important;
}

.templateDescriptionTextStyle {
    font-size: 13px;
    font-weight: 400;
    color: $grey;
}

.templateIconStyle {
    margin-bottom: 21px !important;
}

.featuredTilesWrapper {
    margin-bottom: 25px !important;
    margin-top: 25px !important;
}

.categoryListWrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: $white;
}

// document

.newDocHeadTextItem {
    margin: 30px 0px 10px !important;
    color: #7A7A7A !important;

}

.newDocHeadText {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.documentsTableItem {
    height: calc(100vh - 180px) !important;
}

// document