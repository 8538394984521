.trialComponentGrid {
    padding: 11px 15px;
    border-radius: 5px;
    background: linear-gradient(170deg, #EFD7FE 50%, #68A1E6 75%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.trialComponentText {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #6A097D !important;
}

.HomeBannerBtn {
    border-radius: 5px !important;
    background: #F7E9FF !important;
    text-transform: none !important;
    color: #6A097D !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.selectPlanButton {
    text-align: end !important;
}

@media screen and (min-width:320px) and (max-width: 599px) {
    .trialComponentGrid {
        margin-top: -20px !important;
    }

    .trialComponentText {
        font-size: 15px !important;
        font-weight: 600 !important;
    }

    .HomeBannerBtn {
        font-size: 13px !important;
    }
}

@media screen and (min-width:600px) and (max-width: 699px) {
    .trialComponentText {
        font-size: 15px !important;
    }

    .HomeBannerBtn {
        font-size: 13px !important;
    }
}