@import "../../../utils/colorCode.scss";

// .outlineChild {
//     background-color: $white;
//     border-radius: 10px;
//     box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.0625);
//     overflow: auto;ta
//     height: 100%;
// }
.outlineChildFullHeight {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.0625);
    // padding: 20px 25px;
    // padding: 20px;
    overflow: auto;
    height: 100%;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}

.tabFormWrapper {
    height: 100%;
    // sx={{ display: "flex", flexDirection: "column", height: "100%" }} 
}

.tabAIOutputWrapper {
    background: $white;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    padding: 20px 25px;
    border-radius: 10px;
    height: 100%;
    // padding: 20px 20px 0px;
    height: 100%;
    display: flex;
    flex-direction: column !important;
    overflow: auto;
    // margin: 30px 0px !important;
}

.outlineChildPartialHeight {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.0625);
    overflow: auto;
    // max-height: calc(100% - 70px);
    height: 100%;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}

.outlineChild ::-webkit-scrollbar-track {
    max-height: unset;
}

.outlineChildTabHeader {
    margin-bottom: 15px !important;
}

.outlineChildTabHeader :global(.MuiTabs-indicator) {
    background-color: $black !important;
}

.outlineChildTabHeader :global(.MuiTabs-flexContainer) {
    height: 42px;
}

.outlineChildTabHeader :global(.Mui-selected) {
    color: $black !important;
}

.outlineChildTabHeader :global(.MuiTab-root) {
    min-height: 30px;
    padding: 0;
    font-size: 15px;
    font-weight: 600;
    text-transform: unset;
    min-width: unset;
    margin-right: 15px !important;
}

.settingsTabStyle :global(.MuiTabs-indicator) {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    height: 5px;
    background-color: $primary;
}

.settingsTabStyle :global(.Mui-selected) {
    color: $black;
    font-size: 15px;
    font-weight: 600;
    text-transform: unset;

}

.settingsTabStyle :global(.MuiTab-root) {
    font-size: 15px;
    font-weight: 600;
    text-transform: unset;
    border-bottom: 2px solid #D6D6D6;
}

.settingsTabStyle :global(.Mui-selected) {
    color: $black !important;
}

.settingsTabStyle {
    margin-bottom: 30px;
}


@media only screen and (min-width: 600px) and (max-width: 860px) {
    .tabAIOutputWrapper{
        height: calc(100vh - 280px);
    }
}

@media only screen and (min-width: 320px) and (max-width: 599px) {
    .tabAIOutputWrapper {
        background: $white;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        padding: 20px 10px;
        border-radius: 10px;
        // height: 100%;
        height: calc(100vh - 275px);
        display: flex;
        flex-direction: column !important;
        overflow: auto;
    }
}
@media only screen and (max-width: 860px) {
    .outlineChildPartialHeight{
        height: calc(100% - 130px);
    }
}