.prEditMainItem {
    padding: 27px 40px !important;
}

.prEditFormMainItem {
    border-radius: 10px 10px 0px 0px;
    background: #FFF;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    height: calc(100vh - 120px) !important;
}

.prTypeNameText {
    font-size: 15px;
    font-weight: 500;
}

.prEditHeaderMainContainer {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.prEditHeaderText {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.prEditGlobalformMainItem {
    height: calc(100vh - 240px) !important;
    padding-right: 10px !important;
    overflow: scroll !important;
}
.prEditGlobalformMainItem {
    &::-webkit-scrollbar-thumb {
        background-color: #D9D9D9 !important;
        outline: unset !important;
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        width: 5px !important;
    }
}

@media screen and (max-width : 600px) and (min-width : 320px) {
    .prEditMainItem {
        padding: 10px 20px !important;
    }

    .prEditFormMainItem {
        padding: 20px 10px;
    }
}