.cmpMainItem {
    padding: 25px 30px !important;
}


.cmpMainItemRightContainer {
    justify-content: end;
}

.cmpRightSubItem {
    margin: 0px 8px !important;
}

.cmpLeftSubItem {
    margin: 0px 8px !important;
}

.addIconBtn {
    border-radius: 5px !important;
    background: #FFF !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
    color: black !important;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}


.campaignsCardsMainItem {
    margin: 15px 0px 0px 0px !important;
    max-height: calc(100vh - 130px) !important;
    overflow: auto !important;
    padding: 10px 5px !important;
}

.campaignsCards {
    width: 297px !important;
    min-width: 297px !important;
    margin: 0px 30px 20px 0px !important;
    max-width: 297px !important;
    border-radius: 10px !important;
    background: #FFF !important;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08) !important;
    height: 132px !important;
    padding: 20px 15px !important;
    height: 182px !important;
    cursor: pointer !important;
}

.campaignsCards:hover {
    box-shadow: 0px 0px 8px 0px #6A097DB2 !important;
}

.cmpCardBtnItem {
    margin-top: 20px !important;
    width: 100% !important;
}

.cmpCardIconItem {
    background-color: #FBF4FF !important;
    padding: 10px !important;
    margin-right: 5px !important;
    border-radius: 25px;
    min-width: 50px;
    max-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.cmpCardIconName {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

.cmpCardBtns {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #575757 !important;
    text-transform: none !important;
    box-shadow: 0px 0px 1.5px 0px #6A097D !important;
    border-radius: 5px !important;
    border: none !important;
    padding: 5px 10px !important;
}

.cmpCardNameItem {
    width: 100% !important;
    margin: 0px 0px 10px !important;
}

.smpCardBtnItem {
    width: 100% !important;
    margin: 10px 0px 0px !important;
}

.cmpSearchbarSubItem {
    width: 286px;
}

.cmpNewCampaignBtnItem {
    width: 251px;
}

.cmpCreateBtnContainer {
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 3%;
}

.noDataFoundItem {
    height: calc(100vh - 250px) !important;
    display: flex;
    align-items: center;
}

.cmpHeaderGrid{
    margin-bottom: 10px !important;
}

.cmpDeleteIconButton{
    width: 30px !important;
    height: auto !important;
    border-radius: 100px !important;
}

.cmpCardIconMedia{
    width: auto !important; 
    height: 30px !important; 
    object-fit: none !important;
}

.cmpCardIconCampaignName{
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #4C4A4D !important;
    white-space: pre-line !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.noDataFoundContainer{
    display: block !important; 
    text-align: center !important;
}

.noCampaignCreatedItem{
    margin: 5px 0px 0px !important;
}
.noCampaignCreatedText{
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #4D4D4D !important
}

.creareNewCampaignItem{
    text-align: center !important; 
    width: 250px !important; 
    max-width: 250px !important; 
    margin: 0px auto !important;
}

.noDataFoundText{
    font-size: 15px !important; 
    font-weight: 500 !important;
}

.createDocumentIconImg{
    height: 18px !important; 
    width: auto !important
}

.globalHeadMainItemRightContainer{
    margin-bottom: 0px !important;
}

.cmpGlobalHeader{
    margin-bottom: 15px !important;
}

.cmpCreateCampaignText{
    color: #4C4A4D !important; 
    font-size: 18px !important; 
    font-weight: 600 !important;
}

.cmpCreateDivider{
    color: #B3B3B3 !important; 
    margin-top: 20px !important;
}

.cmpCreateButton{
    margin: 10px 0px !important;
}

.cmpGlobalHeadSpacing {
    padding: 25px 30px 10px !important;
}

.cmpCreateFormHeight {
    height: calc(100vh - 120px) !important;
    overflow: auto !important;
}

.cmpPopupHeadSpacing {
    padding: 25px !important;
}

.cmpCreateFormPopup {
    height: 50vh !important;
    overflow: auto !important;
}

.cmpPopupBorderItem {
    border-radius: 10px !important;
    background: #FFF !important;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) !important;
    padding: 25px !important;
}

.cmpPopupButton{
    margin: 10px 0px !important;
    text-align: center !important;
}

.cmpGlobalComponentBorderItem {
    border-radius: 10px !important;
    background: #FFF !important;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) !important;
    padding: 25px 30px !important;
}

.cmpGlobalContainedBtnStyles {
    background-color: #6A097D !important;
    color: #FFF !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    text-transform: none !important;
}

.cmpGlobalContainedDisabledBtnStyles {
    background-color: #6A097D !important;
    opacity: 0.5;
    color: #FFF !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    text-transform: none !important;
}

@media screen and (min-width: 320px) and (max-width : 599px) {
    .cmpGlobalComponentBorderItem{
        max-height: calc(100vh - 160px) !important;
        overflow: auto !important;
        padding: 25px 15px 0px !important;
    }
    .cmpGlobalHeadSpacing{
        padding: 15px !important;
    }
}

@media screen and (min-width: 600px) and (max-width : 919px) {
    .cmpCreateFormHeight {
        max-height: calc(100vh - 140px) !important;
        overflow: auto !important;
    }
    .cmpGlobalComponentBorderItem {
        padding: 25px 15px 40px !important;
    }
}

@media screen and (min-width: 600px) and (max-width : 900px) {
    .cmpGlobalHeadSpacing {
        padding: 25px 15px !important;
    }
}

@media screen and (min-width: 890px) and (max-width : 900px) {
    .cmpCreateFormHeight {
        max-height: calc(100vh - 120px) !important;
        overflow: auto !important;
    }
}

@media screen and (min-width: 900px) and (max-width : 919px) {
    .campaignsCardsMainItem {
        max-height: calc(100vh - 195px) !important;
    }
}

@media screen and (min-width: 919px) and (max-width : 1199px) {
    .campaignsCardsMainItem {
        max-height: calc(100vh - 170px) !important;
    }
}

@media screen and (min-width: 1200px) and (max-width : 1545px) {
    .campaignsCardsMainItem {
        max-height: calc(100vh - 150px) !important;
    }
}

@media screen and (min-width: 900px) and (max-width : 1200px) {

    .cmpMainItemRightContainer {
        justify-content: start;
        align-items: center;
    }

}

@media screen and (min-width: 599px) and (max-width : 899px) {

    .cmpMainItem {
        padding: 20px 20px !important;
    }

    .campaignsCardsMainItem {
        margin: 15px 0px 0px 0px !important;
    }

    .campaignsCardsMainItem {
        max-height: calc(100vh - 185px) !important;
    }

    .cmpMainItemRightContainer {
        justify-content: space-between;
        align-items: center;
    }

    .cmpRightSubItem {
        margin: 0px !important;
    }

    .campaignsCards {
        width: 297px !important;
        min-width: auto !important;
        margin: 0px 10px 20px 0px !important;
    }


}

@media screen and (min-width: 899px) and (max-width : 884px) {

    .campaignsCards {
        width: 297px !important;
        min-width: auto !important;
        margin: 0px 20px 20px 0px !important;
    }

}

@media screen and (min-width: 320px) and (max-width : 599px) {

    .cmpMainItem {
        padding: 20px 15px !important;
    }

    .campaignsCardsMainItem {
        margin: 10px 0px 0px 0px !important;
    }

    .cmpMainItemRightContainer {
        justify-content: start;
        align-items: center;
    }

    .cmpRightSubItem {
        margin: 0px 5px !important;
    }

    .cmpLeftSubItem {
        margin: 0px 5px 0px 0px !important;
    }
    .campaignsCardsMainItem {
        max-height: calc(100vh - 215px) !important;
    }
}