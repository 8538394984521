@import "../../../utils/colorCode.scss";

.personaNameSubHeading {
    font-size: 12px;
    color: #7A7A7A;
    font-weight: 500;
    margin-bottom: 30px !important;
}

.global_searchBar__yMHEb {
    max-width: 344px;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px #7A7A7A;
}

.global_searchBar__yMHEb .MuiInputBase-root {
    justify-content: space-between;
    width: 100%;
    border-radius: 5px;
    border: unset;
}

.searchBarPerosonaList {
    display: flex;
    justify-content: end;
    height: 33px;
}

.personaCreatedList {
    height: 192px;
    margin-right: 30px !important;
    margin-bottom: 30px !important;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px #00000014;
    position: relative;
    cursor: pointer;
    max-width: 325px !important;
    min-width: 325px !important;
}

.personaListSpacingContainer {
    margin: 25px 20px 20px 20px !important;
}


.personaCreatedListDefault {

    background: #fff !important;
    height: 192px;
    margin-right: 30px !important;
    margin-bottom: 30px !important;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px #00000014;
    position: relative;
    cursor: pointer;
    max-width: 325px !important;
    min-width: 325px !important;
    // for default border style
    $border: 2px solid transparent;
    color: #000;
    background-clip: padding-box !important;
    border: 2px solid transparent;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -4px;
        /* This is what pulls the pseudo-element under the transparent border */
        border-radius: inherit;
        background: linear-gradient(111.63deg, #6A097D 0%, #0A6ADD 100%);
    }

}

.personaCreatedList:hover {
    height: 192px;
    margin-right: 30px !important;
    margin-bottom: 30px !important;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px #6A097DB2;
    position: relative;
    cursor: pointer;
    max-width: 325px !important;
    min-width: 325px !important;
}

.personaCreatedListDefault:hover {
    height: 192px;
    margin-right: 30px !important;
    margin-bottom: 30px !important;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px #6A097DB2;
    position: relative;
    cursor: pointer;
    max-width: 325px !important;
    min-width: 325px !important;
}

.avatarImage {
    height: 44px;
    width: auto;
    object-fit: fill;
    margin-right: 12px !important;
}

.personaIndividualTypeName {
    position: absolute;
    right: 12px;
    top: 0px;
    background: #FBF4FF;
    font-weight: 500;
    font-size: 10px;
    color: #6A097D;
    padding: 5px 9px;
    border-radius: 0px 0px 5px 5px;
}

.personaBrandTypeName {
    position: absolute;
    right: 30px;
    top: 0px;
    background: #E7F1FC;
    font-weight: 500;
    font-size: 10px;
    color: #0A6ADD;
    padding: 5px 9px;
    border-radius: 0px 0px 5px 5px;
}

.crownPersona {
    position: absolute;
    right: 86px;
    top: 3px;
}

.createdPersonaTypeText {
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    color: #7A7A7A;
}

.personaToneCard {
    padding: 6px 8px;
    box-shadow: 0px 0px 1.5px 0px #6A097D;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    color: #575757;
    margin-right: 8px !important;
    max-width: 105px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.aboutPersona {
    font-size: 13px;
    font-weight: 400;
    color: $grey;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: absolute;
    top: 93px;
    max-width: 265px !important;
    min-width: 265px !important;
}

.personaconcise {
    bottom: 20px;
    max-width: 280px !important;
    min-width: 280px !important;
}

.personaOccupation {
    font-size: 13px;
    margin-top: 2px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 265px !important;
    min-width: 265px !important;
}

.personaName {
    font-size: 15px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 265px !important;
    min-width: 265px !important;
}

.personaListNoData {
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    padding: 20px 0px;
}

.featuredPersonaTileStyle {
    padding: 15px !important;
    box-shadow: 0px 0px 4px 0px #D9D9D9;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    margin-left: 20px !important;
}

.featuredPersonaTileStyle:hover {
    box-shadow: 0px 2px 4px 0px #00000040;
}

.personaHeaderContainer {
    flex-direction: row;
}

.personaListCreateNew {
    display: flex;
}

.personaFeatureIconStyle {
    margin-right: 15px !important;
    height: 18px;
    width: 18px;
}

.personaSearchBarContainer {
    justify-content: end;
}

.personaAvatarStyle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.personaNameStyle {
    color: $black;
    font-size: 15px;
    font-weight: 600;
}

.PersonaDisplayNameStyle {
    color: $grey;
    font-size: 13px;
    padding: 2px 0px 7px;
}

.PersonaDescpTooltip {
    background-color: unset !important;
}

.PersonaDescpTooltip :global(.MuiTooltip-popper) {
    background: $white !important;
    padding: 0px !important;
    background-color: unset !important;
}

.personaMenuContainer {
    padding: 15px;
    max-width: 289px;
    background-color: $white;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    flex-wrap: nowrap !important;
}

.personaAvatarStyle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.leftBlock {
    padding-left: 10px;
}

.personaSummaryStyle {
    color: $black;
    font-size: 12px;
}

.personacontainerToneCard {
    flex-wrap: nowrap !important;
    overflow: hidden;
    padding: 1px;
}

.upgradePopupItemStyle {
    padding: 40px;
    background: #FBF4FF;
}

.upgradePopupContainerStyle {
    justify-content: center;
    align-items: center;
}

.upgradePersonaText1 {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 20px;
}

.upgradePersonaText2 {
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 40px;
}

.upgradePersonaText3 {
    font-size: 18px;
    font-weight: 600;
}

.crownUpgradePersonaImage {
    width: 98px;
}

.headingNamePersona {
    display: flex;
}

.personaListSearchBar :global(.MuiOutlinedInput-input) {
    padding: 15.5px 8px !important;
}

.personaListSearchBar .jss1 {
    background-color: transparent !important;
}

@media screen and (min-width:0px) and (max-width:900px) {
    .personaCreatedList {
        height: 187px;
        margin-right: 0px !important;
        margin-bottom: 30px !important;
        padding: 27px 20px 20px 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 15px 0px #00000014;
        position: relative;
        cursor: pointer;
        max-width: 325px !important;
        min-width: 325px !important;
        width: 100%;
    }

    .aboutPersona {
        max-width: 90% !important;
        min-width: 90% !important;
    }

    .personaconcise {
        max-width: 100% !important;
        min-width: 90% !important;
    }

    .personaOccupation {
        max-width: 90% !important;
        min-width: 90% !important;
    }

    .personaName {
        max-width: 90% !important;
        min-width: 90% !important;
    }

    .personaHeaderContainer {
        flex-direction: column;
    }

    .personaListCreateNew {
        display: none;
    }

    .personaFeatureIconStyle {
        margin-right: 0px !important;
    }

    .featuredPersonaTileStyle {
        padding: 5px !important;
        margin-top: 15px !important;
        margin-left: 0px !important;
    }

    .personaSearchBarContainer {
        justify-content: start;
        margin-bottom: 20px !important;
    }
}

@media screen and (min-width:0px) and (max-width:599px) {
    .headingNamePersona {
        display: none;
    }

    .personaListSearchBar :global(.MuiOutlinedInput-input) {
        padding: 5.5px 8px !important;
    }
}