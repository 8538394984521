.maskBox {
    position: relative;
    color: #7A7A7A;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px #00000040;
}

.maskBox .mask {
    width: 150px;
    height: 45px;
    position: absolute;
    transition: all 0.5s ease;
    color: #6A097D !important;
    border-radius: 5px;
}

.maskBox :global(.MuiButton-root) {
    width: 150px;
    transition: all 0.2s 0.1s ease;
    color: #7A7A7A;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

.maskBox .selectedIndividualButton {
    width: 150px;
    color: #6A097D;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    box-shadow: 0px 0px 2px 0px #6A097D;
    border-radius: 4px 0px 0px 4px !important;
}

.maskBox .selectedBrandButton {
    width: 150px;
    color: #6A097D;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    box-shadow: 0px 0px 2px 0px #6A097D;
    border-radius: 0px 4px 4px 0px !important;
}

.personaDivider {
    margin: 0px 0px 10px 0px !important;
}

.personaDivider :global(.MuiDivider-root) {
    border-bottom-width: 0 !important;
}

.personaUpdateDivider {
    margin: 24px 0px 24px 0px !important;
}

.personaUpdateDivider :global(.MuiDivider-root) {
    border-bottom-width: 0 !important;
}

.personaEditText {
    font-size: 18px;
    font-weight: 600;
}

.personaEditTypeName {
    font-size: 18px;
    font-weight: 400;
}

.personaNameSubHeading {
    font-size: 15px;
    color: #7A7A7A;
    font-weight: 400;
    margin-bottom: 20px !important;
}

.personaTypeText {
    font-size: 16px;
    font-weight: 700;
    margin: 0px 19px 5px 19px !important;
}

.editPersonaCancelSaveButtonItem {
    display: flex !important;
}

.editPersonaCancelSaveButtonItemMobile {
    display: none !important;
}

.personaNameCreatePage {
    display: flex !important;
}

.personaNameCreatePageMobile {
    display: none !important;
}

.formUpdatePersonaScroll {
    max-width: 100%;
}

.chatbotMainContainer {
    padding: 30px 30px 10px !important;
}

.chatbotCreateMainItem {
    border-radius: 10px;
    background: #FFF;
    padding: 0px 0px 20px !important;
    overflow: auto !important;
    height: calc(100vh - 220px) !important;
}

@media screen and (min-width:0px) and (max-width:600px) {
    .editPersonaCancelSaveButtonItem {
        display: none !important;
    }

    .editPersonaCancelSaveButtonItemMobile {
        display: flex !important;
        padding: 0px 19px;
    }

    .formUpdatePersonaScroll {
        overflow: scroll;
        height: calc(100vh - 270px);
    }

    .personaNameCreatePage {
        display: none !important;
    }

    .personaNameCreatePageMobile {
        display: flex !important;
    }

    .createPersonaFormMobileHeight {
        overflow: scroll;
        height: calc(100vh - 230px);
    }

    .updateFormWrapper {
        max-height: unset !important;
        overflow-y: unset !important;
    }
}

.updateFormWrapper {
    max-height: calc(100vh - 260px);
    overflow-y: scroll;
}