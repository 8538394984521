@import "../../../utils/colorCode.scss";

.creditUsedContainerBanner {
    padding: 20px;
    border-radius: 5px !important;
    background-color: $sideNavigationPanel !important;
}

.creditUsedContainer {
    border-radius: 10px;
    box-shadow: 0px 0px 2px 0px #6A097D;
    padding: 15px !important;
    margin: 0 15px !important;
}

.CreditUsedRail {
    background-color: #DBDBDB !important;
    border-radius: 5px !important;
    height: 7px;
    margin-top: 20px !important;
}

.CreditUsedRail :global(.MuiLinearProgress-bar) {
    background-color: $primary !important;
    border-radius: 5px !important;
}

.firstBlock {
    border-bottom: 0.5px solid #CFCFCF;
    padding-bottom: 20px !important;
}

.planTextStyle {
    font-weight: 600;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
}

.creditStatusText {
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    margin-left: 7px !important;
}

.secondBlock {
    margin: 26px 0px 20px !important;
}

.freeTrialStyle {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    // margin-right: 12px !important;
}

.dayCountTrialStyle {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: 0;
}

.amountStyle {
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    font-weight: 500;
}

.monthlyStyle {
    font-weight: 500;
    font-size: 13px;
    color: rgba(173, 173, 173, 1);
}

.cancelSubscriptionButtonWrapper {
    margin-right: 10px !important;
}

.bannerTrialLabelItem {
    margin: 3px 0px !important;
}

.bannerCreditItem {
    margin: 15px 0px !important;
    text-align: end !important;
}

.bannerDetailMainItem {
    padding: 15px 5px !important;
}

@media only screen and (min-width: 320px) and (max-width: 954px) {
    .cancelSubscriptionButtonWrapper {
        margin-right: 10px !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    @media only screen and (min-width: 600px) and (max-width: 954px) {
        .billingHistoryButtonWrapper {
            margin-right: 10px !important;
            margin-top: 0px !important;
            margin-bottom: 10px !important;
        }
    }




    @media only screen and (min-width: 320px) and (max-width: 600px) {
        .billingHistoryButtonWrapper {
            margin-right: 10px !important;
            margin-top: 0px !important;
            margin-bottom: 10px !important;
        }
    }

    @media only screen and (min-width: 503px) and (max-width: 600px) {
        .billingHistoryButtonWrapper {
            margin-right: 10px !important;
            margin-top: 10px !important;
            margin-bottom: 10px !important;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 503px) {
        .billingHistoryButtonWrapper {
            margin-right: 10px !important;
            margin-top: 0px !important;
            margin-bottom: 10px !important;
        }
    }


}