@import "../../../utils/colorCode.scss";

.otpInputError {
    font-size: 16px !important;
    width: 60px !important;
    height: 40px !important;
    margin: 4px !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border: 1px solid #FF0000;
    outline: none;
    text-align: center;
    justify-content: center
}

.inputOtpStyle {
    font-size: 16px !important;
    width: 60px !important;
    height: 40px !important;
    margin: 4px !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    outline: none;
    text-align: center;
    justify-content: center
}

.rightBlockWrapper {
    width: 50%;
}

.rightBlock {
    background: linear-gradient(to right, #6A097D 0%, #6A097D 100%),
        linear-gradient(to right, #853495 0%, #853495 100%),
        linear-gradient(to right, #C887D5 0%, #C887D5 100%);
    height: 100vh;
    position: relative;
    overflow: hidden;
    flex-wrap: nowrap;
    min-height: 550px;
}

.leftBlockWrapper {
    width: 50% !important;
    padding: 0 50px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.leftBlock {
    height: 100vh;
    // max-width: 400px;
    // max-width: 323px;
    max-width: 385px;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap; 
    min-height: 550px;
}

.italicText {
    color: $white;
    font-size: 25px;
    max-width: 360px !important;
    font-weight: 600;
    font-style: italic;
    text-align: center;
}

@media screen and (max-width: 550px) {
    .leftBlockWrapper {
        padding: 0 30px !important;
    }
}

@media screen and (max-width:1000px) {
    .rightBlock {
        display: none !important;
    }

    .writewizLogoWrapperSecond {
        display: block !important;
        margin: 30px 0px !important;
    }

    .leftBlockWrapper {
        width: 100% !important;
        padding: 0 50px;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-height:900px) {

    .writeWizlogoWrapper {
        width: 100%;
        height: calc(100% - 550px) !important;
    }

    .rightPanelImg {
        position: fixed;
        // height: auto;
        height: 700px;
        width: auto;
        max-width: 950px;
        // right: -7%;
        // left: -7%;
        left: -1%;
        bottom: -8%;
    }
}

@media screen and (min-height:900px) {

    .writeWizlogoWrapper {
        width: 100%;
        height: calc(100% - 674px);
    }

    .rightPanelImg {
        position: fixed;
        // height: auto;
        height: 700px;
        width: auto;
        max-width: 950px;
        // right: -7%;
        // left: -7%;
        left: 1%;
        bottom: -3%;
    }
}


.recaptchaBlock {
    margin: 30px 0px 40px !important;
    transform: scale(1.25);
}

.mainRecaptchaBlock {
    display: flex;
    justify-content: center;
}

.whiteWeLogoStyle {
    margin-bottom: 35px;
}

.writeWizlogoWrapper {
    width: 100%;
    height: calc(100% - 674px);
}

.writewizLogoWrapperSecond {
    margin: 20px 0px;
    display: none;
}

.logoStyle {
    width: 200px;
    height: auto;
}

@media screen and (max-width:1735px) and (min-width:1550px) {
    .rightPanelImg {
        // right: -13%;
        left: -13%;
    }
}

@media screen and (max-width:1550px) and (min-width:1405px) {
    .rightPanelImg {
        // right: -20%;
        left: -20%;
    }
}

@media screen and (max-width:1405px) and (min-width:1300px) {
    .rightPanelImg {
        // right: -25%;
        left: -25%;
    }
}

@media screen and (max-width:450px) and (min-width:390px) {
    .recaptchaBlock {
        margin: 30px 0px 40px !important;
        transform: scale(1.15) !important;
    }
}

@media screen and (max-width:390px) {
    .recaptchaBlock {
        transform: unset !important;
    }
}

.editIconStyle {
    position: absolute !important;
    right: 18px;
}

@media screen and (max-width:600px) {
    .leftBlock {
        align-items: center;
        justify-content: start;
        height: unset !important;
    }

    .writewizLogoWrapperSecond {
        margin: 30px 0px 20px !important;
    }
}