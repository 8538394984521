@import "../../../utils/colorCode.scss";

.wzTabItem {
    border-bottom: 1px solid #B3B3B3 !important;
    padding-bottom: 25px;
}

.wzTabNewContentItem {
    border-bottom: 1px solid #B3B3B3 !important;
    padding-bottom: 15px;
}

.cmpSelctedTab {
    color: $primary !important;
    text-transform: none !important;
}

.cmpTab {
    color: $black;
    text-transform: none !important;
}

.cmpSelctedTab .MuiTabs-indicator {
    background-color: $primary !important;
    text-transform: none !important;
}

.cmpTabSpacing {
    margin: 10px 0px 35px !important;
}

.cmpTabItem {
    margin: 5px 0px !important;
    position: relative !important;
    overflow: auto !important;
}

.cmpTabItem2 {
    margin: 5px 0px !important;
    position: relative !important;
    min-height: calc(100vh - 250px) !important;
    max-height: calc(100vh - 250px) !important;
    overflow: auto !important;
}

.wzNewContentBtn {
    color: $white !important;
    background-color: $primary !important;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    padding: 6px 16px !important;
    border-radius: 4px !important;
}

.cmpTabContainer {
    max-height: 65vh;
    overflow: auto;
}

.cmpSaveBtn {
    background-color: $primary !important;
    color: $white !important;
    text-transform: none !important;
    border-radius: 5px !important;
    padding: 8px 22px !important;
    border-radius: 5px !important;

}

.cmpDisabledSaveBtn {
    background-color: $primary !important;
    opacity: 0.5 !important;
    color: $white !important;
    text-transform: capitalize !important;
    padding: 8px 22px !important;
    border-radius: 5px !important;
}

.cmpHeadNewContentContainer {
    justify-content: space-between;
}


.cmpAddFeatureIconStyle {
    margin-right: 15px !important;
    height: 18px;
    width: 18px;
}

.cmpHeadTextItem {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
}

.cmpTabItem :global(.MuiButtonBase-root),
.cmpTabItem2 :global(.MuiButtonBase-root) {
    min-height: 0px !important;
}

.cancelButtonGrid {
    position: sticky !important;
    bottom: 35px !important;
    overflow: hidden !important;
    width: 100% !important;
    background-color: white !important;
    padding-top: 25px;
}

.cmpGlobalComponentBorderItem {
    border-radius: 10px !important;
    background: #FFF !important;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) !important;
    padding: 25px 30px !important;
    max-height: calc(100vh - 120px) !important;
}

.cmpGlobalHeaderGrid {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

.plusIconGrid {
    text-align: end !important;
}

.cmpDivider {
    padding: 0px !important;
    border: none !important;
    cursor: pointer !important;
    margin: 15px 0px 0px !important;
    width: 100% !important;
}

.cmpDetailsTab {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-width: 70px !important;
}

.cmpDocumentsTab {
    padding-left: 10px !important;
    padding-right: 10px !important;
    min-width: 70px !important;
}

.cmpDocumentsAvatar {
    background-color: #B3B3B3 !important;
    height: 18px !important;
    width: 18px !important;
    font-size: 12px !important;
    margin-top: 2px !important;
    font-weight: 500 !important;
    padding: 9px !important;
}

.cmpDocumentsSelectedAvatar {
    background-color: #6A097D !important;
    height: 18px !important;
    width: 18px !important;
    font-size: 12px !important;
    margin-top: 2px !important;
    font-weight: 500 !important;
    padding: 9px !important;
}

.cmpRefreshGrid {
    position: absolute !important;
    right: 0 !important;
    margin-top: 2px !important;
}

.cmpRefreshIcon {
    color: #6A097D !important;
}

.cmpSaveGrid {
    gap: 15px !important;
}

.cmpCancelButton {
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    border: 1px solid #6a097d !important;
    color: #6a097d !important;
    border-radius: 4px !important;
}

.cmpCancelButton:hover {
    border: 1px solid #6a097d !important;
    color: #6a097d !important;
}

.cmpGlobalHeadSpacing {
    padding: 20px 30px 10px !important;
}

.wzGlobalHead {
    color: $black !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
}

@media screen and (min-width: 1232px) and (max-width : 3000px) {
    .cmpTabContainer {
        height: calc(100vh - 350px) !important;
        max-height: calc(100vh - 38vh) !important;
        overflow: auto;
    }
}

@media screen and (min-width: 900px) and (max-width : 1232px) {
    .cmpTabContainer {
        height: calc(100vh - 340px) !important;
        max-height: calc(100vh - 380px) !important;
        overflow: auto;
    }

    .cmpGlobalComponentBorderItem {
        height: calc(100vh - 140px) !important;
    }

    .cancelButtonGrid {
        bottom: 5px !important;
        padding-top: 0px !important;
    }
}

@media screen and (min-width: 925px) and (max-width : 1232px) {
    .cmpGlobalComponentBorderItem {
        height: calc(100vh - 120px) !important;
    }
}

@media screen and (min-width: 600px) and (max-width : 900px) {
    .cmpTabContainer {
        max-height: calc(100vh - 370px) !important;
        overflow: auto;
    }

    .cmpGlobalComponentBorderItem {
        padding: 25px 15px 40px !important;
        height: calc(100vh - 145px) !important;
    }

    .cancelButtonGrid {
        bottom: 20px !important;
        padding-top: 0px !important;
    }

    .cmpGlobalHeadSpacing {
        padding: 20px !important;
    }
}

@media screen and (min-width: 320px) and (max-width : 599px) {
    .cmpTabContainer {
        max-height: calc(100vh - 380px) !important;
        overflow: auto;
    }

    .cmpGlobalComponentBorderItem {
        max-height: calc(100vh - 165px) !important;
        padding: 15px 15px 0px !important;
    }

    .cancelButtonGrid {
        bottom: 20px !important;
        padding-top: 0px !important;
    }

    .cmpGlobalHeadSpacing {
        padding: 0px 15px !important;
        height: calc(100vh - 200px) !important;
    }
}