@import "../../../utils/colorCode.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.sidebarCollapsed {
  width: 20px !important;
  padding: 27px 25px 0px 0px;
  background-color: #FDFBFD;
  // height: 100vh;
  height: calc(100vh - 64px);
  min-width: unset !important;
}

.sidebarNotCollapsed {
  width: 100% !important;
  // padding: 27px 25px;
  // padding: 25px 20px;
  padding: 20px;
  background-color: #FDFBFD;
  // height: 100vh;
  // height: calc(100vh - 64px);
  height: 100%;
  min-width: unset !important;

}




// a:hover {
//   color: #fff !important;
//   /* #0ab4ff */
//   font-size: 15px !important;
// }

.menu-anchor:hover {
  background-color: #3B78CE !important;
  /* font-weight: bolder; */
}

.menu-label {
  font-size: 15px !important;
  color: #fff !important;
}

/* button {
    right: 20px;
  } */
hr {
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 0.15;
}


.ps-sidebar-container {
  overflow-y: hidden !important;
  background-color: #3B78CE !important;
  border: 2px solid red !important;
}

.ps-menu-suffix {
  opacity: 1 !important;
  position: fixed;
  /* left:218px */
}

.ps-menu-label {
  font-size: 15px;
  font-weight: 500;
}

.sidebarCollapsed {
  // width: 70px;
  // min-width: 70px;
}

.sidebarNotCollapsed :global(.ps-sidebar-container) {
  overflow-y: hidden !important;
  height: 100%;
  background: transparent;
  // width:200px !important;
  // min-width: 200px !important;
}


.tagStyle {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.firstTab {
  padding: 0;
  padding-left: 20px;
  margin-right: 15px;
  font-size: 15px !important;
  font-weight: 600 !important;
  color: $grey !important;
  text-transform: unset
}

.textStyle {
  font-size: 15px;
  color: #646464;
  font-weight: 600;
  margin-bottom: 15px
}

.tabStyle:global(.Mui-selected) {
  background-color: #4C4A4D !important;
}

.tabPanelParent {
  max-height: calc(100vh - 125px);
  overflow-y: auto;
}

.templateTextStyle {
  // font-size: 20px;
  font-size: 18px;
  font-weight: 600;
  color: $primary
}

.templateNameblock {
  padding-bottom: 12px !important;
}

.personaDropdown {
  width: 100% !important
}

.personaDropdown :global(.MuiInputBase-input) {
  color: $primary !important;
  font-size: 15px;
  font-weight: 500;
  padding: 0 !important;
}

.personaDropdown::before {
  border-bottom: 0px !important;
  background: unset !important;
}

.personaDropdown::after {
  border-bottom: 0px !important;
  background: unset !important;
}

.personaDropdown::before {
  border-bottom: 0px !important;
  background: unset !important;
}

.personaDropdown :global(.MuiSelect-select) {
  width: 100%;
  background-color: #FDFBFD !important;
  white-space: wrap;
}

.personaDropdown :global(.MuiSelect-select)::before {
  border-bottom: 0px !important;
}

.personaDropdown :global(::after) {
  border-bottom: 0px !important;
}

.personaTextStyle {
  font-size: 15px;
  font-weight: 500;
  color: $grey;
  margin-right: 7px !important;
}

.personaContainer {
  padding-bottom: 12px;
  // padding-left: 20px;
  padding-left: 0px;

}

.personaDropdownMenuItem {
  white-space: pre-wrap !important;
}

.personaDropdown :global(.MuiInputBase-root)::before {
  border-bottom: 0px !important;
}

.personaAvatarStyle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.personaMenuContainer {
  padding: 15px;
  max-width: 289px;
  background-color: $white;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  flex-wrap: nowrap !important;
}

.PersonaDescpTooltip {
  background-color: unset !important;
}

.PersonaDescpTooltip :global(.MuiTooltip-popper) {
  background: $white !important;
  padding: 0px !important;
  background-color: unset !important;
}

.MuiTooltip-root {
  background-color: unset !important;
}

.personaNameStyle {
  color: #2765FF;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 0px 6px;
}

.PersonaDisplayNameStyle {
  color: $grey;
  font-size: 11px;
  padding: 2px 0px 6px;
}

.personaSummaryStyle {
  color: $black;
  font-size: 11px;
  flex-wrap: nowrap !important;
  width: 189px !important;
  // margin-top: 5px !important;
}

.leftBlock {
  padding-left: 10px;
}