@import "../../../utils/colorCode.scss";


.colorGuideInclusion {
    background: $colorGuideInclusion;
}

.colorGuideSynonym {
    background: $colorGuideSynonym;
}

.colorGuideGrammar {
    background: $colorGuideGrammar;
}

.colorPickerBox {
    width: 16px;
    height: 16px;
    border-radius: 3px;
}

// .mainContainer {
//     display: flex;
//     flex-direction: column;
//     font-size: 12px;
//     font-weight: 400;

// }

// .subContainer {
//     display: flex;
// }

.primaryText {
    display: flex;
}


.dividerStyle {
    padding: 0px !important;
    margin: 3px 0px !important;
    opacity: 0.65;
}