.carouselParaphraser .control-dots {
    text-align: end !important;
}

.carouselParaphraser div {
    height: 100% !important;
}

.paraphrasingCarousel {
    height: 100%;
}

.paraphrasingCarousel .rec-slider {
    height: 100% !important;
   
}


.imageCarousel {
    height: 100%;
    position: relative;
    padding-right: 15px;
}

.imageCarousel .rec-slider {
    height: 100% !important;
   
}

.carouselParaphraser .slider-wrapper {
    overflow-y: auto !important;
}

.carouselParaphraser .control-dots .dot {
    background-color: #000 !important;
}

.paraphraseCrousel {
    width: 100% !important;
    height: 100%;
}

.styleDiv {
    width: 100%;
    /* overflow: auto; */
    height: 100% !important;
}

.paraphrasingCarousel .vUlQj:hover,
.vUlQj:focus {
    box-shadow: none !important;
}
.imageCarousel .vUlQj:hover,
.vUlQj:focus {
    box-shadow: none !important;
}
.paraphrasingCarousel .iNpnDY:hover,
.iNpnDY:focus {
    box-shadow: none !important;
}
.imageCarousel .iNpnDY:hover,
.iNpnDY:focus {
    box-shadow: none !important;
}
.paraphrasingCarousel .kvrXoZ {
    width: 10%;
    justify-content: end;
    display: inline-flex;
    position: absolute;
    bottom: 0px;
    right: 5px;
    background-color: #fff;
    border-top-left-radius: 10px;
}

@media screen and (max-width : 600px) and (min-width : 400px) {
    .paraphrasingCarousel .kvrXoZ {
        width: 10%;
        justify-content: end;
        display: inline-flex;
        position: absolute;
        bottom: 0px;
        right: 5px;
        background-color: #fff;
        border-top-left-radius: 10px;
    }
}

@media screen and (max-width : 400px) and (min-width : 320px) {
    .paraphrasingCarousel .kvrXoZ {
        width: 13%;
        justify-content: end;
        display: inline-flex;
        position: absolute;
        bottom: 0px;
        right: 5px;
        background-color: #fff;
        border-top-left-radius: 10px;
    }
}

.paraphrasingCarousel .imageCarousel .dydPIA {
    margin: 0 !important;
}

.paraphrasingCarousel .rec-dot_active {
    box-sizing: border-box;
    padding: 0px;
    transition: all 250ms ease 0s;
    border: none;
    margin: 5px;
    background-color: #6A097D;
    font-size: 1.3em;
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 50%;
    outline: none;
    box-shadow: none;

}
.imageCarousel .rec-dot_active {
    box-sizing: border-box;
    padding: 0px;
    transition: all 250ms ease 0s;
    border: none;
    margin: 5px;
    background-color: #6A097D;
    font-size: 1.3em;
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 50%;
    outline: none;
    box-shadow: none;

}
.paraphrasingCarousel  .rec-dot {
    box-shadow: none;
    border: 1px solid gray;
}
.imageCarousel .rec-dot {
    box-shadow: none;
    border: 1px solid gray;
}
.paraphrasingCarousel .rec-item-wrapper {
    height: 100%;
  
}
.imageCarousel .rec-item-wrapper {
    justify-content: space-evenly !important;
    height: 100%;
    /* background-image: url("../../../assets/images/paraphrasing/carouselBgImg.png") ; */
    border-radius: 10px;
    background-repeat:round;
    padding-left: 15px !important;
}
.imageCarousel .rec-slider-container{
    margin: 0px !important;
}
.paraphrasingCarousel  .rec-carousel {
    /* height: 372px; */
    height: 100% !important;
}
.imageCarousel .rec-carousel {
    /* height: 372px; */
    height: 100% !important;
   
}
.imageCarousel .kvrXoZ{
    width: 10%;
    justify-content: end;
    display: inline-flex;
    position: absolute;
    bottom: 45%;
    right: -17px;
    background-color: #fff;
    border-top-left-radius: 10px;
    transform: rotate(90deg);
    display: none !important;
}
.imageCarousel .rec-pagination {
    display: none;
}
.backArrowCarouselLeft{
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 35px !important;
    height: 35px !important;
    right:calc(100% - 10%);
    cursor: pointer;
}
.backArrowCarouselRight{
    transform: rotate(180deg);
    position: absolute;
    top: 50%;
    /* right: 5%; */
    left: calc(100% - 10%);
    width: 35px !important;
    height: 35px !important;
    z-index: 1;
    cursor: pointer;
}