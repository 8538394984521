@import "../../../utils/colorCode.scss";

.tabStyle {
    text-transform: capitalize !important;
}

.iFrameContainer {
    border: 1px solid #E9E9E9;
    border-radius: 3px !important;
}

.embedHeadItem {
    background-color: #FBF4FF !important;
}

.embedTitle {
    color: #6A097D !important;
    font-size: 15px;
    font-weight: 500;
}

.embedHeadContainer {
    padding: 20px 10px 20px 20px;
}

.embedDescription {
    color: #565656 !important;
}

.embedLinkContainer {
    padding: 20px;
}

.domainTable {
    width: 100%;
    margin-top: 18px !important;
}

.tryChatBotStyles {
    max-height: calc(100vh - 220px);
    overflow-y: scroll;
}



// -----------------------------------

.cbViewMainItem {
    border-top: 1px solid #DEDEDE;
    position: relative;
}

.cbViewMainMobileItem {
    border-top: none;
}

.cbPreviewMainItem {
    background-color: #F6F6F6;
}

.cbPreviewHeadItem {
    margin: 10px 0px !important;
}

.cbPreviewHead {
    color: #1F384C !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.cbDetailsMainItem {
    position: relative !important;
}

.cbTabsRenderMainItem {
    height: calc(100vh - 200px);
    padding-right: 25px !important;
    overflow: auto;
}


.cbTabsRenderMainItem {
    &::-webkit-scrollbar-thumb {
        display: block;
        background-color: #D9D9D9 !important;
        outline: unset !important;
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        width: 5px !important;
    }
}

.cbHeaderMainItem {
    margin: 0px 0px 15px !important;
}

.cbHeaderBreadCrumbsHeadText {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: #7A7A7A !important;
}

.cbHeaderBreadCrumbsChatbotLabel {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: #1F384C !important;
}

.cbHeaderBreadCrumbMainItem {
    margin: 2px 0px 0px !important;
}


.cbCancelButton {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #6A097D !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    border: 1px solid #6A097D !important;
    width: 120px !important;
    max-width: 120px !important;
    text-transform: capitalize !important;
}

.cbSaveButton {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #fff !important;
    background-color: #6A097D !important;
    border-radius: 5px !important;
    width: 120px !important;
    max-width: 120px !important;
    text-transform: capitalize !important;
}

.cbSaveCancelContainer {
    justify-content: end;
    gap: 10px;
}

.cbSaveCancelContainerGrid {
    margin: 10px 0px !important;
}

.cbDetailsPreviewMainItem {
    position: absolute;
    right: 0;
    top: 10px;
    background-color: #fff;
}

.cbDetailsTabitem {
    margin: 0px 0px 15px !important;
}

.cbDetailsTabitem .MuiTabs-root .MuiTabs-fixed {
    width: 82% !important;
    max-width: 82% !important;
    overflow: auto !important;
}

.chatbottabStyle {
    min-height: auto !important;
}

.chatbotTabStyle {
    font-weight: 600 !important;
    font-size: 14px !important;
    min-height: auto !important;
    padding: 20px 12px 10px !important;
}

.chatBotNameText {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #1F384C !important;
}

.previewTextGrid {
    gap: 5px !important;
    cursor: pointer !important;
}

.previewTextStyle {
    color: #0A6ADD !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.dashboardChildWrapper {
    padding: 15px 25px !important;
}

.selectTaboptions {
    font-size: 15px !important;
    font-weight: 600 !important;
}

.selectTaboptions :global(.MuiSelect-select) {
    &:focus {
        background-color: unset !important;
    }
}

.selectTabOptionsFormControl {
    margin: 8px !important;
    width: calc(100% - 35px) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.selectTabOptionsFormControl ::before {
    border-bottom: unset !important;
}

.selectTabOptionsFormControl :focus {
    &:focus {
        border-bottom: 2px solid #d4d4d4 !important;
    }
}


// Landing page
.cbLandingPageItem {
    margin: 20px auto !important;
    text-align: center;
}


.cbLandingPageItem2 {
    margin: 0px auto 20px !important;
    text-align: center;
}

.getStartedBtn {
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #6A097D !important;
    border: 1px solid #6A097D !important;
    border-radius: 5px !important;
}

.dashboardChildWrapper {
    // background: linear-gradient(360deg, #ffffff 18%, #f7e9ff 26%, #f7e9ff 10%, #fff);
    height: 100%;
}


// Landing page

@media screen and (max-width : 1200px) and (min-width : 0px) {

    .cbTabsRenderMainItem {
        height: calc(100vh - 225px);
        overflow: auto;
    }

    .selectTabOptionsFormControl {
        width: calc(100% - 105px) !important;
    }
}

@media screen and (min-width : 1201px) {
    .selectTabOptionsFormControl {
        display: none !important;
    }
}


@media screen and (max-width : 600px) and (min-width : 0px) {
    .cbSaveCancelContainer {
        justify-content: center !important;
    }

    .chatbotTabStyle {
        font-weight: 600 !important;
        font-size: 14px !important;
        min-height: auto !important;
        padding: 12px 5px !important;
    }

    .cbDetailsMainItem {
        padding-right: 0px;
        position: relative !important;
    }

    .cbTabsRenderMainItem {
        height: calc(100vh - 200px);
        padding-right: 0px !important;
        overflow: auto;
    }

    .cbLandingPageItem {
        text-align: start;
    }

    .cbLandingPageItem2 {
        text-align: start;
    }

}

// -----------------------------------