@import "../../../utils/colorCode.scss";

.titleStyle {
    font-size: 18px;
    font-weight: 600;
    color: $primary !important;
    display: flex;
    align-items: center;
}

.titleStyleWrapper {
    flex-wrap: nowrap !important;
    margin-bottom: 7px !important;
}

.stepStatus {
    color: $grey !important;
    padding-left: 15px;
}