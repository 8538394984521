@import "../../../utils/colorCode.scss";

.basicAccordion:global(.MuiAccordion-root) {
    box-shadow: unset !important;
    margin: 0 !important
}

.basicAccordion :global(.MuiAccordion-root::before) {
    height: 0;
}

.basicAccordion:global(.MuiPaper-root)::before {
    background-color: unset !important;
}

.basicAccordionSummary :global(.MuiAccordionSummary-root) {
    padding: 0px !important;
    min-height: unset !important;
}

.basicAccordionSummary :global(.MuiAccordionSummary-content) {
    margin: 0px !important;
}

.basicAccordionDetails:global(.MuiAccordionDetails-root) {
    padding: 0px !important;
}

.heading {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: $black !important
}

.subHeading {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: $black !important
}

.keynote {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: $black !important;
    padding: 15px 0px 0px 38px !important;

}

.subHeadingGrid {
    padding-left: 19px;
}