@import "../../../utils/colorCode.scss";

.wcPvrMainItem {
    padding: 5px 15px 5px !important;
}

.wcPvrHeadTextItem {
    margin: 5px 0px;
}

.wcPvrOriginalTextItem {
    margin: 5px 0px 0px;
}

.wcPvrOriginalText {
    font-size: 13px !important;
    color: #8F8F8F !important;
    font-weight: 500 !important;
}

.wcPvrSuggestionTextPreferred {
    cursor: pointer;
    color: $white !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    text-align: center !important;
}

.wcPvrSuggestionText {
    cursor: pointer;
    color: #000 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    text-align: center !important;
}

.wcPvrHeadText {
    color: #8F8F8F !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.wcPvrSuggestionitemPreferred {
    cursor: pointer;
    background-color: $primary !important;
    color: $white !important;
    border-radius: 5px;
    width: 90px;
    min-width: auto;
    padding: 5px 10px !important;
    margin: 5px 10px 5px 0px !important;
    text-align: center !important;
}

.wcPvrSuggestionitem {
    cursor: pointer;
    background-color: #FBF4FF !important;
    border-radius: 5px;
    width: 90px;
    min-width: auto;
    padding: 5px 10px !important;
    margin: 5px 10px 5px 0px !important;
    text-align: center !important;
}

.wcPvrSuggestionitem:hover {
    background-color: #6A097D !important;
    color: #fff;
    border-radius: 5px;
    width: 90px;
    min-width: auto;
    padding: 5px 10px !important;
    margin: 5px 10px 5px 0px !important;
    text-align: center !important;
}

.wcPvrReasonChangerTextMainItem {
    padding: 10px 15px 10px;
}

.wcPvrReasonChangerText {
    color: #8F8F8F !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    width: 100% !important;
}

.wcPvrReasonChangerTextValue {
    // color: #D11B1B !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    width: 100% !important;
}

.wcPvrHeadTextValue {
    color: black !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    width: 100% !important;
}