@import "../../../utils/colorCode.scss";

.setUpContextWrapper {
    padding: 18px 35px 45px;
}

.leftBlock {
    margin-top: 10px !important;
}

.personaDropdown {
    width: 100% !important
}

.personaDropdown :global(.MuiInputBase-input) {
    color: $primary !important;
    font-size: 15px;
    font-weight: 500;
    // padding: 0 !important;
    padding-top: 4px !important;
}

.personaDropdown::before {
    border-bottom: 0px !important;
    background: unset !important;
}

.personaDropdown::after {
    border-bottom: 0px !important;
    background: unset !important;
}

.personaDropdown :global(.MuiSelect-select) {
    width: 100%;
    background-color: $white !important;
    white-space: wrap;
}

.personaDropdown :global(.MuiSelect-select)::before {
    border-bottom: 0px !important;
}

.personaDropdown :global(::after) {
    border-bottom: 0px !important;
}

.personaDropdown :global(::before) {
    border-bottom: 0px !important;
}

.personaTextStyle {
    font-size: 15px;
    font-weight: 600;
    color: $black;
    margin-right: 7px !important;
}

.personaContainer {
    padding-bottom: 12px;
}

@media only screen and (max-width: 600px) {
    .setUpContextWrapper {
        padding: 25px 20px;
    }

}

.personaDropdownMenuItem {
    white-space: pre-wrap !important;
}

.hellloooooooo:hover .PopOver {
    display: block;
}

.PopOver {
    display: none;
}

.personaAvatarStyle {
    width: 77px;
    height: auto;
}

.personaMenuContainer {
    padding: 15px;
    max-width: 289px;
    background-color: $white;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    flex-wrap: nowrap !important;
}

.PersonaDescpTooltip {
    background-color: unset !important;
}

.PersonaDescpTooltip :global(.MuiTooltip-popper) {
    background: $white !important;
    padding: 0px !important;
    background-color: unset !important;
}

.MuiTooltip-root {
    background-color: unset !important;
}

.personaNameStyle {
    color: $black;
    font-size: 15px;
    font-weight: 600;
}

.PersonaDisplayNameStyle {
    color: $grey;
    font-size: 13px;
    padding: 2px 0px 7px;
}

.personaSummaryStyle {
    color: $black;
    font-size: 12px;
}

.leftBlock {
    padding-left: 10px;
}