@import "../../../utils/colorCode.scss";

.mainContainer {
    // box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 8px;
}

.leftBlock {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25)
}

.rightBlock {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
}

.clearInputText {
    font-size: 15px;
    font-weight: 500;
    color: $primary;
}

.autocompleteStyle fieldset {
    background-color: $white;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
}

.characterCountText {
    font-size: 13px;
    font-weight: 500;
    color: #1F384C;
}

.inputTextStyle {
    font-size: 15px;
    font-weight: 500;
    color: $black;
}

.ppContainer {
    padding: 2rem;
}

.paraphrasingMainItem {
    padding: 20px 35px;
}

.ppHeader {
    margin-bottom: 1rem !important;
}

.ppHeader p {
    font-size: 18px !important;
    font-weight: 600;
    color: $black;
}

.ppInput {
    // box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    // border-radius: 10px;
    // padding: 0 1rem;
    border-top: 1px solid rgba(222, 222, 222, 0.5);
}

.ppInputHeader {
    // padding: 20px 0px !important;
    padding: 9px 0px !important;
}

.ppTabs {
    // display: none !important;
    margin-bottom: 20px;
}

.ppTabs :global(.MuiTabs-indicator) {
    background-color: $primary;
}

.ppTabs button {
    // display: none;
    text-transform: capitalize !important;
    padding: 0 !important;
    margin-right: 1rem;
    min-width: unset;
    min-height: 50px;
    font-weight: 600;
    color: #676767;
}

.ppTabs :global(.Mui-selected) {
    color: $primary !important;
}

.ppTabs span {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    height: 4px !important;
    bottom: 4px !important;
}

.ppTabsSecond {
    min-height: 40px !important;
}

.ppTabsSecond button {
    text-transform: capitalize !important;
    padding: 0 !important;
    margin-right: 1rem;
    min-width: unset;
    min-height: 40px;
    font-weight: 600;

}

.ppTabsSecond span {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    height: 4px !important;
}

.tabParent {
    padding: 0px 20px;
}

.ppInputContainer {
    position: relative;

    /* Box Shadow on the Right Side */
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -4px;
        bottom: 0;
        width: 3px;
        // box-shadow: -1.5px 0 2px 0 rgba(0, 0, 0, 0.15);
    }
}



.ppInputContainerHeader {
    font-weight: 600;
    font-size: 1.1rem;
    // margin-bottom: 1rem !important;
    background: $sideNavigationPanel;
    padding: 12px 20px;
    height: fit-content;
    border-radius: 5px 0px 0px 0px;
}

.ppInputField :global(.MuiOutlinedInput-root) {
    padding: 0 !important;
    box-shadow: unset !important;
}

.ppInputField :global(.MuiInputBase-input) {
    box-shadow: none !important;
    border-radius: 10px !important;

}

.ppInputFieldOptions {
    padding-bottom: 0px !important;
    // padding: 20px 0px 20px 20px;
    background-color: #F9F9F9;
    position: relative;
    // border-top: 1px solid #E9E9E9;
    display: flex;
    align-self: start;
    flex-direction: column !important;
    justify-content: space-between;
    width: 100%;
}

.ppInputFieldOptionsWordChanger {
    padding-bottom: 0px !important;
    // padding: 20px 0px 20px 20px;
    background-color: #F9F9F9;
    position: relative;
    // border-top: 1px solid #E9E9E9;
    display: flex;
    align-self: start;
    flex-direction: column !important;
    justify-content: space-between;
    width: 100%;
}

.ppInputField :global(.MuiTextField-root) {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}


.inputOptions {
    // OLD HEIGHT
    // min-height: 174px;
    // max-height: 182px;

    // OLD DYNAMIC CSS
    // margin-top: 20px !important;
    // min-height: 28vh !important;
    // max-height: 28vh !important;
    // overflow-y: auto;
    // margin-top: 1rem;
    // padding: 1px;
    margin-top: 5px !important;
    // min-height: 26vh !important;
    // max-height: 29vh !important;
    // min-height: calc(100vh - 70vh) !important;
    // max-height: calc(100vh - 75vh) !important;
    max-height: unset;
    min-height: unset;

    // min-height: 22vh !important;
    // max-height: 22vh !important;
    overflow-y: auto;
    margin-top: 1rem;
    padding: 1px;

}

.bottomLeftContainer {
    min-height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
    height: 100%
}

.bottomLeftContainerCompact {
    min-height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
    // min-height: calc(100vh - 250px);
    // max-height: calc(100vh - 230px);
}

.ppInputFieldItem {
    display: flex;
    justify-content: start;
    height: 94% !important;
}

@media screen and (max-height: 750px) {
    // .inputOptions {
    //     min-height: 12vh !important;
    //     max-height: 14vh !important;
    // }

    // .ppInputFieldItem :global(.MuiInputBase-input) {

    //     min-height: 120px !important;
    // }
}

@media screen and (max-height: 650px) {
    // .inputOptions {
    //     min-height: 12vh !important;
    //     max-height: 14vh !important;
    // }

    // .ppInputFieldItem :global(.MuiInputBase-input) {

    //     min-height: 60px !important;
    // }
}

.inputOptions {
    &::-webkit-scrollbar-thumb {
        background-color: #D9D9D9 !important;
        outline: unset !important;
    }

    &::-webkit-scrollbar {
        width: 5px !important;
    }
}

.inputOptions :global(.MuiAccordion-root) {
    box-shadow: none !important;
}

.inputOptions :global(.MuiAccordionSummary-root) {
    padding-left: 0 !important;
}

.inputOptions :global(.Mui-expanded) {
    // min-height: 40px !important;
    margin: 0 !important;
}

.inputOptions :global(.MuiAccordionDetails-root) {
    padding: 6px 8px 6px 0px !important;
}

// .inputOptionsCont p {
//     // padding: 0.5rem 1rem;
//     // border: 1px solid #d2d2d2;
//     // border-radius: 10px;
//     // cursor: pointer;
//     // margin-right: 15px;
// }

.inputOptionsSingle {
    display: flex;
    gap: 1rem;
}

.ppOutputContainerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    max-height: 44px;
    background: $sideNavigationPanel;
    height: calc(100% - 25px);
    // border: 2px solid red // height: 4vh;
}

.ppOutputContainerHeader p {
    font-weight: 500;
    font-size: 15px;
}

.ppOutputPagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputOptionsSingleCont {
    border-radius: 10px;
    padding: 1rem 1rem;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    border: 1px solid #d2d2d2;
    width: fit-content;
    cursor: pointer;
}

.inputOptionsSingleCont:hover {
    box-shadow: 0px 0px 1.5px 0px $primary;
}

.selectedInputOptionsSingleCont {
    border-radius: 10px;
    padding: 1rem 1rem;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    border: 1px solid #d2d2d2;
    width: fit-content;
    cursor: pointer;
    background: #F7E9FF;
}

.selectedOutput {
    box-shadow: 0 0 2px 0 rgba(106, 9, 125, 1);
    border: unset !important
}

.selectedChipTextStyle {
    color: $primary;
    font-size: 15px;
    font-weight: 500;
}

.chipTextStyle {
    color: $black;
    font-size: 15px;
    font-weight: 500;
}

.lengthSingle {
    display: flex;
}

.lengthSingle p {
    padding: 0.5rem 1.4rem !important;
    // border: 1px solid #d2d2d2;
    // border-radius: 10px;
    cursor: pointer;
    border: 1px solid #d2d2d2;
    // margin-right: 15px;
}

.lengthSingle :global(.MuiButtonBase-root) {
    padding: 5px 30px !important;
    border: 1px solid #d2d2d2 !important;
    color: #d2d2d2 !important;
}

.optionStatic {
    position: sticky;
    top: 100%;
    left: 0;
    padding: 11px 20px;
    background-color: #F9F9F9;
    display: flex;
}

.optionStaticWordChanger {
    position: sticky;
    top: 100%;
    left: 0;
    padding: 11px 20px;
    background-color: #F9F9F9;
    display: flex;
    max-height: 65px;
}

.mBoptionStatic {
    position: sticky;
    top: 100%;
    left: 0;
    // padding: 5px 20px;
    // padding: 0px 20px;
    padding: 0px 15px;
    background-color: #F9F9F9;
}

.preferencesTextStyle {
    color: #414141;
    font-size: 13px;
    font-weight: 600;
    text-align: start !important;
    padding: 8px 20px // padding: 5px 0px;
}

.optionStatic :global(.MuiOutlinedInput-root) {
    padding: 0 !important;
}

.optionStaticWordChanger:global(.MuiOutlinedInput-root) {
    padding: 0 !important;
}

.ppCheckGrammarBtn button {
    background-color: rgba(247, 233, 255, 1) !important;
    color: rgba(106, 9, 125, 1) !important;
}


// .ppOutputContainer {
//     // max-height: calc(100vh - 180px);
//     // margin-top: 1rem;
//     // margin-top: 0.5rem !important;
//     // padding: 1rem;
//     // height: 80vh;
//     // background: rgba(251, 244, 255, 0.50);
// }

.bottomTextStyle {
    color: #1F384C !important;
    font-size: 15px !important;
    font-weight: 600;
    margin-right: 10px !important;
}

.ppOutputButtons {
    padding: 11px 11px 0px;
    // height: 4vh;
}

.ppOutputButtonsContainer {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    // height: 4vh;
}

// .ppOutputButtonsContainer button {
//     border: 1px solid rgba(122, 122, 122, 0.3);
//     color: $black !important;
//     text-transform: capitalize !important;
// }

.ppOutputField {
    // // max-height: 595px;
    max-height: 650px;
    height: 100%;
    padding: 0 9px;
    overflow-y: auto;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    padding: 0px;
}

.ppOutputField textarea {
    width: 100%;
    padding: 1rem;
    border: none;
    outline: none;
    font-size: 15px;
    font-weight: 400;
    padding: 0px;
    max-height: 270px;
    overflow-y: scroll;
    resize: none;
    letter-spacing: 0.5px;
    line-height: 170%;
    // border-bottom: 1px solid #E9E9E9;
    // border-radius: 5px;
    // height: fit-content !important;
    // border: 1px solid rgba(0, 0, 0, 0.25);
    // padding-bottom: 10px;
    // width: 98%;
    // box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    // margin-bottom: 1rem;
}

.ppOutputField p {
    width: 100%;
    padding: 1rem;
    border: none;
    outline: none;
    font-size: 15px;
    font-weight: 400;
    padding: 0px;
    // max-height: 270px;
    overflow-y: scroll;
    resize: none;
    letter-spacing: 0.5px;
    line-height: 170%;
    // border-bottom: 1px solid #E9E9E9;
    // border-radius: 5px;
    // height: fit-content !important;
    // border: 1px solid rgba(0, 0, 0, 0.25);
    // padding-bottom: 10px;
    // width: 98%;
    // box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    // margin-bottom: 1rem;
}


.ppOptionsHeader {
    font-size: 15px;
    font-weight: 600;
    color: #1F384C !important;
    margin-bottom: 10px !important;
}

.ppOptionsLanguage :global(.MuiInputBase-root) {
    padding: 0 !important;
}

.ppOutputTextFields {
    // height: calc(100% - 44px);
    display: flex;
    width: 100%;
    justify-content: center;
    // height: 100%;
    // max-height: calc(100% - 150px);
    // overflow-y: scroll;
    // padding: 20px 0px;
    // padding: 1rem 0rem;
    // height: calc(80vh - 8vh)
    // box-shadow: 0px 0px 1.5px 0px #6A097D;
    // border-radius: 5px;
    // height: calc(80vh - 8vh);
    // background-color: #fff;
    // border-radius: 10px;
}

.ppClearButton button {
    // border-radius: 5px !important;
    // background: #6A097D !important;
    // color: #fff !important;
    // width: 120px;
    width: 100px;
    text-transform: capitalize;
}

// @media screen and (max-width: 1445px) {
//     // .ppInputHeader {
//     //     padding: 0 !important;

//     // }

//     // .ppInputContainer {
//     //     padding: 0.4rem !important;

//     // }

//     // .ppOutputContainer {
//     //     padding: 0.4rem !important;
//     // }
// }



.moreOptionPopover :global(.MuiPaper-root) {
    height: 210px !important;
    box-shadow: 0px 0px 12px 0px #0000001A;
    width: 15% !important;
    border-radius: 15px;
}

.moreOptionText {
    color: $grey;
    font-size: 12px;
}

.languageParent {
    // min-width: 125px;
    min-width: 140px;

}

.noOutputParent {
    min-width: 81px;
}

.personaAvatarStyle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.PersonaDescpTooltip {
    background-color: unset !important;
}

.personaMenuContainer {
    padding: 15px;
    max-width: 289px;
    background-color: $white;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    flex-wrap: nowrap !important;
}

.PersonaDescpTooltip :global(.MuiTooltip-popper) {
    background: $white !important;
    padding: 0px !important;
    background-color: unset !important;
}

.MuiTooltip-root {
    background-color: unset !important;
}

.personaNameStyle {
    color: #2765FF;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 0px 6px;
}

.PersonaDisplayNameStyle {
    color: $grey;
    font-size: 11px;
    padding: 2px 0px 6px;
}

.personaSummaryStyle {
    color: $black;
    font-size: 11px;
    flex-wrap: nowrap !important;
    width: 189px !important;
    // margin-top: 5px !important;
}

.languageAutocomplete {
    border: 2px solid red;
}

.autocompletePaper {
    width: min-content !important;
    min-width: 100%;
    max-width: min-content;
}

.autocompletePaper :global(.MuiAutocomplete-listbox) {
    max-height: 210px !important;
}

.autocompletePaper ul {
    display: grid;
    grid: auto-flow dense/1fr 1fr;
    grid-auto-flow: row dense;
    font-size: 15px;
}

.autocompletePaper li {
    overflow: visible !important;
}

.singleStructureDropdown ul {
    font-size: 15px;
}

.languagePaperStyle ul {
    font-size: 15px;
}

// for paraphrasing autocomplete style
.autocompleteParaphrasing {
    width: 100%;
    /* border: 1px solid #ced7e0 !important; 
    border-radius: 5px !important;  */
    box-shadow: rgb(0 0 0 / 60%) 0px 0px 2px 0px;
    border: none !important;
    background-color: white !important;
    outline: none !important;
    /* font-inherit !important/
    font-family:inherit !important;
    /* font-size: 13px !important; */
    /* padding: 5px !important; */
    width: 100% !important;
    border-color: #cccccc !important;
    /* border-style: groove !important; */
    font-weight: 500;
    /* margin: 5px 0px 0px 0px !important; */
    letter-spacing: 0.13px !important;
    /* line-height: 20px !important; */
    border-radius: 4px;
    min-height: 40px !important;
    padding: 0px !important;

}

.autocompleteParaphrasing :global(.MuiChip-deleteIcon) {
    color: #010101 !important;
    font-size: 15px !important;

}

.autocompleteParaphrasing :global(.MuiFormControl-root) {
    background: #ffffff;
    border: none !important;
    border-radius: 4px;
    min-height: 40px
}

.autocompleteParaphrasing :global(.MuiAutocomplete-tag) {
    margin: 6px !important;

}

.autocompleteParaphrasing :global(.MuiAutocomplete-inputRoot) {
    padding: 0px !important;
}

.autocompleteParaphrasing input {
    border: none !important;
    color: #000000DE !important;

}

.autocompleteParaphrasing :global(.MuiOutlinedInput-notchedOutline) {
    border: none !important;
}

.autocompleteParaphrasing :global(.MuiInputBase-input) {
    /* height: 100% !important; */
    padding: 0px 10px !important;
    font-family: inherit !important;
    font-weight: 400;
    font-size: 14px !important;
    min-height: 40px !important;
}

.autocompleteParaphrasing :global(.MuiOutlinedInput-root) {
    min-height: 34px !important;
    /* background: #F3F5F9; */
    background: white;
    /* font-size: 14px !important; */
}

.autocompleteParaphrasing :global(.MuiChip-root) {
    border-radius: 6px !important;
    background-color: white !important;
    border: 1px solid #E1CEE5 !important;
    font-size: 14px !important;
    font-weight: 400;
}

.autocompleteParaphrasing .MuiListSubheader-sticky {
    padding-left: 12px !important;
}

// Mobile Responsive-------

.ppOutputMobileContainerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 15px;
    max-height: 52px;
    background: rgba(249, 249, 249, 0.9);
    height: calc(100% - 25px);
    // border: 2px solid red // height: 4vh;
}

.ppOutputMobileContainerHeader p {
    font-weight: 500;
    font-size: 15px;
}


.ppOutputMobileTextFields {
    // height: calc(100% - 52px);
    // padding: 20px 0px;
    background-color: rgba(249, 249, 249, 0.9);
}

.outputCardMobileContainer {
    position: relative;
    width: 99%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 12px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    margin-top: 1px;
    background-color: #FFF;
}


// Latest Changes:
.ppGlobalMultilineTextField textarea p {
    font-size: 15px !important;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    line-height: 170% !important;
}

.ppGlobalMultilineTextField textarea {
    &::-webkit-scrollbar-thumb {
        background-color: #D9D9D9 !important;
        outline: unset !important;
        border-radius: 10px;
        cursor: pointer;
    }

    &::-webkit-scrollbar {
        width: 5px !important;
    }
}

.ppGlobalMultilineTextField textarea {
    line-height: 170% !important;
}

.ppInputFieldItem :global(.MuiOutlinedInput-root) {
    padding: 0 !important;
    box-shadow: unset !important;
    height: 100%;
}

.ppInputFieldItem :global(.MuiInputBase-input) {
    box-shadow: none !important;
    border-radius: 10px !important;
    height: 94% !important;
}

@media screen and (min-height:700px) and (max-height:800px) {
    .ppInputFieldItem :global(.MuiInputBase-input) {
        box-shadow: none !important;
        border-radius: 10px !important;
        height: 89% !important;
    }
}

@media screen and (min-height:600px) and (max-height:700px) {
    .ppInputFieldItem :global(.MuiInputBase-input) {
        box-shadow: none !important;
        border-radius: 10px !important;
        height: 85% !important;
    }
}


@media screen and (max-height:600px) {
    .ppInputFieldItem :global(.MuiInputBase-input) {
        box-shadow: none !important;
        border-radius: 10px !important;
        height: 70% !important;
    }
}

.ppInputFieldItem :global(.MuiTextField-root) {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    justify-content: start !important;
}


.ppInputCollapseFieldItem :global(.MuiOutlinedInput-root) {
    padding: 0 !important;
    box-shadow: unset !important;
    height: 100%;
}

.ppMainComponentWrapper {
    min-height: calc(100vh - 150px) !important;
    max-height: calc(100vh - 150px) !important;
}

.ppMainComponentWrapper1 {
    min-height: calc(100vh - 140px) !important;
    max-height: calc(100vh - 140px) !important;
}

.ppMainComponentWrapperContainer {
    height: 100%;
}

.ppIpOpMainItemWrapper {
    height: 100% !important;
}

.ppIpOpMainContainerWrapper {
    height: 100%;
}

.ppInMainItemWrapper {
    display: flex !important;
    align-self: flex-start !important;
    height: 100% !important;
}

.ppInMainContainerWrapper {
    height: 100%;
    align-self: flex-start;
}

.inputOptionsChild {
    padding: 0px 20px;
}

// PARAPHRASER BTN KEPT STICKY AND NOT SCROLLING ALL OVER WINDOW ---------------

@media (min-width : 599px) {
    .ppInputFieldItem :global(.MuiOutlinedInput-root) {
        align-items: start !important;
    }

    .ppGlobalMultilineTextField :global(.MuiInputBase-input) {
        padding: 14px 14px 0px 14px !important;
    }

    .ppTabs button {
        font-size: 15px !important;
    }

    .ppInputCollapseFieldItem :global(.MuiInputBase-input) {
        box-shadow: none !important;
        border-radius: 10px !important;
        height: calc(100vh - 375px) !important;
    }

    .ppInputCollapseFieldItem :global(.MuiOutlinedInput-root) {
        padding: 0 !important;
        box-shadow: unset !important;
    }

}

@media screen and (min-width : 1200px) and (max-width : 1620px) {
    .responsivePane1 {
        max-height: calc(100% - 150px) !important;
    }

    .optionStatic {
        max-height: 115px !important;
    }

    .languageOutputCountContainer {
        max-height: 42px;
    }
}

@media screen and (min-width : 599px) and (max-width : 1620px) {

    .optionStatic {
        max-height: 145px
    }



    .ppInputFieldItem :global(.MuiInputBase-input) {
        box-shadow: none !important;
        border-radius: 10px !important;
        // height: calc(100vh - 695px) !important;
    }

}

@media screen and (min-width : 1620px) and (max-width : 1800px) {
    .ppInputFieldItem :global(.MuiInputBase-input) {
        box-shadow: none !important;
        border-radius: 10px !important;
        // height: calc(100vh - 650px) !important;
    }
}

@media screen and (min-width : 1800px) and (max-width : 2000px) {
    .ppInputFieldItem :global(.MuiInputBase-input) {
        box-shadow: none !important;
        border-radius: 10px !important;
        // height: calc(100vh - 680px) !important;
        // min-height: 180px;
    }
}

@media screen and (min-width : 2000px) and (max-width : 2200px) {
    .ppInputFieldItem :global(.MuiInputBase-input) {
        box-shadow: none !important;
        border-radius: 10px !important;
        // height: calc(100vh - 660px) !important;
    }
}

@media screen and (min-width : 2201px) and (max-width : 2500px) {
    .ppInputFieldItem :global(.MuiInputBase-input) {
        box-shadow: none !important;
        border-radius: 10px !important;
        // height: calc(100vh - 725px) !important;
    }

    .inputOptions {
        margin-top: 5px !important;
        // min-height: 27vh !important;
        // max-height: 27vh !important;
        overflow-y: auto;
        margin-top: 1rem;
        padding: 1px;

    }
}

@media screen and (min-width : 2501px) and (max-width : 2800px) {
    .ppInputFieldItem :global(.MuiInputBase-input) {
        box-shadow: none !important;
        border-radius: 10px !important;
        // height: calc(100vh - 710px) !important;
    }

    .inputOptions {
        margin-top: 5px !important;
        // min-height: 27vh !important;
        // max-height: 27vh !important;
        overflow-y: auto;
        margin-top: 1rem;
        padding: 1px;

    }
}

@media screen and (min-width : 2801px) {
    .ppInputFieldItem :global(.MuiInputBase-input) {
        box-shadow: none !important;
        border-radius: 10px !important;
        // height: calc(100vh - 700px) !important;
    }

    .inputOptions {
        margin-top: 5px !important;
        // min-height: 21vh !important;
        // max-height: 21vh !important;
        overflow-y: auto;
        margin-top: 1rem;
        padding: 1px;

    }
}

// PARAPHRASER BTN KEPT STICKY AND NOT SCROLLING ALL OVER WINDOW ---------------


// Mobile Responsive-------

.wordCounterMobileContainer {
    // padding: 20px;
    display: none;
}

.ppWbWordAndCharMainContainer {
    position: absolute;
    bottom: -3px;
    left: 10px;
    // for mobile
    background: #fff;
    padding: 10px 0px;
}

.wordCounterMobile {
    font-size: 14px;
    font-weight: 500;
    color: #686868;
    margin-right: 8px !important;
}

.outputCardContainerWordChanger {
    height: 100%;
    position: relative;
    width: 98%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    // padding: 10px 15px;
    padding: 0px;
    // padding: 12px;
    margin-bottom: 0px;
    border-radius: 5px;
    margin-top: 1px;
}

.outputCardContainer {
    position: relative;
    width: 98%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px 15px;
    // padding: 12px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    margin-top: 1px;
}

.reactMarkdownStyle ul {
    padding: 0px 15px;
    list-style: disc outside none;
}

.reactMarkdownStyle ol {
    padding: 0px 15px;
}

.reactMarkdownStyle ul li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.firstBlock {
    height: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    align-items: center;
}

.indexStyle {
    color: $grey;
    font-size: 15px;
    font-weight: 400;
}

.checkboxStyle {
    padding: 0px !important;

    [type='checkbox']:checked,
    [type='checkbox']:not(:checked) {
        left: 0 !important;
    }
}



.outputLength {
    color: #1F384C;
    font-size: 13px;
    font-weight: 500;
    padding-right: 15px;

}

.useasInputBtnStyle {
    display: flex;
    color: $primary;
    font-size: 13px;
    font-weight: 500;
    // padding-right: 20px;
}

.outputMainItem {
    flex-wrap: nowrap !important;
    justify-content: space-between;
    padding: 15px 13px;
    // max-height: calc(100% - 52px);
}

.inputOptionsContainer {
    gap: 15px !important;
    align-items: center;
}

.languagePaperStyle ul {
    display: grid;
    grid: auto-flow dense/1fr 1fr;
    grid-auto-flow: row dense;
}

.languagePaperStyle li {
    // white-space: normal;
    text-align: left;
    // word-wrap: break-word;
    // word-break: break-all;
    // text-align: justify;
    // text-justify: inter-word;
    // hyphens:;
}

.languagePaperStyle {
    z-index: 2;
    min-width: 300px;
}

// .languagePaperStyle :global(.MuiAutocomplete-option) {
//     justify-content: start !important;
// }

.ppCloseArrow {
    transform: rotate(90deg) !important;
}

.ppCloseRotateArrow {
    transform: rotate(270deg) !important;
}


@media screen and (max-width: 1620px) {

    .paraphraseLangButtonParent {
        flex-direction: column !important;
    }

    .languageContainer {
        width: 60%;
    }

    .languageContainerChild {
        justify-content: start;
    }

    .languageParent {
        width: 65%;
    }

    .noOutputParent {
        width: 100%;
    }

    .outputCountContainer {
        width: 40%;
    }

    .paraphraseButtonContainer {
        width: 100% !important;
    }

    .languageOutputCountContainer {
        width: 100%;
        display: flex;
        // margin-bottom: 20px !important;
        height: calc(100% - 40px);
    }




}

@media screen and (min-width: 1621px) {
    .optionStatic {
        max-height: 75px;
    }
}

@media screen and (max-width: 1199px) {
    .paraphraseButtonContainer {
        // margin-top: 20px !important;
        margin-top: 10px !important;
    }

    .languageOutputCountContainer {
        height: unset;
    }

    .ppOutputField {
        height: unset !important;
    }

    .ppInputContainer {
        position: relative;

        /* Box Shadow on the Right Side */
        &::after {
            content: unset;
            position: unset;
            top: 0;
            right: -4px;
            bottom: 0;
            width: 3px;
            /* Adjust the width as needed */
            box-shadow: unset;
        }

        // margin-top: 0.5rem !important;
        // padding: 1rem !important;
        // padding: 0px 1rem 1rem !important;

    }
}

@media screen and (min-width:599px) and (max-width: 650px) {
    .paraphrasingMainItem {
        padding: 20px;
    }

    .ppTabsSecond button {
        margin-right: 10px;
    }

    .optionStatic {
        padding: 11px 12px
    }

    .inputOptionsChild {
        padding: 0px 12px
    }

    .tabParent {
        padding: 0px 12px
    }

    .preferencesTextStyle {
        padding: 8px 12px
    }
}

@media screen and (min-width: 650px) and (max-width:700px) {
    .paraphrasingMainItem {
        padding: 20px;
    }

    .ppTabsSecond button {
        margin-right: 15px;
    }

    .optionStatic {
        padding: 11px 15px
    }

    .inputOptionsChild {
        padding: 0px 15px
    }

    .tabParent {
        padding: 0px 15px
    }

    .preferencesTextStyle {
        padding: 8px 15px
    }
}

@media screen and (min-width:599px) and (max-width:1199px) {
    .responsivePane1 {
        max-height: calc(100% - 180px) !important;
    }

    .ppInputFieldOptionsHeight {
        max-height: unset !important;
    }

    .ppInputFieldOptionsHeightCompactWordChanger {
        max-height: unset !important;
    }

    .ppInputFieldOptionsHeightCompact {
        max-height: unset !important;
    }

    .bottomLeftContainer {
        min-height: unset !important;
        max-height: unset !important;
    }

    .noOfOutputContainerChild {
        flex-direction: column !important;
        align-items: start !important;

    }

    .languageContainerChild {
        flex-direction: column !important;
        align-items: start !important;
    }

    .bottomTextStyle {
        margin-bottom: 10px !important;
    }

    .languageParent {
        min-width: unset !important;
        width: 100%;
    }

    .noOfOutputContainerChild {
        flex-direction: column;
        align-items: start;

    }

    .ppInputContainer {
        position: relative;

        /* Box Shadow on the Right Side */
        &::after {
            content: unset;
            position: unset;
            top: 0;
            right: -4px;
            bottom: 0;
            width: 3px;
            /* Adjust the width as needed */
            box-shadow: unset;
        }

        // margin-top: 0.5rem !important;
        // padding: 1rem !important;
        // padding: 0px 1rem 1rem !important;

    }

    .languageContainerChild {
        flex-direction: column;
        align-items: start;

    }

    .bottomTextStyle {
        margin-bottom: 10px;
    }
}

@media screen and (max-width:599px) {
    .preferencesTextStyle {
        padding: 8px 0px;
    }

    .ppInputFieldOptions {
        padding: 0px 0px 0px 15px;
    }

    .autocompleteParaphrasing input {
        max-width: 70%;
    }

    .languageContainer {
        width: 50%;
    }

    .languageParent {
        width: 50%;
    }

    .noOutputParent {
        width: 100%;
    }

    .outputCountContainer {
        width: 50%;
    }

    .noOfOutputContainerChild {
        flex-direction: column !important;
        align-items: start !important;

    }

    .languageContainerChild {
        flex-direction: column !important;
        align-items: start !important;
    }

    .bottomTextStyle {
        margin-bottom: 10px !important;
    }

    .languageParent {
        width: 100%
    }
}

@media screen and (max-width: 599px) {
    .ppTabsSecond :global(.MuiTabs-indicator) {
        display: none;
    }

    .ppOutputTextFields {
        height: 95%;
    }

    .ppOutputField {
        max-height: unset !important;
    }

    .ppOutputField textarea {
        max-height: unset !important;
    }

    .ppOutputField p {
        max-height: unset !important;
    }

    .ppInputContainer {
        position: relative;

        /* Box Shadow on the Right Side */
        &::after {
            content: unset;
            position: unset;
            top: 0;
            right: -4px;
            bottom: 0;
            width: 3px;
            /* Adjust the width as needed */
            box-shadow: unset;
            // box-shadow: -2px 0 2px 0 rgba(0, 0, 0, 0.25);
            /* Adjust the values as needed */
        }

        // margin-top: 0.5rem !important;
        // padding: 1rem !important;
        // padding: 0px 1rem 1rem !important;

    }

    .ppHeader {
        display: none;
    }

    .wordCounterMobileContainer {
        display: block !important;
        // padding: 20px;

    }

    .characterCountText {
        display: none
    }

    .ppInput {
        border-top: unset;
    }

    .ppInputHeader {
        padding: 20px !important;
    }

    .ppContainer {
        padding: 0 !important;
    }

    // for mobile
    .ppInputField :global(.MuiInputBase-input) {
        width: calc(100% - 10px) !important;
        height: calc(100% - 55px) !important;
        overflow: scroll !important;
        padding-bottom: 50px !important;
    }

    .ppInputContainer {
        // for mobile
        height: 100%
    }

    .ppGlobalMultilineTextField :global(.MuiInputBase-root) {
        width: 100%;
        height: 100%;
    }



    .ppGlobalMultilineTextField textarea {
        height: 100% !important;
        overflow: scroll;
        width: 100%;
    }

    .splitpaneStyle {
        padding-top: 56px;
    }

    .ppGlobalMultilineTextField {
        height: 100%;
        align-items: start;
        height: 100%;
    }
}


@media screen and (min-width:1200px) {
    @media screen and (max-height : 645px) {

        .ppInputFieldOptionsHeight {
            max-height: unset !important;
        }

        .ppInputFieldOptionsHeightCompactWordChanger {
            max-height: unset !important;

        }

        .ppInputFieldOptionsHeightCompact {
            max-height: unset !important;
        }

        .bottomLeftContainerCompact {
            max-height: unset;
        }

        .bottomLeftContainer {
            max-height: unset;
        }

    }
}

@media screen and (max-width : 1536px) and (min-width : 1200px) {


    .ppOutputField {
        // height: calc(100% - 5px);
        padding: 0 9px;
        overflow-y: auto;
        margin: 1rem 0 0 !important;
    }

}

@media screen and (max-width : 1200px) and (min-width : 601px) {
    .optionStatic {
        padding-top: 0px !important;
    }

    .dividerStyle {
        display: none;
    }

    .languageOutputCountContainerChild {
        gap: 5px !important;
    }

    .ppOutputButtons {
        height: 4vh;
        margin: 10px 0px 15px !important;
        padding: 0px 5px !important;
    }

    .ppOutputField {
        padding: 0px;
    }

    .languageContainer {
        width: 48.75%;
    }

    .languageContainerChild {
        justify-content: start;
    }

    .languageParent {
        width: 100%;
    }

    .noOutputParent {
        width: 100%;
    }

    .outputCountContainer {
        width: 48.75%;
    }

}

@media screen and (max-width : 735px) and (min-width : 601px) {

    .ppContainer {
        padding: 30px 15px !important;
    }

    // .outputMainItem {
    //     padding: 0 10px !important;
    // }

    @media screen and (max-width : 680px) and (min-width : 601px) {
        .ppOutputButtons {
            padding: 0px 0px !important;
        }

        .ppOutputButtonsContainer {
            gap: 7px;
        }

        .inputOptionsContainer {
            gap: 7px !important;
        }
    }

}

@media screen and (min-width: 1199px) and (max-width: 1250px) {
    .optionStatic {
        padding: 11px 15px
    }

    .languageParent {
        // min-width: 118px;
        min-width: 132px;
    }

    .inputOptionsChild {
        padding: 0px 15px
    }

    .tabParent {
        padding: 0px 15px
    }

    .preferencesTextStyle {
        padding: 8px 15px
    }
}

// Mobile responsiveness
// resizer -----

.mbOptionCharText {
    font-size: 11px !important;
    font-weight: 500 !important;
    color: rgb(122, 122, 122) !important;
    display: flex;
    align-items: center;
}

.mbOutputItem {
    height: 100% !important;
    overflow: hidden;
}

.mbppStickyFooter {
    position: sticky;
    bottom: 0;
    background: #FBFBFB;
}

.paraphrasingResizer {
    background: #F9F9F9 !important;
    opacity: 1;
    height: 80px !important;
    min-height: 25px !important;
    max-height: 25px !important;
    position: relative !important;
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    display: flex;
    justify-content: center;
    border: none !important;
    margin: 0 !important;
    height: 20px !important;
    z-index: 2;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    // border: 2px solid #D9D9D9 !important;
}

.paraphrasingResizer::before {
    content: "";
    position: absolute;
    margin-left: 42%;
    margin-top: 3px;
    text-align: center !important;
    justify-content: center !important;
    display: flex !important;
    top: 5px;
    left: 0;
    width: 15%;
    z-index: 2;
    border-radius: 20px !important;
    background: #D9D9D9 !important;
    height: 5px;
}

.mbOutputHeadItem {
    padding: 0px 15px 10px !important;
}

.mbOutputHead {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #000000 !important;
}

.ppMbInputFieldOptions {
    padding-bottom: 0px !important;
    // padding: 0px 0px 20px 20px;
    padding: 0px 0px 20px 15px;
    background-color: white;
}

.ppOutputField {
    // max-height: 650px;
    max-height: calc(100vh - 150px);
    height: 100%;
    // padding: 0 9px;
    overflow-y: auto;
    margin: 0px 0px 10px !important;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
}

.carouselParaphraser .carousel .control-dots {
    text-align: right !important;
    justify-content: end !important;

}

.mbppTopPartMainItem {
    padding: 10px 15px !important;

}

.optionMbSection1Static {
    position: sticky;
    top: 100%;
    left: 0;
    // padding: 0px 20px;
    padding: 0px 15px;
    background-color: white;

}

.mbOpSection2MainItem {
    height: 100% !important;
}

.mbOpSection2MainContainer {
    height: 100% !important;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
}

.mbOpSection2ParaphrserBtnMainItem {
    position: sticky !important;
    bottom: 0 !important;
    background: #f9f9f9 !important;
    padding: 10px !important;
    display: flex;
    align-items: end;
}

.mbOpSection2ParaphrserBtnMainContainer {
    align-items: flex-end;
    background-color: #f9f9f9 !important;

}

.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;

}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover {
    border-color: transparent;
}

@media screen and (min-width : 0px) and (max-width : 599px) {
    .outputCardContainer {
        background-color: #FFF !important;
    }

    .outputMainItem {
        padding: 0px 13px !important;
        // max-height: calc(100% - 52px);
    }

    .ppOutputButtons {
        padding: 10px 12px !important;
    }

    .ppOutputButtonsContainer {
        gap: 8px;
    }

    .paraphrasingMainItem {
        // padding: 20px 0px;
        padding: 0px;
    }

    .ppMbinputOptions {
        display: flex;
        align-self: start;
        width: 100%;
        // margin-top: 20px !important;
        margin-top: 0px !important;
        overflow-y: auto;
        margin-top: 1rem;
        padding: 1px;
    }
}

.ppInputFieldOptionsHeightCompactWordChanger {
    max-height: calc(100vh - 470px);
}

.ppInputFieldOptionsHeight {
    max-height: calc(100vh - 430px);
}

// when paraphrase button comes at bottom for wide screens
@media screen and (min-width:599px) and (max-width: 1620px) {
    .languageOutputCountContainer {
        margin-bottom: 10px !important;
    }

    .ppInputFieldOptionsHeightCompact {
        // max-height: calc(100vh - 510px);
        max-height: calc(100% - 115px) !important;
    }

    .ppInputFieldOptionsHeight {
        // max-height: calc(100vh - 490px);
        max-height: calc(100vh - 460px);
    }
}

.optionNumWrapper {
    display: flex;
    gap: 5px
}

@media screen and (max-width: 599px) {
    * {
        scrollbar-width: thin !important;
        scrollbar-color: transparent transparent !important;

        // WebKit browsers
        &::-webkit-scrollbar {
            width: 0px;
            background-color: transparent !important;

        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent !important;
            outline: transparent !important;
        }
    }
}

@media screen and (max-height:550px) {
    .ppInputFieldItem{
        padding-bottom: 25px !important;
    }
}

.mobileDividerStyle {
    opacity: 0.4;
    padding: 0;
}

.dividerStyle {
    opacity: 0.5;
    padding: 0;
    margin-right: 20px !important;
    padding-left: 20px !important;
    border-style: unset !important;
    border-bottom-width: unset !important;
}

.dropdownTitleStyle {
    padding: 0px !important;
}

.listItemStyle span {
    font-size: 15px;
    font-weight: 500;
}

.languageOutputCountContainerChild {
    gap: 18px;
}

@media screen and (max-width:365px) {
    .ppTabsSecond button {
        margin-right: 12px !important;
    }
}

.desktopSplitPaneStyle {
    // padding-top: 44px !important;
}

.pane1Child {
    position: relative;
    height: 100%;
    overflow-y: s;
}

.upArrowStyles {
    position: absolute;
    bottom: -12px;
    z-index: 2;
    right: 10px;
    cursor: pointer;
    // transform: rotate(90deg);
}

.downArrowStyles {
    position: absolute;
    bottom: -18px;
    z-index: 2;
    right: 10px;
    cursor: pointer;
}

.resizerStyle {
    z-index: 0;
}

// Mobile responsiveness
// resizer -----