.ContentEditable__root {
  border: 0;
  font-size: 15px;
  display: block;
  position: relative;
  outline: 0;
  padding: 7px 140px 40px 30px;
  /* min-height: 80vh; */
  max-height: 80vh;
}

@media (max-width: 1025px) {
  .ContentEditable__root {
    padding-left: 25px;
    padding-right: 8px;
  }
}