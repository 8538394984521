@import "../../../utils/colorCode.scss";

.accountDetailsWrapperStyle {
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 30px;
    border-radius: 10px;
}

.accDetailsMainItem {
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 30px !important;
}


.accDetailsFormMainItem {
    margin: 20px 0px 30px !important;
}

.accDetailsFormMainContainer {
    justify-content: start;
}


.accDetailsMainHead {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #000 !important;
}

.accDetailsSubHead {
    color: rgba(0, 0, 0, 0.70) !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.firstBlock {
    margin-bottom: 30px !important;
}

.personalInfoTextStyle {
    font-size: 15px !important;
    font-weight: 600 !important;
    margin-bottom: 5px !important;
    color: $black !important;
}

.updateTextStyle {
    font-size: 12px !important;
    font-weight: 600;
    color: $black;
}

.accDetailsGlobalFieldsItem {
    max-width: 350px !important;
    width: 350px !important;
    min-width: auto !important;
    margin: 0px 25px 15px 0px !important;
}

.accDetailsFullWithStyle {
    width: 100%;
}

.accDetailGlobalFieldsLabel {
    color: #000 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.accDetailsGlobalFieldLabelItem {
    margin: 4px 0px !important;
}

.accDetailGlobalFieldsItem {
    margin: 4px 0px !important;
}

.accCancelBtnitem {
    // margin-right: 15px !important;
    max-width: 120px !important;
    width: 120px !important;
}

.accSaveBtnitem {
    // margin-left: 15px !important;
    max-width: 120px !important;
    width: 120px !important;
}

// media query

@media screen and (max-width : 600px) and (min-Width : 320px) {

    .accDetailsGlobalFieldsItem {
        max-width: 350px !important;
        width: 350px !important;
        min-width: auto !important;
        margin: 0px 0px 15px 0px !important;
    }

    @media screen and (max-width : 425px) and (min-Width : 320px) {
        .accDetailsGlobalFieldsItem {
            max-width: 350px !important;
            width: 350px !important;
            min-width: auto !important;
            margin: 0px 0px 15px 0px !important;
        }

        .accDetailsMainItem {
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
            padding: 30px 20px;
            border-radius: 10px;
            margin: 30px 0px !important;
        }


    }
}

// media query

// Old Css -------


.fieldWrapper {
    margin: 0px 30px 20px 0px !important;
    max-width: 330px;
}


.phoneInputWrapper {
    height: 52px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 3px;
}

.phoneInputWrapper :global(.react-tel-input) {
    height: 100%;
}

.phoneInputWrapper :global(.selected-flag) {
    padding: 0 0 0 15px !important;

}

.phoneInputWrapper :global(.form-control) {
    height: 100%;
}