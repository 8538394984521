@import "../../../utils/colorCode.scss";

.splitpaneStyle {
    overflow: visible !important;
    height: calc(100vh - 66px) !important;
    margin-top: 1px !important;
}

/* Define the collapsed class */
.collapsed .Resizer.vertical {
    left: 20px;
    cursor: col-resize;
}


// .splitpaneStyle:global(.ABCD:first-child) {
//     flex: 20 auto !important;
//     border: 2px solid red;
//     min-width: 25px !important;
//     display: block !important;
//     flex-wrap: nowrap !important;
// }

// .splitpaneStyle:global(.EFGH:first-child) {
//     border: 2px solid blue;
//     flex: 20 auto !important;
// }

.collapsed {
    // flex: 20 auto !important;
}



.flagOpen

/* Optionally, adjust the size of the resizer handle */
.collapsed .Resizer.vertical::before {
    content: '';
    width: 4px;
    /* Adjust as needed */
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ccc;
    /* Adjust the color */
}

.responsiveEditor {
    background: $lightPurple;
    padding: 25px 20px;
    max-height: calc(100vh - 52px);
    overflow: scroll;
    margin-top: 1px;
    flex-wrap: nowrap !important;
}
.responsiveEditorBlogPost {
    background: $lightPurple;
    padding: 25px 20px;
    max-height: calc(100vh - 52px);
    overflow: scroll;
    margin-top: 1px;
    flex-wrap: nowrap !important;
}

.editorDesktopView {
    width: 100% !important;
    margin: 0px !important;
    flex-wrap: nowrap;
    height: auto
}

@media screen and (min-width:860px) {
    .editorDesktopView {
        display: block !important
    }

    .responsiveEditor {
        display: none !important;
    }
    .responsiveEditorBlogPost {
        display: none !important;
    }
}

@media screen and (max-width: 860px) {
    .editorDesktopView {
        display: none !important
    }

    .responsiveEditor {
        display: block !important;
    }
    .responsiveEditorBlogPost {
        display: block !important;
        max-height: calc(100vh - 112px);
    }
}