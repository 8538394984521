@import "../../../utils/colorCode.scss";

.Heading {
    display: flex;
    align-items: start;
    color: $primary !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    justify-content: center;
    margin-bottom: 9px;
}
.ideaStyle {
  width:20px !important;
  height:20px !important;
  transform: translateY(-3px);
  margin-right: 5px;
}

.descriptionStyle {
    color: #7A7A7A;
    font-size: 15px !important;
    margin-top: 19px !important;
    text-align: start;

}
.learnMoreStyle {
    display: flex !important;
    align-items: center !important;
    color: $primary !important;
    font-size: 15px !important;
    margin-top: 5px !important;
    text-decoration: unset !important;
}
.rightArrowStyle{
    width: 15px !important;
    height: auto;
    margin-left: 7px;
}
.CarouselStyles .slide {
    display: flex !important;
    justify-content: end !important;
}
